/* eslint-disable no-console */
import { observer } from 'mobx-react';
import { Suspense, useEffect, useState } from 'react';
import { Route, Switch, useHistory, useLocation } from 'react-router-dom';
import { Helmet } from 'react-helmet-async';

// Components
import { notification, PreviewBanner } from 'bbot-component-library';
import GuardedRoute from 'components/GuardedRoute';
import LoadingScreen from 'components/LoadingScreen';
import ThemeLoader from 'components/ThemeLoader';

// Constants
import { BbotLoggedError } from 'constants/Errors';

// Hooks
import useFacebookAuth from 'hooks/use-facebook-auth';
import { useStores } from 'hooks/use-stores';

// Styles
import 'antd/dist/antd.css';
import 'styles/index.scss';

// Utils
import { retrieveFromLocalStorage } from 'utils/LocalStorage';
import { lazyWithRetry } from 'utils/DynamicContent';
import { getPreviewBannerParams } from 'utils/Host';
import { eventBus } from 'utils/Utility';
import { saveLocationToLocalStorage, trimCharacters } from 'utils/History';
import ConnectivityDetector from 'pages/ConnectivityDetector';
import useQuery from 'hooks/use-query';
import { base64DecodeJSON } from 'utils/LoyaltyRedirect';
import PartyTownComponent from 'components/partytown';
import { LockType } from 'stores/LockStore';
import LayerManager from '@doordash/component-layer-manager';
import PrismTheming from './styles/PrisimTheming';

const AccountDetailsPage = lazyWithRetry(async () => await import('pages/AccountDetailsPage'));
const ErrorPage = lazyWithRetry(async () => await import('pages/ErrorPage'));
const ExternalLoyaltyRedirectPage = lazyWithRetry(async () => await import('pages/ExternalLoyaltyRedirectPage'));
const ForgotPasswordPage = lazyWithRetry(async () => await import('pages/ForgotPasswordPage'));
const JoinSmartTab = lazyWithRetry(async () => await import('pages/JoinSmartTab'));
const LandingPage = lazyWithRetry(async () => await import('pages/LandingPage'));
const LocationRoute = lazyWithRetry(async () => await import('routes/LocationRoute'));
const LoginPage = lazyWithRetry(async () => await import('pages/LoginPage'));
const LogoutPage = lazyWithRetry(async () => await import('pages/LogoutPage'));
const OrderHistoryPage = lazyWithRetry(async () => await import('pages/OrderHistoryPage'));
const OrderReceiptPage = lazyWithRetry(async () => await import('pages/OrderReceiptPage'));
const OrderStatusPage = lazyWithRetry(async () => await import('pages/OrderStatusPage'));
const PartyTabsPage = lazyWithRetry(async () => await import('pages/PartyTabsPage'));
const PasswordResetPage = lazyWithRetry(async () => await import('pages/PasswordResetPage'));
const RegisterPage = lazyWithRetry(async () => await import('pages/RegisterPage'));
const SmartBarPage = lazyWithRetry(async () => await import('pages/SmartBarPage'));
const ThankYouPage = lazyWithRetry(async () => await import('kiosk/ThankYouPage'));
const ThanxVerificationPage = lazyWithRetry(async () => await import('pages/ThanxVerificationPage'));
const VerifyUserAccountPage = lazyWithRetry(async () => await import('pages/UserVerificationPage'));

function App() {
  const { initFacebookSdk } = useFacebookAuth();
  const history = useHistory();
  const location = useLocation();
  const [isAuthenticated, setIsAuthenticated] = useState(retrieveFromLocalStorage('isAuthenticated'));
  const { hostStore, lockStore, uiState, userStore, locationStore, rootStore } = useStores();
  const showExternalLoyalty = hostStore?.external_loyalty_integration?.integration_connected ?? false;
  const query = useQuery();
  const code = query.get('code');
  const state = query.get('state');

  useEffect(() => {
    if (code && showExternalLoyalty) {
      const stateParams = base64DecodeJSON(state);
      (async () => {
        try {
          await rootStore.api.completeExternalLoyaltyAuthentication({
            oauthToken: code,
            customerId: stateParams.customerId,
          });
          history.push(`/${stateParams.locationCode as string}/checkout?reload=true`);
          await rootStore.userStore.loadUserData();
        } catch (error: TODO) {
          throw new BbotLoggedError(
            error?.message ?? 'There was an error authenticating the session, please try again'
          );
        }
      })();
    }
  }, [code, showExternalLoyalty, state, locationStore, rootStore]);

  const previewBannerParams = getPreviewBannerParams();
  const orderStatusComponent = locationStore.kiosk_enabled ? ThankYouPage : OrderStatusPage;

  const { assets } = hostStore;

  // Set our last known locations other than auth screens, so that we can return the user to the last known
  // part of their workflow after logging in/out/registering
  useEffect(() => {
    // Only save states that aren't tied to auth or defaults
    if (
      ![`login`, `register`, 'login/forgot', 'logout', ''].includes(trimCharacters(location?.pathname ?? '', '/')) &&
      !location.pathname.includes('/user/reset') &&
      !location.pathname.includes('/user/') &&
      !location.pathname.includes('/verify/') &&
      !location.pathname.includes('/error')
    ) {
      saveLocationToLocalStorage(location.pathname);
    }
  }, [location]);

  useEffect(() => {
    setIsAuthenticated(retrieveFromLocalStorage('isAuthenticated'));
  }, [userStore.user_info]);

  useEffect(() => {
    (async () => {
      if (!hostStore.loaded && !hostStore.loading) {
        try {
          await hostStore.loadHostData();

          // Redirect the user back to the homepage if a connection is established
          if (location.pathname.includes('/error')) {
            history.push('/');
          }
        } catch (err) {
          const shouldRedirect = !lockStore.hasLock(LockType.RedirectPage);

          // Only show the error if we are actually redirecting to an error page.
          if (shouldRedirect) {
            notification.error({
              message: err.message,
            });
          }

          if (shouldRedirect && err.redirect) {
            window.location.href = err.redirect;
          }

          // Redirect the user to the error page
          if (shouldRedirect && !location.pathname.includes('/error')) {
            history.push('/error');
          }
        }
      }

      // The Facebook SDK will only work when on https. This is true even in local development,
      // so you must set `HTTPS=true` in your `.env.development.local` to develop or test
      // Facebook authentication in your local environment.
      if (window.location.protocol.includes('https') && hostStore.loaded) {
        try {
          await initFacebookSdk(hostStore.settings.hostname);
        } catch (err) {
          console.error(err);
        }
      }
    })();
  }, [hostStore, initFacebookSdk, history, location.pathname]);

  // Add history to uiState
  useEffect(() => {
    if (!uiState.history) {
      uiState.setHistory(history);
    }
  }, [uiState, history]);

  // If we switch to a route that has modals that aren't open initially, we must reset to no open modals
  // This prevents a bug where after redirecting to the order status page, we are shown a blank modal
  useEffect(() => {
    if (location.pathname.includes('/order-status')) {
      uiState.setModalView(undefined);
    }
  }, [location.pathname]);

  const dispatchUserInteractionEvent = (event) => {
    eventBus.dispatch('userInteraction', event);
  };

  useEffect(() => {
    document.addEventListener('scroll', dispatchUserInteractionEvent);
    document.addEventListener('click', dispatchUserInteractionEvent);
    document.addEventListener('keyup', dispatchUserInteractionEvent);
    document.addEventListener('mousemove', dispatchUserInteractionEvent);

    return () => {
      document.removeEventListener('scroll', dispatchUserInteractionEvent);
      document.removeEventListener('click', dispatchUserInteractionEvent);
      document.removeEventListener('keyup', dispatchUserInteractionEvent);
      document.removeEventListener('mousemove', dispatchUserInteractionEvent);
    };
  }, []);

  return (
    <div className="App" data-theme={uiState.themeId}>
      <PrismTheming>
        <LayerManager>
          <Helmet>
            <link
              rel="shortcut icon"
              href={
                assets?.favicon
                  ? hostStore.assets.favicon
                  : 'https://static.bbot.menu/customer_theme/bbot_standard/favicon.ico'
              }
            />
          </Helmet>
          {previewBannerParams.showPreviewBanner && !process.env.REACT_APP_BBOT_ENVIRONMENT.includes('jenkins') && (
            <PreviewBanner
              redirectMessage={previewBannerParams.message}
              redirectUrl={previewBannerParams.redirectUrl}
            />
          )}
          <ConnectivityDetector />
          <PartyTownComponent />
          <ThemeLoader />
          <Suspense fallback={<LoadingScreen />}>
            <Switch>
              <Route exact path="/" component={LandingPage} />
              <Route exact path="/join-tab/:secretKey" component={JoinSmartTab} />
              <Route exact path="/error" component={ErrorPage} />
              <Route exact path="/loyalty-redirect" component={ExternalLoyaltyRedirectPage} />
              <Route exact path="/order-status" component={orderStatusComponent} />
              <Route exact path="/order/showReceipt" component={OrderReceiptPage} />
              <Route exact path="/smart-bar" component={SmartBarPage} />
              <Route exact path="/loyalty-verification" component={ThanxVerificationPage} />
              <Route exact path="/user/:userId/verify/:token" component={VerifyUserAccountPage} />
              <Route exact path="/user/reset/:userId/:token" component={PasswordResetPage} />
              <GuardedRoute
                auth={!userStore.isDDMerchant && !isAuthenticated}
                exact
                path="/login"
                component={LoginPage}
              />
              <GuardedRoute
                auth={!userStore.isDDMerchant && !isAuthenticated}
                exact
                path="/login/forgot"
                component={ForgotPasswordPage}
              />
              <GuardedRoute
                auth={!userStore.isDDMerchant && !isAuthenticated}
                exact
                path="/register"
                component={RegisterPage}
              />
              <GuardedRoute
                auth={!userStore.isDDMerchant && isAuthenticated}
                exact
                path="/logout"
                component={LogoutPage}
              />
              <GuardedRoute
                auth={!userStore.isDDMerchant && isAuthenticated}
                exact
                path="/account-details"
                component={AccountDetailsPage}
              />
              <GuardedRoute
                auth={!userStore.isDDMerchant && isAuthenticated}
                exact
                path="/order-history"
                component={OrderHistoryPage}
              />
              <GuardedRoute
                auth={!userStore.isDDMerchant && isAuthenticated}
                exact
                path="/party-tabs"
                component={PartyTabsPage}
              />
              <Route path="/:locationCode" component={LocationRoute} />
            </Switch>
          </Suspense>
        </LayerManager>
      </PrismTheming>
    </div>
  );
}

export default observer(App);
