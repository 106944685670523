/* eslint-disable @typescript-eslint/prefer-nullish-coalescing */
import { makeAutoObservable, runInAction, computed } from 'mobx';
import { v4 as uuidv4 } from 'uuid';
import { DateTime } from 'luxon';
import _ from 'lodash';
import { isPossiblePhoneNumber } from 'react-phone-number-input';

// Components
import { notification } from 'bbot-component-library';

// Constants
import {
  BbotLoggedError,
  CartItemChangedError,
  CheckoutError,
  CheckoutValidationError,
  CSRFError,
  InventoryQuantityError,
  LoyaltyAccountCreationError,
  PricecheckError,
  StripeCardValidationError,
} from 'constants/Errors';
import {
  CannotEditClosedCartError,
  InvalidSharedCartIdError,
  PreviousCartAbandonedError,
  UserIsNotMemberOfCartError,
} from 'constants/SharedCartErrors';
import { CHARGE_TYPE, SHARED_CART_STATE } from 'constants/Checkout';
import {
  DELIVERY_FULFILLMENT_METHODS,
  MAP_TEMPLATE_TOGGLE_OPTIONS,
  PICKUP_FULFILLMENT_METHODS,
} from 'constants/FulfillmentMethods';

// Integrations
import {
  trackGTMPurchase,
  trackGTMSuccessfulCheckout,
  trackGTMFailedCheckout,
} from 'integrations/google-tag-manager/tracking-events';
import { trackFBPurchaseEvent } from 'integrations/facebook/tracking-events';
import { getAnalytics } from 'integrations/segment/instrumentation/Analytics';

// Models
import SharedCart from 'models/SharedCart';
import Cart from 'models/Cart';
import Location from 'models/Location';
import Check from 'models/Check';
import { ChargeDistribution } from 'models/Types';
import Tab from 'models/Tab';

// Stores
import RootStore from 'stores/RootStore';

// Utils
import { captureErrorInSentry } from 'integrations/Sentry';
import { removeFromLocalStorage, retrieveFromLocalStorage, saveToLocalStorage } from 'utils/LocalStorage';
import { getCookie, setCookie, deleteCookie } from 'utils/Cookie';
import { scrollToItemInElement } from 'utils/Scroll';
import { deleteQueryParamsFromUrl } from 'utils/Utility';
import { formatGiftCardInfoForTracking } from 'utils/GiftCardHelpers';
import { TODO } from 'utils/Types';

// Tracking
import { checkoutPageTrackingEvents } from 'integrations/segment/tracking-events';
import { cartFormatting } from 'integrations/segment/util/formatting';
import { trackOpenConsumerTab } from 'integrations/segment/tracking-events/ManageTabTracking';

// Constants
import { OrderStatusContext } from 'stores/UIState';
import { CurrentEnvironment } from 'constants/Environments';

// Types
import { LockType } from 'stores/LockStore';
import { CartPriceData, SharedCartData, TabStatus } from 'api/types';
import SharedCartItem from 'models/SharedCartItem';
import CartItem from 'models/CartItem';
import { GoogleApplePaymentMethod } from 'services/StripeService';
import { TabErrorId } from '../constants/TabErrors';
import TransportLayer from '../api/TransportLayer';
import { validateEmail } from './utils';

type ChargeType = typeof CHARGE_TYPE[keyof typeof CHARGE_TYPE];

export default class CheckoutStore {
  // Checkout Data
  api: TransportLayer;
  checkout_id: string | null = null;
  grabAnotherDrinkCheckoutId = '';
  checks: Array<TODO> = [];
  customer_id: string | null = null; // Id of the customer that the patron is ordering from
  location_id: string = '';
  desired_charges: Array<TODO> = [];
  fulfillment_method: TODO = null;
  // Cart data we get from this.selectedCart.toJSON()
  extra_checkout_info: Array<TODO> = [];
  integration_features: Record<string, TODO> = {};
  delivery_info: Record<string, TODO> = {};
  selectedPaymentMethod?: string;
  addCardFormOpen: boolean = false;

  // Errors
  error: Error | null = null;
  exceeded_rate_limits = {};
  requiredCheckoutInfoErrors = {};
  paymentInfoErrors = {};
  checkoutError: string | null = null;
  checkoutErrorIsCardValidError: boolean = false;
  pricecheckError: string | null = null;
  userDesiredTimeConflict = false; // Used when you have items from 2 menus with different future order settings

  // States
  loaded: boolean = false; // Used to check if checkout is ready
  isUpdating: boolean = false;
  validatingCheckout: boolean = false;
  performingCheckout: boolean = false;
  performingDeliveryDistanceCheck: boolean = false;
  terminalPaymentStatus: string = '';

  // Promotions
  promoCode: string = ''; // TODO: Enable support for multiple promo codes?
  promoDiscounts: Array<TODO> = [];
  promo_codes: Array<TODO> = [];
  unmet_promo_code_conditions: Array<TODO> = [];
  valid_promo_codes: Array<TODO> = [];
  BOGOTags = new Set<string>();

  availableTimeBlocks = null;

  // Cart management
  // selectedCart is always set by the `load()` function in the constructor
  selectedCart!: Cart;
  carts: Array<TODO> = [];
  cartEditable = true;

  // Local Variables
  _getCartPriceId = null;

  rootStore: RootStore;
  cartErrors: {} = {};

  constructor(rootStore: RootStore) {
    this.rootStore = rootStore;
    this.api = rootStore.api;
    makeAutoObservable(this, {
      rootStore: false,
      cartErrors: false,
      paymentInfoErrors: false,
      isReadyForCheckout: computed,
    });

    this.load();
    this.loadUserDesiredTime();
    this.setCheckoutId();
    this.setGrabAnotherDrinkCheckoutAndTabId();
  }

  /**
   * Checks that checkout/cart is finished updating and that every item is fulfillable
   * @returns {boolean}
   */
  get isReadyForCheckout() {
    return !this.isUpdating && this.selectedCart?.isReadyForCheckout;
  }

  get resumeIncompleteCheckout() {
    const { activeConsumerTab } = this.rootStore.tabStore;
    return activeConsumerTab && activeConsumerTab.status === TabStatus.PendingCheckout;
  }

  get extraCheckoutInfo() {
    const { activeConsumerTab } = this.rootStore.tabStore;
    const savedRequiredCheckoutInfoWhenOpeningTab = activeConsumerTab?.extraCheckoutInfo;
    return activeConsumerTab && savedRequiredCheckoutInfoWhenOpeningTab
      ? savedRequiredCheckoutInfoWhenOpeningTab
      : this.extra_checkout_info;
  }

  setTerminalProcessingStatus(newStatus: string) {
    runInAction(() => {
      this.terminalPaymentStatus = newStatus;
    });
  }

  setAddCardFormOpen(val: boolean): void {
    runInAction(() => {
      this.addCardFormOpen = val;
    });
  }

  loadSelectedPaymentMethod(): void {
    const { tabStore } = this.rootStore;
    const previousPaymentMethodKey = retrieveFromLocalStorage('previouslySelectedPaymentMethod', null);
    let previousPaymentMethod = this.supportedPaymentMethods.find((method) => method.id === previousPaymentMethodKey);

    // If not a valid card id then check if its a party tab id
    if (!previousPaymentMethod && tabStore.activePartyTab) {
      previousPaymentMethod = { id: 'partyTab', brand: '', last4: '', exp_month: '', exp_year: '', default: true };
    }

    let selectedPaymentMethodOnLoad = previousPaymentMethod?.id;
    if (!selectedPaymentMethodOnLoad && this.supportedPaymentMethods.length > 0) {
      selectedPaymentMethodOnLoad = this.supportedPaymentMethods[this.supportedPaymentMethods.length - 1].id;
    }

    if (selectedPaymentMethodOnLoad) {
      this.setSelectedPaymentMethod(selectedPaymentMethodOnLoad);
    }
  }

  setSelectedPaymentMethod(val: string | undefined): void {
    runInAction(() => {
      this.selectedPaymentMethod = val;
    });
    saveToLocalStorage('previouslySelectedPaymentMethod', val);
  }

  /**
   * Filter out the supported payment methods for the given configuration.
   */
  get supportedPaymentMethods() {
    const { featuresStore, userStore, tabStore } = this.rootStore;
    if (tabStore.activePartyTab) {
      // Only ever include the party tab in the list of payment methods if the user joined one.
      // Allowing other payment methods breaks a lot of the assumptions we have around how payment method use works.
      // TODO: remove this as soon as party tabs is replaced with shareable consumer tabs.
      return userStore.paymentMethodsList(false, true, false);
    }

    if (featuresStore.tabsEnabled || tabStore.activeConsumerTab) {
      return userStore.paymentMethodsList(false);
    }

    return userStore.paymentMethodsList();
  }

  get selectedPaymentMethodDetails() {
    return this.supportedPaymentMethods.find((paymentMethod) => paymentMethod.id === this.selectedPaymentMethod);
  }

  // Used to cover some edge cases. It can be possible for these properties to be missing if the selectedPaymentMethod is googlePay or applePay,
  // so this covers those cases. Will never happen if validToken/invalidToken (I haven't been able to recreate, but theoretically possible...)
  get isGoogleApplePayToken() {
    const details = this.selectedPaymentMethodDetails;
    return (
      details &&
      (('brand' in details && !details.brand) ||
        ('last4' in details && !details.last4) ||
        ('exp_month' in details && !details.exp_month) ||
        ('exp_year' in details && !details.exp_year)) &&
      details.id in GoogleApplePaymentMethod
    );
  }

  get showUpdatingState() {
    return !this.selectedCart?.isSharedCart && this.isUpdating;
  }

  get checkoutBlocked() {
    return (
      Cart.isEmptyOrUpdating(this.selectedCart) ||
      this.isUpdating ||
      this.performingCheckout ||
      this.performingDeliveryDistanceCheck ||
      this.userDesiredTimeConflict ||
      !this.rootStore.locationStore.enable_checkout ||
      this.rootStore.userStore.savingCardInProgress ||
      Boolean(this.pricecheckError) ||
      (CurrentEnvironment.isProd && window.location !== window.parent.location)
    );
  }

  get cannotCoverCartTotal() {
    const giftCardTotal = this.selectedGiftCardsIfAllowed.reduce((total, { balance }) => total + balance, 0);
    return !this.selectedPaymentMethod && giftCardTotal < this.selectedCart.cartTotal;
  }

  get isTippingAllowed() {
    const { forced_tip_fraction } = this.rootStore.locationStore;
    // const { activeConsumerTab } = this.rootStore.tabStore;
    if (forced_tip_fraction !== null && forced_tip_fraction >= 0) {
      return false;
    }

    // If tabs is enabled and the user already has a tab open then we automatically set the tip based of their selection
    // from the first order. The user is allowed to change their tip amount when they close the tab.
    if (
      this.rootStore.featuresStore.tabsEnabled &&
      this.rootStore.tabStore.activeConsumerTab &&
      this.rootStore.tabStore.allowTipAtEndOfTab()
    ) {
      return false;
    }

    return this.tippingAllowedForSelectedFulfillmentMethod;
  }

  get tippingAllowedForSelectedFulfillmentMethod() {
    const { customer } = this.rootStore.locationStore;
    const { for_fulfillment_methods } = customer?.app_properties?.tipping || {};
    return for_fulfillment_methods?.includes('all') || for_fulfillment_methods?.includes(this.fulfillment_method);
  }

  get promptsToGuest() {
    return this.selectedCart?.prompts_to_guest ?? [];
  }

  get availableOrderTimes() {
    return this.selectedCart?.availableOrderTimes;
  }

  get hasAvailableTimeBlocks() {
    return this.selectedCart?.hasAvailableTimeBlocks;
  }

  get userDesiredTime() {
    return this.delivery_info?.user_desired_time;
  }

  get showPromoCodeInput() {
    const { isMobile } = this.rootStore.uiState;
    const sharedCartOwner = this.selectedCart.isSharedCart && this.rootStore.userStore.isCartOwner;

    if (isMobile) {
      return this.rootStore.locationStore.uses_promo_codes && (!this.selectedCart.isSharedCart || sharedCartOwner);
    } else {
      return (
        this.rootStore.locationStore.uses_promo_codes &&
        (!this.selectedCart.isSharedCart || (sharedCartOwner && this.selectedCart.isLockedSharedCart))
      );
    }
  }

  get extraCheckoutInfoForFulfillmentMethod() {
    // Filters extra checkout info when using Patron Choice fulfillment method
    // i.e. If Address field has method set to 'driver_delivery' and Name field has method set to 'all' (undefined) we
    // should only send Address in payload when patron selects delivery as the fulfillment method.
    return this.extraCheckoutInfo.filter(
      (info) => (info.method === this.fulfillment_method || info.method === undefined) && Object.keys(info).length !== 0
    );
  }

  // A11Y: Used to describe buttons that open checkout modals/panels
  get cartButtonAriaLabel(): string {
    const { isSharedCart, itemsCount } = this.selectedCart;
    const viewCartButtonText = isSharedCart ? 'View Order' : 'View Cart';
    const ariaLabelS = itemsCount > 1 ? 's' : '';
    return `${itemsCount} item${ariaLabelS}, Open ${viewCartButtonText}`; // Ex: 5 items, Open View Cart
  }

  isAllowedToEditItem(cartItem: CartItem | SharedCartItem) {
    const { anonymousUserId, isCartOwner } = this.rootStore.userStore;

    return (
      !this.selectedCart?.isSharedCart ||
      (cartItem.owner_id === anonymousUserId &&
        this.selectedCart?.sharedCartReference?.status === SHARED_CART_STATE.OPEN) ||
      isCartOwner
    );
  }

  isDeliveryFulfillment = (): boolean => Boolean(DELIVERY_FULFILLMENT_METHODS.includes(this.fulfillment_method));

  setUserDesiredTime = (time: (DateTime & { isASAP: boolean }) | null | DateTime) => {
    const newDeliveryInfo = {
      ...this.delivery_info,
      user_desired_time: time,
    };
    runInAction(() => {
      this.delivery_info = newDeliveryInfo;
    });

    if (this.loaded) {
      this.debouncedGetCartPrice();
    }
  };

  setError = (value: string | null) => {
    runInAction(() => {
      this.error = value;
    });
  };

  setUnmetPromoCodeConditions = (conditions: any[]) => {
    this.unmet_promo_code_conditions = conditions;
  };

  setLocationId = (locId: string) => {
    runInAction(() => {
      this.location_id = locId;
    });

    if (this.selectedCart) {
      this.selectedCart.setLocationId(locId);
    }
  };

  setSelectedCart = (cartId: string) => {
    try {
      const cart = this.carts.find((c) => c.id === cartId);
      runInAction(() => {
        this.selectedCart = cart;
      });
    } catch (err) {
      if (!(err instanceof BbotLoggedError)) {
        console.error(err);
      }
    }
  };

  setPromptsToGuest = (value: Array<TODO>) => {
    this.selectedCart?.setPromptsToGuest(value);
  };

  setCheckoutError = (error: Error | undefined) => {
    runInAction(() => {
      this.checkoutError = error?.message ?? null;
      this.checkoutErrorIsCardValidError = error instanceof StripeCardValidationError;
    });
  };

  setPricecheckError = (errMessage: string) => {
    runInAction(() => {
      this.pricecheckError = errMessage;
    });
  };

  /**
   * Loads the user desired time from localStorage
   */
  loadUserDesiredTime = () => {
    const data = retrieveFromLocalStorage('userDesiredTime', null);
    if (!data) {
      return;
    }

    const userDesiredTimeFromLocalStorage = DateTime.fromSeconds(data);
    if (userDesiredTimeFromLocalStorage < DateTime.now()) {
      // if in the past, return
      return;
    }

    // if the userDesiredTime is valid, set it in the store
    this.setUserDesiredTime(DateTime.fromSeconds(data));
  };

  /**
   * Builds a charge distribution array that indicates how much of the total cart amount will be charged to which
   * payment methods. Return array will look like:
   * [{amount: 10, chargeType: 'gift_card', gift_card_id: ''}, {amount: 7.75, chargeType: 'saved_stripe'}]
   * @param chargeType
   * @returns Array<Object>
   */
  buildDistributionArray = (chargeType: ChargeType): Array<ChargeDistribution> => {
    const giftCardsToUse = this.selectedGiftCardsIfAllowed;
    const distributionArray = [];

    let totalAccountedFor = 0;
    while (totalAccountedFor < this.selectedCart.cartTotal) {
      if (giftCardsToUse.length > 0) {
        const amountToApply = Math.min(giftCardsToUse[0].balance, this.selectedCart.cartTotal - totalAccountedFor);
        distributionArray.push({
          amount: amountToApply,
          chargeType: CHARGE_TYPE.GIFT_CARD,
          cardId: giftCardsToUse[0].id,
        });
        totalAccountedFor += amountToApply;
        giftCardsToUse.shift();
      } else {
        distributionArray.push({
          amount: this.selectedCart.cartTotal - totalAccountedFor,
          chargeType,
        });
        totalAccountedFor = this.selectedCart.cartTotal;
      }
    }
    return distributionArray;
  };

  setPerformingDeliveryDistanceCheck = (val: boolean) => {
    runInAction(() => {
      this.performingDeliveryDistanceCheck = val;
    });
  };

  setCheckoutStarting = () => {
    runInAction(() => {
      this.performingCheckout = true;

      // Reset the error messages
      this.checkoutError = null;
      this.cartErrors = {};
      this.requiredCheckoutInfoErrors = {};
      this.paymentInfoErrors = {};
    });
    window.addEventListener('beforeunload', this.beforeUnload);
    window.addEventListener('popstate', this.disableBack);
  };

  setCheckoutEnded = (err?: Error) => {
    this.setCheckoutError(err);
    runInAction(() => {
      this.performingCheckout = false;
    });
    window.removeEventListener('beforeunload', this.beforeUnload);
    window.removeEventListener('popstate', this.disableBack);
  };

  setCheckoutId = () => {
    const checkout_id = retrieveFromLocalStorage('checkout_id');
    runInAction(() => {
      if (!checkout_id) {
        this.checkout_id = uuidv4();
        saveToLocalStorage('checkout_id', this.checkout_id);
      } else {
        this.checkout_id = checkout_id;
      }
    });
  };

  setGrabAnotherDrinkCheckoutAndTabId = (checkoutId?: string) => {
    const popFromQueryParams = () => {
      const windowUrl = window.location.search;
      const params = new URLSearchParams(windowUrl);
      const checkoutIdFromUrl = params.get('isRefill') && params.get('checkout_id');
      const tabIdFromUrl = params.get('tab_id');

      if (checkoutIdFromUrl) {
        setTimeout(() => {
          params.delete('checkout_id');
          params.delete('isRefill');
          params.delete('tab_id');
          window.history.replaceState(null, '', `${window.location.pathname}?${params.toString()}`);
        });
      }

      return {
        checkoutIdFromUrl,
        tabIdFromUrl,
      };
    };

    const handleFetchDrinkRefill = async (drinkRefillCheckoutId: string, tabIdFromUrl: string | null) => {
      const releaseMenuLoadLock = this.rootStore.lockStore.acquire(LockType.MenuLoad);
      await this.rootStore.ordersStore.fetchDrinkRefill(drinkRefillCheckoutId);
      releaseMenuLoadLock();
      await this.rootStore.tabStore.setActiveConsumerDataWithTabId(tabIdFromUrl);
    };

    const fromQueryParams = popFromQueryParams();
    const fromLocalStorage = retrieveFromLocalStorage('grabAnotherDrinkCheckoutId');
    const grabAnotherDrinkCheckoutId = checkoutId ?? fromQueryParams.checkoutIdFromUrl ?? fromLocalStorage;
    const isFromLinkClick = grabAnotherDrinkCheckoutId === fromQueryParams.checkoutIdFromUrl;

    runInAction(() => {
      if (grabAnotherDrinkCheckoutId && grabAnotherDrinkCheckoutId !== this.grabAnotherDrinkCheckoutId) {
        this.grabAnotherDrinkCheckoutId = grabAnotherDrinkCheckoutId;
        saveToLocalStorage('grabAnotherDrinkCheckoutId', grabAnotherDrinkCheckoutId);
        saveToLocalStorage(`grabAnotherDrinkTabId:${fromQueryParams.tabIdFromUrl}`, true);
        handleFetchDrinkRefill(grabAnotherDrinkCheckoutId, fromQueryParams.tabIdFromUrl);
        getAnalytics().identify({ grab_another_drink_id: isFromLinkClick ? fromQueryParams : undefined });
      }
    });
  };

  unsetGrabAnotherDrinkCheckoutAndTabId = () => {
    removeFromLocalStorage('grabAnotherDrinkCheckoutId');
    removeFromLocalStorage(`grabAnotherDrinkTabId:${this.rootStore.tabStore.activeConsumerTab?.id}`);
    getAnalytics().identify({ grab_another_drink_id: undefined });
    runInAction(() => {
      this.grabAnotherDrinkCheckoutId = '';
    });
  };

  setFulfillmentMethod = (method: TODO) => {
    runInAction(() => {
      this.fulfillment_method = method;
    });

    setCookie('previously_selected_fulfillment_method', method);

    // You cannot call get-cart-price without first loading a location
    if (this.rootStore.locationStore.loaded) {
      // We need to call getCartPrice because there are fees for specific fulfillment methods
      setTimeout(() => {
        this.getCartPrice();
      });
    }
  };

  calculateTimeBlocks = async () => {
    const possibleOrderTimeBlocks = this.availableOrderTimes.map((timeStamp) =>
      timeStamp === null ? DateTime.now() : DateTime.fromISO(timeStamp)
    );

    // if ASAP should be an option
    if (this.availableOrderTimes.length > 0 && this.availableOrderTimes[0] === null) {
      possibleOrderTimeBlocks[0].isASAP = true;
    }

    runInAction(() => {
      this.availableTimeBlocks = possibleOrderTimeBlocks;
    });

    if (this.userDesiredTime) {
      const matchingTimeBlockOption = possibleOrderTimeBlocks.find((timeBlock) => {
        // If the user desiredTime is less than a 1 second difference from the ASAP timeblock then select the ASAP timeblock
        if (timeBlock.isASAP) {
          const diff = this.userDesiredTime.diff(timeBlock);
          return diff.toMillis() < 1000;
        } else {
          return this.userDesiredTime.diff(timeBlock).toMillis() === 0;
        }
      });
      if (!matchingTimeBlockOption) {
        this.setUserDesiredTime(null);
      }
    }
  };

  // NOTE: You cannot customize the message in most modern browsers
  // Reference: https://stackoverflow.com/questions/38879742/is-it-possible-to-display-a-custom-message-in-the-beforeunload-popup
  beforeUnload = (event: Event) => {
    event.preventDefault();
    const msg = 'Order is submitting. Please wait for the transaction to finish.';
    return msg;
  };

  // Note this isn't a great solution but its better than nothing
  disableBack = (event: Event) => {
    event.preventDefault();
    window.history.forward();
    // eslint-disable-next-line no-alert
    window.alert(
      'Order is submitting. Please wait for the transaction to finish. If you are trying view the menu you will be redirected back to the checkout page.'
    );
  };

  setExtraCheckoutInfo = (value: Array<TODO>) => {
    runInAction(() => {
      this.extra_checkout_info = value;
    });

    if (this.loaded) {
      this.debouncedGetCartPrice();
    }
  };

  getPromoCode = () => this.promoCode;

  setPromoCode = (promoCode: string) => {
    runInAction(() => {
      this.promoCode = promoCode;
    });
  };

  debouncedGetCartPrice = _.debounce(function () {
    this.getCartPrice();
  }, 500);

  /**
   * Run getCartPrice when a promo is added/removed, or when we hit the checkout page and the cart is edited.
   */
  getCartPrice = async (tries = 0, delayUntilRetry = 0): Promise<void> => {
    if (this.selectedCart?.itemsCount < 1) {
      runInAction(() => {
        this.selectedCart.loaded = true;
        this.selectedCart.pricechecks = {};
        this.promoCode = '';
        this.selectedCart.promoDiscounts = [];
        this.pricecheckError = null;
      });
      return;
    }

    const currentRequestId = uuidv4();
    runInAction(() => {
      if (!this.selectedCart.isSharedCart) {
        this.isUpdating = true;
      }
      this._getCartPriceId = currentRequestId;
      this.pricecheckError = null; // Resets the price check error
    });

    try {
      const { locationStore } = this.rootStore;
      const cleanPromoCodes = _.compact([this.getPromoCode()]); // TODO: Enable support for multiple promo codes?

      const requestBody = {
        items: this.selectedCart.getItemsDict(),
        promo_codes: cleanPromoCodes,
        locationId: locationStore.id,
        fulfillment_method: this.fulfillment_method,
        prompts_to_guest: this.selectedCart?.prompts_to_guest,
        applied_loyalty_promotion: this.selectedCart?.applied_loyalty_promotion,
        checkout_id: this.checkout_id,
        extra_checkout_info: this.extraCheckoutInfo,
        pricecheck_extra_data: this.selectedCart?.pricecheck_extra_data,
        delivery_info: { user_desired_time: this.delivery_info?.user_desired_time },
        tab_id: this.rootStore.tabStore.activeConsumerTab?.id,
      };

      const responseData = (await this.rootStore.api.getCartPrice(requestBody)) as CartPriceData;
      const { prompts_to_guest } = responseData;

      runInAction(() => {
        this.prompts_to_guest = prompts_to_guest;
        this.unmet_promo_code_conditions = responseData.unmet_promo_code_conditions;
        this.valid_promo_codes = responseData.valid_promo_codes;
      });

      this.selectedCart.updateFromGetCartPrice(responseData);

      // Only considered the cart loaded from local storage if the first getCardPrice has resolved thereby
      // updating all promo codes and other items
      runInAction(() => {
        this.isUpdating = false;
        this.loaded = true;
        this.selectedCart.loaded = true;
      });
    } catch (err) {
      if (err instanceof PricecheckError) {
        runInAction(() => {
          this.setPricecheckError(err.message);
          this.isUpdating = false;
        });
      } else if (tries < 3) {
        console.error(err); // Leave this here because its helpful when debugging why getCartPrice function failed
        runInAction(() => {
          this.isUpdating = false;
        });
        setTimeout(async () => await this.getCartPrice(tries + 1, delayUntilRetry + 1000), delayUntilRetry + 1000);
      } else {
        runInAction(() => {
          this.error = err.message ?? 'Restaurant was unable to verify your cart. Please refresh and try again.';
          this.isUpdating = false;
        });

        // After 3 attempts send the error to sentry. This will help keep track of integration errors
        // causing getCartPrice to fail.
        captureErrorInSentry(err);

        this.selectedCart.resetCart();
        console.error(`Tried 3 times. No server response.`);

        if (err instanceof CSRFError) {
          console.error('Logging the user out due to CSRF errors.');
          this.rootStore.api.clearSessionCookies();
          this.rootStore.userStore.clearUserData();
        }

        this.rootStore.uiState.history?.push('/');
      }
    }
  };

  loadSharedCartFromLocalStorage = async (sharedCart: SharedCart) => {
    const { location_id, id } = sharedCart;
    try {
      const { updated_shared_cart } = (await this.rootStore.api.pollSharedCartUpdates(
        id,
        location_id
      )) as SharedCartData;
      const loadedSharedCart = await SharedCart.create(this.rootStore, updated_shared_cart, this.selectedCart);
      this.selectedCart?.setSharedCartReference(loadedSharedCart);
    } catch (error) {
      if (!(error instanceof BbotLoggedError)) {
        console.error(error);
      } else if (
        error instanceof InvalidSharedCartIdError ||
        error instanceof UserIsNotMemberOfCartError ||
        error instanceof PreviousCartAbandonedError
      ) {
        removeFromLocalStorage(`shared_cart:${location_id}`);
      } else if (error instanceof CannotEditClosedCartError) {
        setCookie(`checkedOutSharedCart:${id}`, id, 1);
        setCookie('mostRecentSharedCartId', id, 1);
        removeFromLocalStorage(`shared_cart:${location_id}`);
        // The cart loaded from localStorage is already checked out so redirect the user to the order status page
        this.rootStore.uiState.history.push('/order-status');
      } else if (error instanceof UserIsNotMemberOfCartError) {
        removeFromLocalStorage(`shared_cart:${location_id}`);
      }
    }
  };

  /**
   * Loads the cart from localStorage
   * Should only be called after menu-data and fulfillable-items have been loaded
   */
  load = () => {
    const data = localStorage.getItem('cart');
    if (data) {
      runInAction(() => {
        this.isUpdating = true;
      });

      let cart = JSON.parse(data);

      // If the cart does not have a last modified date then remove it and dont load it
      if (!cart.lastModified) {
        localStorage.removeItem('cart');
        cart = null;
      } else {
        const cartLastModified = DateTime.fromISO(cart.lastModified);
        const lastModifiedToNowInDays = DateTime.now().diff(cartLastModified, 'days').toObject().days;

        // If the cart is more than 1 day old then remove that cart from local storage and dont load it
        if (lastModifiedToNowInDays >= 1) {
          localStorage.removeItem('cart');
          cart = null;
        }
      }

      const loadedCart = new Cart(cart, this.rootStore);

      runInAction(() => {
        this.valid_promo_codes = cart?.valid_promo_codes || [];
        this.carts = [loadedCart];
        this.isUpdating = false;
        this.setSelectedCart(loadedCart.id);
        this.loaded = true;
      });

      // NOTE: We only consider the cart to be finished loading once the first getCartPrice call has resolved, this
      // allows for accurate reporting to amplitude of the cart total
    } else {
      const newCart = new Cart(null, this.rootStore);

      runInAction(() => {
        this.carts = [newCart];
        this.isUpdating = false;
        this.setSelectedCart(newCart.id);
        this.loaded = true;
      });
    }
  };

  /** Assumes that the location is already loaded.
   * NOTE: The reason we do not check if the location store is loaded here is because we need to use the loaded state
   * for the location store in the useEffect to ensure that the initial fulfillment method is calculated once the
   * location data loads.
   */
  determineInitialFulfillmentMethodOnPageLoad = () => {
    const { fulfillment_method, possible_fulfillment_methods } = this.rootStore.locationStore;

    if (fulfillment_method === 'patron_choice') {
      let initialFulfillmentMethod = possible_fulfillment_methods[0]?.fulfillment_method;

      // If the user selected Pickup or Delivery option on the map template then be sure to pre-select it here
      const lpFulfillmentSelection = getCookie('previously_selected_fulfillment_method');

      if (lpFulfillmentSelection === MAP_TEMPLATE_TOGGLE_OPTIONS.PICKUP) {
        initialFulfillmentMethod = possible_fulfillment_methods.find((method) =>
          PICKUP_FULFILLMENT_METHODS.includes(method.fulfillment_method)
        )?.fulfillment_method;
      } else if (lpFulfillmentSelection === MAP_TEMPLATE_TOGGLE_OPTIONS.DELIVERY) {
        initialFulfillmentMethod = possible_fulfillment_methods.find((method) =>
          DELIVERY_FULFILLMENT_METHODS.includes(method.fulfillment_method)
        )?.fulfillment_method;
      } else if (
        Location.supportsGivenFulfillmentMethod(
          { fulfillment_method, possible_fulfillment_methods },
          lpFulfillmentSelection
        )
      ) {
        initialFulfillmentMethod = lpFulfillmentSelection;
      }

      this.setFulfillmentMethod(initialFulfillmentMethod);
    } else {
      this.setFulfillmentMethod(fulfillment_method);
    }
  };

  validateExtraCheckoutInfo = () => {
    const { activeConsumerTab } = this.rootStore.tabStore;
    const { required_checkout_info } = this.rootStore.locationStore;

    // If there are required checkout fields then check for invalid submissions
    const errors: Record<string, string> = {};

    if (required_checkout_info) {
      runInAction(() => {
        this.requiredCheckoutInfoErrors = {};
      });

      // Return early since we are just using the required checkout info from previous inputs and this assumes it
      // passed validation when opening
      // NOTE: During opening a tab we create the tab before the first checkout but we only save the extra checkout
      // fields after the first successful checkout
      const setTabId = retrieveFromLocalStorage(`setTabId:${activeConsumerTab?.id}`);
      if (activeConsumerTab && (activeConsumerTab.extraCheckoutInfo?.length > 0 || setTabId)) {
        return errors;
      }

      Object.values(required_checkout_info)
        .filter((info) => !info.method || info.method === this.fulfillment_method)
        .sort((a, b) => (a.display_order ?? 9999) - (b.display_order ?? 9999))
        .forEach((field) => {
          const submittedField = this.extraCheckoutInfo.find((submission) => submission?.key === field.key);
          // If the field is required but null or a string of length 0 then add to errors list
          if (
            field.required &&
            (!submittedField?.value || submittedField.value.length === 0 || /^\s+$/.test(submittedField?.value))
          ) {
            errors[field.key] = `${field.name_for_patron} is required before checkout.`;
          } else if (
            field.required &&
            submittedField?.key === 'address' &&
            DELIVERY_FULFILLMENT_METHODS.includes(this.fulfillment_method) &&
            !submittedField?.value?.canDeliver
          ) {
            // Confirm that the address is within the deliverable range
            // NOTE: The address might be out of range because the address for the customer is an invalid google address
            errors[field.key] = `The address, "${
              submittedField?.value?.formatted_address as string
            }", is outside delivery range.`;
          } else if (
            field.required &&
            submittedField?.key === 'phone_number' &&
            !isPossiblePhoneNumber(submittedField?.value)
          ) {
            errors[field.key] = `Please enter a valid phone number`;
          } else if (field.required && submittedField?.key === 'email' && !validateEmail(submittedField?.value)) {
            errors[field.key] = `Please enter a valid email`;
          }
        });

      runInAction(() => {
        this.requiredCheckoutInfoErrors = errors;
      });
    }

    // If there are no required checkout fields then return empty object
    return errors;
  };

  get hasExtraCheckoutInfoErrors() {
    return Object.values(this.requiredCheckoutInfoErrors)?.length > 0;
  }

  get firstExtraCheckoutInfoErrorMessage() {
    const firstError = Object.values(this.requiredCheckoutInfoErrors)[0];
    return typeof firstError === 'string' ? firstError : null;
  }

  validateUserDesiredTime = async () => {
    const showingDateTimePicker = await this.rootStore.locationStore.showDateTimePicker;

    if (showingDateTimePicker && this.hasAvailableTimeBlocks && this.availableTimeBlocks?.length === 0) {
      if (this.selectedCart?.someItemsAvailable) {
        runInAction(() => {
          this.userDesiredTimeConflict = false;
        });
        throw new BbotLoggedError(
          'There is a problem with your cart; these items are available at different days or times, so they cannot be ordered all together. Please remove some items.',
          {
            customer_id: this.rootStore?.locationStore?.customer?.customer_id,
            endpoint: 'checkoutStore.validateUserDesiredTime',
          }
        );
      } else {
        runInAction(() => {
          this.userDesiredTimeConflict = true;
        });
        throw new BbotLoggedError('The merchant is not accepting orders for the near future. Please come back later.', {
          customer_id: this.rootStore?.locationStore?.customer?.customer_id,
          endpoint: 'checkoutStore.validateUserDesiredTime',
        });
      }
    }
    runInAction(() => {
      this.userDesiredTimeConflict = false;
    });
  };

  get selectedGiftCardsIfAllowed() {
    const { locationStore, userStore } = this.rootStore;
    return locationStore?.customer?.has_gift_card_integration ? userStore.selectedUserGiftCards : [];
  }

  validateCheckout = async () => {
    runInAction(() => {
      this.validatingCheckout = true;
      this.paymentInfoErrors = {};
    });

    const errors = {};

    // Ensure that the cart is valid
    const cartErrors = Cart.validateCartItems(this.selectedCart);
    Object.assign(errors, cartErrors);

    // Ensure that the user has filled out all required checkout info
    const extraCheckoutFieldErrors = this.validateExtraCheckoutInfo();
    Object.assign(errors, extraCheckoutFieldErrors);

    await this.validateUserDesiredTime();

    // Ensure that there are valid checks with valid charges attached that equal the cart total
    const paymentInfoErrors = Cart.validateCartChecks(this.selectedCart);
    Object.assign(errors, paymentInfoErrors);

    runInAction(() => {
      this.validatingCheckout = false;
    });

    return errors;
  };

  buildCheckoutPayload = (cart: Cart, checks: Array<Check>) => {
    const { activeConsumerTab } = this.rootStore.tabStore;
    const { checkout_id, fulfillment_method, customer_id, delivery_info } = this;

    const extra_checkout_info =
      activeConsumerTab && activeConsumerTab.extraCheckoutInfo?.length > 0
        ? activeConsumerTab.extraCheckoutInfo
        : this.extraCheckoutInfoForFulfillmentMethod;

    // Add Location/Customer Data
    const locationData = this.rootStore.locationStore.getLocationCheckoutData();

    // Add Cart Items, Checkout info, Card
    const cartData = Cart.toJSON(cart);

    return {
      checkout_id,
      fulfillment_method,
      customer_id,
      extra_checkout_info,
      delivery_info,
      checks,
      ...locationData,
      ...cartData,
    };
  };

  createLoyaltyAccount = async (checkoutId?: string | null) => {
    if (this.selectedCart.loyaltyToggled) {
      try {
        const customerId = this.rootStore?.locationStore.customer.customer_id;
        const name = this.rootStore.checkoutStore.extraCheckoutInfo.find((field) => field.key === 'name').value;
        const phoneNumber = this.rootStore.checkoutStore.extraCheckoutInfo.find(
          (field) => field.key === 'phone_number'
        ).value;
        const email = this.rootStore?.userStore.user_info?.email;

        if (customerId && phoneNumber) {
          await this.rootStore.api.createExternalLoyaltyAccount({
            phoneNumber,
            customerId,
            name,
            email,
            checkoutId,
          });
        }
      } catch (err) {
        throw new LoyaltyAccountCreationError('Error creating loyalty account.  Please try again.', {
          endpoint: 'checkoutStore.createLoyaltyAccount',
          cause: err.message,
        });
      }
    }
  };

  checkout = async (chargeType: ChargeType) => {
    const activeConsumerTabForTracking = !!this.rootStore.tabStore.activeConsumerTab; // Used for tracking events to determine if a tab is being created/opened

    this.setCheckoutStarting();
    const startTime = DateTime.now(); // Used to track checkout duration
    try {
      this.setCheckoutId();

      if (chargeType === CHARGE_TYPE.TAB) {
        await this.rootStore.userStore.ensureUserHasPrimaryCard();
      }

      const selectedGiftCards = chargeType === CHARGE_TYPE.TAB ? [] : this.selectedGiftCardsIfAllowed;

      // Get the check from the desired charges
      const checks = await Check.configureCartChecks(this.selectedCart, chargeType, selectedGiftCards, this.rootStore);

      // Ensure that all the checkout data is valid now that the cart is up to date
      const checkoutErrors = await this.validateCheckout();

      // If checking out with a consumer tab then ensure its locked since we dont show the UI
      if (
        this.selectedCart.isSharedCart &&
        this.selectedCart.sharedCartReference &&
        this.rootStore.tabStore.activeConsumerTab &&
        chargeType === CHARGE_TYPE.TAB
      ) {
        await this.selectedCart.sharedCartReference.setSharedCartStatus(SHARED_CART_STATE.LOCKED);
      }

      if (Object.keys(checkoutErrors).length) {
        // Leave this so its easy for others to debug
        Object.values(checkoutErrors).forEach((errMessage) => {
          throw new CheckoutValidationError(errMessage, { endpoint: 'checkoutStore.checkout' });
        });
      } else {
        const cartPayload = this.buildCheckoutPayload(this.selectedCart, checks);

        const response = await this.rootStore.api.checkout(cartPayload);
        const endTime = DateTime.now(); // Used to track checkout duration

        await this.handleCheckoutResponse(response);

        // checks will be a single length array when array charge type is tab
        if (chargeType !== CHARGE_TYPE.TERMINAL && checks[0]?.charge?.type === CHARGE_TYPE.TAB) {
          // Ensure that the tab is up to date with the server
          await this.rootStore.tabStore.getTabAsConsumer();
        }

        // If a guest is OPENING a tab then send the trackOpenConsumerTab event
        if (!activeConsumerTabForTracking && chargeType === CHARGE_TYPE.TAB) {
          const tabId = checks[0]?.charge?.tab_id ?? '';
          trackOpenConsumerTab({ tabId });
        }

        const { sharedCartReference } = this.selectedCart ?? {};
        if (sharedCartReference) {
          setCookie(`checkedOutSharedCart:${sharedCartReference.id}`, sharedCartReference.id, 1);
          setCookie('mostRecentSharedCartId', sharedCartReference.id, 1);
          removeFromLocalStorage(`shared_cart:${sharedCartReference.location_id}`);
        } else {
          deleteCookie('mostRecentSharedCartId');
        }

        // Set the default tip amount as a cookie so it can be
        if (chargeType === CHARGE_TYPE.TAB) {
          const tabId: string = checks[0].charge.tab_id; // checks will be a single length array when array charge type is tab
          setCookie(`tab_default_tip_amount:${tabId}`, this.selectedCart?.tip_amount, 1); // NOTE: uses cents
          setCookie(`tab_default_tip_percentage:${tabId}`, this.selectedCart?.tip_percentage, 1);
          setCookie(`tab_default_tip_type:${tabId}`, this.selectedCart?.tip_type, 1);

          Tab.saveRequiredCheckoutInfoToLocalStorage(tabId, cartPayload);
        }

        this.rootStore.uiState.setOrderStatusContext(OrderStatusContext.OrderPlaced);

        this.setGrabAnotherDrinkCheckoutAndTabId(String(this.checkout_id));

        const userInfoForLoyaltyToggle = {
          loyalty_toggled: this.selectedCart.loyaltyToggled,
          first_name: this.rootStore?.userStore?.user_info?.first_name,
          last_name: this.rootStore?.userStore?.user_info?.last_name,
          email: this.rootStore?.userStore?.user_info?.email,
          mobile: this.rootStore?.userStore?.user_info?.mobile,
          anonymous_id: this.rootStore?.userStore?.user_info?.id,
        };

        setTimeout(() => {
          checkoutPageTrackingEvents.trackCheckoutSuccess({
            location_customer_id: this.rootStore?.locationStore?.customer?.customer_id,
            anonymous_id: this.rootStore?.userStore?.user_info?.id,
            ...cartFormatting.formatCartForTracking(this.selectedCart, this.rootStore.locationStore),
            checkoutDuration: endTime.diff(startTime).toObject().milliseconds,
            ...formatGiftCardInfoForTracking(this.rootStore?.userStore?.user_info?.gift_cards ?? [], checks),
            ...userInfoForLoyaltyToggle,
          });
        });

        trackGTMPurchase(
          this.selectedCart,
          cartPayload.checks[0], // just default to first check for now
          this.rootStore.locationStore.customer,
          this.rootStore.locationStore?.locationShortId,
          this.valid_promo_codes.join(', '),
          this.checkout_id,
          chargeType,
          response?.orderIds ?? []
        );

        trackFBPurchaseEvent(this.selectedCart, this.rootStore.locationStore.customer);
        trackGTMSuccessfulCheckout();

        const marketing = this.extraCheckoutInfo.find((i) => i.key === 'marketing_opt_in');
        if (marketing && (marketing.value === true || marketing.value?.length)) {
          saveToLocalStorage('hideMarketingOptIn', true);
        }

        // After a successful checkout, clear the cart and local storage values
        this.rootStore.checkoutStore.selectedCart?.resetCart();

        this.setCheckoutEnded();
      }
    } catch (err) {
      setTimeout(() => {
        checkoutPageTrackingEvents.trackCheckoutFailure({
          location_customer_id: this.rootStore?.locationStore?.customer?.customer_id,
          anonymous_id: this.rootStore?.userStore?.user_info?.id,
          ...cartFormatting.formatCartForTracking(this.selectedCart, this.rootStore.locationStore),
          checkoutDuration: DateTime.now().diff(startTime).toObject().milliseconds,
          errorMessage: err.message,
        });
      });

      // Send checkout error as part of GTM Funnel
      trackGTMFailedCheckout();
      this.setCheckoutEnded(err);

      // If checking out with a consumer tab then ensure its locked since we dont show the UI
      if (
        this.selectedCart.isSharedCart &&
        this.rootStore.tabStore.activeConsumerTab &&
        chargeType === CHARGE_TYPE.TAB
      ) {
        await this.selectedCart.sharedCartReference?.setSharedCartStatus(SHARED_CART_STATE.OPEN);
      }

      if (err instanceof CartItemChangedError) {
        const redirectLink = this.rootStore.locationStore.menuPageHref;
        // You have to reload the page because we do not poll for modifier changes
        notification.error({
          message: err.message,
          onClose: () => (window.location.href = redirectLink),
        });
      } else if (err instanceof InventoryQuantityError) {
        const { isMobile } = this.rootStore.uiState;
        setTimeout(() =>
          scrollToItemInElement(isMobile ? 'mobile-checkout-modal' : null, 'inventory-error-list', 'top')
        );
      }

      // Bubble up error to checkout page
      throw err;
    }

    // Prompts must be reset after checkout for Spendgo integration to work properly, but should not be reset for Thanx.
    if (this.prompts_to_guest.find(({ prompt_id }) => typeof prompt_id === 'string' && prompt_id.includes('spendgo'))) {
      this.setPromptsToGuest([]);
    }

    // Reset promo code to prevent it from automatically being re-applied on a subsequent checkout.
    this.setPromoCode('');
  };

  handleCheckoutResponse = async (response: TODO) => {
    if (response.failures?.length) {
      // Remove all items that have modifiers that have changed values
      response.failures.forEach((failure: TODO) => {
        if (failure.lineItemIdsToRemove?.length > 0) {
          const removedCartItemNames: Array<TODO> = [];
          failure.lineItemIdsToRemove.forEach((lineItemId: number) => {
            const item = this.selectedCart?.items.find((cartItem) => cartItem.hash() === lineItemId);
            removedCartItemNames.push(item.name_for_customer);
            this.selectedCart?.removeItem(item, lineItemId);
          });

          throw new CartItemChangedError(failure.errorCode, {
            endpoint: 'checkoutStore.handleCheckoutResponse',
            cause: removedCartItemNames,
          });
        } else if (
          failure?.insufficient_quantity_items &&
          Object.keys(failure.insufficient_quantity_items)?.length > 0
        ) {
          this.selectedCart?.setInsufficientQuantityItems(failure.insufficient_quantity_items);

          throw new InventoryQuantityError(failure.errorCode, {
            endpoint: 'checkoutStore.handleCheckoutResponse',
            cause: failure.insufficient_quantity_items,
          });
        }
      });

      if (!response.failures[0]?.errorCode && (response.failures?.[0]?.unfulfillable_menuItemIds ?? []).length > 0) {
        // TODO: update items in cart and mark unavailable items as unavailable
        throw new CheckoutError(
          'Some items are now unavailable. Your card was not charged as a result. Please refresh and try again.',
          {
            endpoint: 'checkoutStore.handleCheckoutResponse',
          }
        );
      } else if (
        response.failures[0]?.errorCode === 'Additional verification required.' &&
        response.failures[0]?.payment_intent_id_to_verify
      ) {
        await this.performStripe3dsAuthentication(response.failures[0]);
      } else if (response.failures[0]?.errorCode === TabErrorId.tab_closed_in_pos) {
        // This is technically a less severe error than the tab being closed, but we want to take advantage of the
        // automatic reload when the notification.error closes and this error name is close enough for now.
        throw new CartItemChangedError(
          'Your server has combined your tab with another active tab. Your tab is now closed and you card ' +
            'will not be charged. Please refresh the page.',
          {
            endpoint: 'checkoutStore.handleCheckoutResponse',
          }
        );
      } else {
        // It is a unhandled error and so throw the errors
        throw new CheckoutError(response.failures[0]?.errorCode, { endpoint: 'checkoutStore.handleCheckoutResponse' });
      }
    } else {
      // all successes
      removeFromLocalStorage('userDesiredDate');
      removeFromLocalStorage('userDesiredTime');
      this.rootStore.ordersStore.addSuccessfulOrderIds(response.orderIds || []);
    }
  };

  performStripe3dsAuthentication = async (failedCheckoutResponse: TODO) => {
    const response = await this.rootStore.stripeStore.stripe.confirmCardPayment(
      failedCheckoutResponse.payment_intent_secret
    );
    if (response.paymentIntent != null && response.paymentIntent.status === 'requires_capture') {
      // 3DS Authentication was completed - redo checkout and capture the charge on the backend
      this.rootStore.stripeStore.setPaymentIntentId(failedCheckoutResponse.payment_intent_id_to_verify);
      await this.checkout(CHARGE_TYPE.PAYMENT_INTENT);
    } else if (response.error?.code === 'payment_intent_authentication_failure') {
      // Rotate the checkout_id because the last checkout failed.  If it is not rotated, the server will block checkout for the guest
      // If the checkout process failed here, no charges are authorized so there is not a risk of double charging the guest.
      // This isn't done in a helper function because we should be very careful resetting the checkout_id as it can lead to double charges
      removeFromLocalStorage('checkout_id');
      this.setCheckoutId();
      throw new CheckoutError('Error completing 3DS Authentication.  Please try again.', {
        endpoint: 'checkoutStore.performStripe3dsAuthentication',
        cause: response.error,
      });
    } else if (response.error?.code === 'card_declined') {
      throw new CheckoutError('Your card was declined.', {
        endpoint: 'checkoutStore.performStripe3dsAuthentication',
        cause: response.error,
      });
    } else {
      // Unknown error here.
      throw new CheckoutError('Error completing checkout.  Please refresh and try again.', {
        endpoint: 'checkoutStore.performStripe3dsAuthentication',
        cause: response?.error,
      });
    }
  };

  // ----------------------------------------------------------------
  //
  // Shared Cart
  //
  // ----------------------------------------------------------------

  createNewSharedCartFromMostRecent = async () => {
    try {
      const oldSharedCartId = getCookie('mostRecentSharedCartId');

      // Return early if there is no shared cart id stored in cookies
      if (!oldSharedCartId) {
        return;
      }

      // newSharedCartId will contain either a new shared cart's id or an existing shared cart's id. Either way, start polling for this new shared cart.
      const newSharedCartId = await this.rootStore.api.createSharedCartFromExistingSharedCart(oldSharedCartId);
      const { updated_shared_cart } = (await this.rootStore.api.pollSharedCartUpdates(
        newSharedCartId,
        this.rootStore.locationStore.id
      )) as SharedCartData;
      const sharedCart = await SharedCart.create(this.rootStore, updated_shared_cart, this.selectedCart);
      this.selectedCart?.setSharedCartReference(sharedCart);
    } catch (error) {
      if (!(error instanceof BbotLoggedError)) {
        console.error(error);
      } else if (error instanceof PreviousCartAbandonedError) {
        // If the previous shared cart is abandoned then assume the group is disbanded and remove the cookie
        deleteCookie('mostRecentSharedCartId');
      }
    }
  };

  /**
   * Called from fulfillable menu items poll if curr user is part of a shared cart.
   * if using server side carts, this will also be called when accessing a location code where a consumer
   * has an open cart.
   * @param sharedCartId
   * @returns {Promise<void>}
   */
  rejoinSharedCart = async (sharedCartId: string) => {
    const { id } = this.rootStore.locationStore;
    const { updated_shared_cart } = (await this.rootStore.api.pollSharedCartUpdates(
      sharedCartId,
      id
    )) as SharedCartData;
    const sharedCart = await SharedCart.create(this.rootStore, updated_shared_cart, this.selectedCart);
    this.selectedCart?.setSharedCartReference(sharedCart);
  };

  joinSharedCart = async (
    anonymousUserName: string,
    locationId: string,
    sharedCartId: string,
    secretPassword: string,
    cart: SharedCart,
    secretForLink: string
  ) => {
    this.selectedCart.setItemsToAddToSharedCart(this.selectedCart.items);
    const { joined_cart_id } = await this.rootStore.api.joinSharedCart({
      anonymousUserName,
      locationId,
      sharedCartId,
      secretPassword,
      secretForLink,
    });
    const { updated_shared_cart } = (await this.rootStore.api.pollSharedCartUpdates(
      joined_cart_id,
      locationId
    )) as SharedCartData;
    const sharedCart = await SharedCart.create(this.rootStore, updated_shared_cart, this.selectedCart);
    this.selectedCart?.setSharedCartReference(sharedCart);
  };

  reclaimSharedCart = async (
    locationId: string,
    sharedCartId: string,
    cartOwnerPhoneNumber: string,
    secretForLink: string
  ) => {
    this.selectedCart.setItemsToAddToSharedCart(this.selectedCart.items);
    const { joined_cart_id } = await this.rootStore.api.reclaimSharedCart({
      locationId,
      sharedCartId,
      cartOwnerPhoneNumber,
      secretForLink,
    });
    const { updated_shared_cart } = (await this.rootStore.api.pollSharedCartUpdates(
      joined_cart_id,
      locationId
    )) as SharedCartData;
    const sharedCart = await SharedCart.create(this.rootStore, updated_shared_cart, this.selectedCart);
    this.selectedCart?.setSharedCartReference(sharedCart);
  };

  updateLocalCart = async () => {
    try {
      const { sharedCartReference } = this.selectedCart ?? {};
      const { shared_cart_items } = sharedCartReference ?? {};
      const cartItems = SharedCart.convertSharedCartItemsToCartItems(this.rootStore.menuDataStore, shared_cart_items);
      this.selectedCart.setItemsFromSharedCart(cartItems);
      if (this.rootStore.locationStore.loaded && this.selectedCart?.loaded && sharedCartReference?.loaded) {
        await this.getCartPrice();
        await sharedCartReference.updateFromGetCartPrice();
      }
    } catch (error) {
      console.error(error);
    }
  };

  /**
   * Accepts an anonymous user name if the user is not logged in. If the user (anonymous or logged in) has the same name as another cart owner
   * then they will be prompted to input a cart name which takes president over the user's name being used as the cart
   * name.
   * @param anonymousUserName - Optional
   * @param cartName - Optional
   * @param locationId - Required
   * @returns {Promise<SharedCart>}
   */
  createSharedCart = async (anonymousUserName = null, cartName: string, locationId: string) => {
    this.selectedCart.setItemsToAddToSharedCart(this.selectedCart.items);
    const { created_cart_id } = await this.rootStore.api.createSharedCart(anonymousUserName, cartName, locationId, []);
    const { updated_shared_cart } = (await this.rootStore.api.pollSharedCartUpdates(
      created_cart_id,
      locationId
    )) as SharedCartData;
    const sharedCart = await SharedCart.create(this.rootStore, updated_shared_cart, this.selectedCart);
    this.selectedCart?.setSharedCartReference(sharedCart);
    return sharedCart;
  };

  removeClientConnectionToSharedCart(shouldClearItems = true) {
    const { id } = this.rootStore.locationStore;
    this.selectedCart?.resetCart(shouldClearItems);
    removeFromLocalStorage(`shared_cart:${id}`);
    this.selectedCart?.setSharedCartReference(null);
  }

  leaveSharedCart = async (shouldAbandonItems = false) => {
    const { anonymousUserId } = this.rootStore.userStore;
    const { sharedCartReference } = this.selectedCart ?? {};

    if (!sharedCartReference) {
      return;
    }

    await this.rootStore.api.leaveSharedCart(sharedCartReference.id);

    await SharedCart.convertFromSharedToSingleUserCart(
      this.rootStore.menuDataStore,
      sharedCartReference,
      anonymousUserId,
      shouldAbandonItems
    );
    this.removeClientConnectionToSharedCart(shouldAbandonItems);

    setTimeout(async () => await this.getCartPrice());
  };

  getSharedCartMetaData = async (token: string, locationId: string) => {
    const { shared_cart_metadata, joined_cart_id } = await this.rootStore.api.getSharedCartMetaData(token, locationId);
    if (joined_cart_id) {
      const { updated_shared_cart } = (await this.rootStore.api.pollSharedCartUpdates(
        joined_cart_id,
        locationId
      )) as SharedCartData;
      const sharedCart = await SharedCart.create(this.rootStore, updated_shared_cart, this.selectedCart);
      this.selectedCart?.setSharedCartReference(sharedCart);
      const url = deleteQueryParamsFromUrl('token', 'reclaimHost');
      this.rootStore.uiState?.history?.replace(`${url.pathname}${url.search}`);
      notification.success({
        message: `You have successfully joined ${sharedCart?.cartOwner?.name}'s group order!`,
      });
    } else {
      return shared_cart_metadata;
    }
    return '';
  };

  isTagBOGO = (tagName: string) => {
    const tagIsBOGO = tagName.toUpperCase().includes('BOGO') || tagName.toUpperCase().includes('BUY ONE GET ONE FREE');
    if (tagIsBOGO) {
      this.addBOGOTag(tagName.toUpperCase());
    }
    return tagIsBOGO;
  };

  addBOGOTag(BOGOName: string): void {
    runInAction(() => {
      this.BOGOTags.add(BOGOName);
    });
  }

  get BOGOConditionsText() {
    if (this.BOGOTags.size > 1) {
      return 'can mix and match between item groups. The lowest priced item in that group will recieve the discount.';
    }
    return 'can mix and match between items. The lowest priced item will receive the discount.';
  }
}
