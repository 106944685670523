/* eslint-disable @typescript-eslint/no-non-null-assertion */
import { runInAction, makeAutoObservable } from 'mobx';

// Constants
import { BbotLoggedError, StripeError, StripeCardValidationError, CheckoutValidationError } from 'constants/Errors';
import { CHARGE_TYPE } from 'constants/Checkout';

// Models
import Cart from 'models/Cart';

// Services
import * as StripeService from 'services/StripeService';

// Tracking
import { checkoutPageTrackingEvents } from 'integrations/segment/tracking-events';

// Types
import RootStore from 'stores/RootStore';
import TransportLayer from 'api/TransportLayer';
import Customer from 'models/Customer';

import {
  Stripe,
  StripePaymentRequestButtonElement,
  StripePaymentRequestButtonElementClickEvent,
  PaymentRequest,
  StripeElements,
  SetupIntent,
  CanMakePaymentResult,
  PaymentRequestTokenEvent,
  CreateTokenCardData,
} from '@stripe/stripe-js';

// Types
import { PaymentRequestPaymentMethod } from 'services/StripeService';
import { LockType } from './LockStore';

// REFERENCE: https://stripe.com/docs/payments/integration-builder

export default class StripeStore {
  stripe: Stripe | null = null;
  customer: Customer | null = null;
  customer_name: string = '';
  country_code: string | null = null;
  currency: string | null = null;
  loaded: boolean = false;
  setupIntent?: SetupIntent;
  setupIntentPromise?: Promise<SetupIntent>;
  error?: StripeError | null = null;
  addCardError?: StripeError = undefined;

  // These error fields are only undefined when first render, then they are either an empty string or string with error message
  cardNumberError?: string | undefined = undefined;
  cardExpiryError?: string | undefined = undefined;
  cardCvcError?: string | undefined = undefined;

  selectedPanel?: string;

  paymentRequestButtonElement: StripePaymentRequestButtonElement | null = null;
  paymentRequest: PaymentRequest | undefined;
  canMakePayment?: CanMakePaymentResult | Record<string, boolean>;
  paymentRequestToken: string | null = null; // Used for Apple pay and Google Pay
  paymentIntentId: string | null = null; // Id of payment intent that needs 3D Secure Authentication
  elements?: StripeElements;
  stipeTokenOptions?: CreateTokenCardData;
  formReady = false;

  result = null;

  // Used to show the error above the clicked button (in this case the payment request button)
  paymentRequestButtonClicked = false;

  // NOTE: this is value that reflects what is stored in the input at the current time
  zipcode = ''; // Guest's zipcode on there card

  rootStore: RootStore;
  api: TransportLayer;

  constructor(rootStore: RootStore) {
    this.rootStore = rootStore;
    this.api = rootStore.api;

    this.setup();

    makeAutoObservable(this, {
      rootStore: false,
      api: false,
      stripe: false,
    });
  }

  async setup() {
    await this.rootStore.lockStore.waitForRelease(LockType.UserDataLoad);
    const stripe = await StripeService.getStripeInstance(this.rootStore.userStore.stripeAPIKey);

    runInAction(() => {
      this.stripe = stripe;
      this.loaded = true;
    });
  }

  get errorMessage() {
    if (this.error) {
      return this.error.message;
    } else {
      return null;
    }
  }

  setSelectedPanel(selectedPanel: string | undefined): void {
    runInAction(() => {
      this.selectedPanel = selectedPanel;
    });
  }

  setElements = (elements?: StripeElements) => {
    runInAction(() => {
      this.elements = elements;
    });
  };

  setFormReady = (formReady: boolean) => {
    runInAction(() => {
      this.formReady = formReady;
    });
  };

  setPaymentIntentId = (paymentIntentId: string) => {
    runInAction(() => {
      this.paymentIntentId = paymentIntentId;
    });
  };

  setPaymentRequestToken = async (token: string) => {
    runInAction(() => {
      this.paymentRequestToken = token;
    });
  };

  setStripeTokenOptions = (options: CreateTokenCardData) => {
    runInAction(() => {
      this.stipeTokenOptions = options;
    });
  };

  setCustomerData = (customer: Customer) => {
    runInAction(() => {
      this.customer = customer;
      this.customer_name = customer?.customer_name;
      this.country_code = customer?.physical_address?.country_code || 'US'; // NOTE: Not all customers have their physical address inputted
      this.currency = customer?.currency || 'usd';
    });
  };

  setAddCardError = (error: StripeError | undefined) => {
    runInAction(() => {
      this.addCardError = error;
    });
  };

  setCardNumberError = (error: string | undefined) => {
    runInAction(() => {
      this.cardNumberError = error;
    });
  };

  setCardExpiryError = (error: string | undefined) => {
    runInAction(() => {
      this.cardExpiryError = error;
    });
  };

  setCardCvcError = (error: string | undefined) => {
    runInAction(() => {
      this.cardCvcError = error;
    });
  };

  // Use this to determine if we should throw an error before calling stripeInstance.createToken().
  // If any of these conditions are true, then we should wait for stripe to throw an error
  get expectingStripeFieldErrors() {
    return (
      this.cardCvcError !== '' ||
      this.cardCvcError === undefined ||
      this.cardNumberError !== '' ||
      this.cardNumberError === undefined ||
      this.cardExpiryError !== '' ||
      this.cardExpiryError === undefined
    );
  }

  reset = () => {
    runInAction(() => {
      this.paymentRequestButtonClicked = false;
    });
  };

  setPaymentRequestButtonElement = (prElement: StripePaymentRequestButtonElement) => {
    runInAction(() => {
      this.paymentRequestButtonElement = prElement;
    });

    this.paymentRequestButtonElement?.on('click', this.handlePaymentRequestOnClick);
  };

  confirmSetup = async (elements?: StripeElements) => {
    try {
      const stripeElements = elements ?? this.elements;

      if (!stripeElements) {
        throw new Error("We weren't able to confirm your card. Please refresh the page and try again.");
      }

      const selectedGiftCards = this.rootStore.checkoutStore.selectedGiftCardsIfAllowed;
      const setupIntent = await StripeService.confirmSetup(stripeElements, Boolean(selectedGiftCards.length));
      this.resetSetupIntent();
      await this.getSetupIntent();
      return setupIntent;
    } catch (error: any) {
      if (error instanceof StripeCardValidationError || error instanceof StripeError) {
        throw error;
      }

      throw new BbotLoggedError(error.message || 'There was an error confirming your card.', {
        endpoint: 'stripe.handleAddCard',
        customer_id: this.rootStore?.locationStore?.customer?.customer_id,
        cause: error,
      });
    }
  };

  /**
   * Used after the setupIntent is spent. This allows the UI to get a new setupItent from the backend so that the user
   * can save another card.
   */
  resetSetupIntent(): void {
    runInAction(() => {
      this.setupIntentPromise = undefined;
    });
  }

  // Cannot be a getter because it must be async to wait for the api endpoint to resolve
  async getSetupIntentFromBackend(): Promise<SetupIntent | undefined> {
    // eslint-disable-next-line no-useless-catch
    try {
      if (!this.setupIntentPromise) {
        runInAction(() => {
          this.setupIntentPromise = this.api.getStripeIntent();
        });
      }

      return await this.setupIntentPromise;
    } catch (error) {
      throw error;
    }
  }

  async getSetupIntent() {
    const intent = await this.getSetupIntentFromBackend();
    runInAction(() => {
      this.setupIntent = intent;
    });
    return this.setupIntent;
  }

  /**
   * NOTE: This must be called synchronously
   */
  handlePaymentRequestOnClick = (event: StripePaymentRequestButtonElementClickEvent) => {
    checkoutPageTrackingEvents.trackClickCheckoutGoogleApplePayButton();
    try {
      runInAction(() => {
        this.paymentRequestButtonClicked = true; // Used to display the error above the payment request button if an error occurs
      });

      const errors = {};

      // Ensure that the cart is valid
      const cartErrors = Cart.validateCartItems(this.rootStore.checkoutStore.selectedCart);
      Object.assign(errors, cartErrors);

      // Ensure that the user has filled out all required checkout info
      const extraCheckoutFieldErrors = this.rootStore.checkoutStore.validateExtraCheckoutInfo();
      Object.assign(errors, extraCheckoutFieldErrors);

      if (Object.keys(errors).length) {
        // Leave this so its easy for others to debug
        Object.values(errors).forEach((errMessage) => {
          console.error(errMessage);
          throw new CheckoutValidationError(errMessage, { endpoint: 'StripeStore.handlePaymentRequestOnClick' });
        });
      }
    } catch (err) {
      console.error(err);
      event.preventDefault(); // Stripe Recommended
      this.rootStore.checkoutStore.setCheckoutEnded(err);
    }
  };

  /**
   * NOTE: This must be called synchronously
   */
  handlePaymentRequestOnCancel = (_event: Event) => {
    runInAction(() => {
      this.paymentRequestButtonClicked = false;
    });
    this.rootStore.checkoutStore.setCheckoutEnded();
  };

  handlePaymentRequestOnToken = async (event: PaymentRequestTokenEvent) => {
    try {
      // Update the paymentToken so that
      if (event.token?.id) {
        await this.setPaymentRequestToken(event.token.id);
      } else {
        throw new BbotLoggedError('Error processing token from Stripe.', {
          customer_id: this.rootStore?.locationStore?.customer?.customer_id,
          endpoint: 'StripeStore.handlePaymentRequestOnToken',
        });
      }

      await this.rootStore.checkoutStore.checkout(CHARGE_TYPE.APPLE_PAY);
      this.rootStore.checkoutStore.setCheckoutEnded();
      event.complete('success');
      this.rootStore.uiState.redirect('/order-status');
    } catch (err) {
      console.error(err);
      this.rootStore.checkoutStore.setCheckoutEnded(err);
      event.complete('fail');
    }
  };

  /**
   * Used to configure the Google Pay, Apple Pay button. We pass the customer to this function because the payment
   * request could use either the host_customer or the location_customer. We do not allow Google Pay / Apple Pay for
   * Party Tab creation because of incremental authorization but to make this easy to read and debug we pass the
   * customer.
   *
   * @param customer
   * @returns {Promise<void>}
   */
  // eslint-disable-next-line consistent-return
  setUpPaymentRequest = async (customer: Customer): Promise<void> => {
    try {
      // NOTE: The Payment request options, in this case the customer, can be updated as per docs
      // https://stripe.com/docs/js/payment_request/create#stripe_payment_request-options
      // Set up listeners (NOTE: the on click listener is attached on a useEffect in AppleGooglePayButton)
      const pr = StripeService.setUpPaymentRequest(
        customer,
        // eslint-disable-next-line @typescript-eslint/no-misused-promises
        this.handlePaymentRequestOnToken,
        this.handlePaymentRequestOnCancel
      );
      runInAction(() => {
        this.paymentRequest = pr;
      });

      if (this.paymentRequest) {
        const makePayment = await StripeService.canMakePaymentForEnvironment(this.paymentRequest);
        runInAction(() => {
          this.canMakePayment = makePayment;
        });
        this.rootStore.checkoutStore.loadSelectedPaymentMethod();
      }
    } catch (error) {
      console.error(error);
    }
  };

  // NOTE: This does not take into account if there is an active consumer tab.
  get supportedPaymentRequestMethods(): Array<{ id: PaymentRequestPaymentMethod }> {
    return this.canMakePayment ? StripeService.validPaymentMethodsForEnvironment(this.canMakePayment) : [];
  }

  resetPaymentRequest = () => {
    runInAction(() => {
      this.paymentRequest = undefined;
    });
  };
}
