import styled from 'styled-components';

// Components
import { Alert } from 'bbot-component-library';

// Types
import { AlertProps } from 'antd';

export const StyledAlert: React.FunctionComponent<AlertProps> = styled(Alert)`
  margin-bottom: 1.25rem;

  gap: 5px;

  .anticon {
    order: 1;
  }
`;
