/* eslint-disable prefer-promise-reject-errors */
// Constants
import { BLACK_WHITE_MAP } from 'constants/GoogleMapStyles';
import { BbotLoggedError } from 'constants/Errors';

// Utils
import { loadScript } from 'utils/LoadScript';
import {
  appendCustomerHaversineDistance,
  convertDistanceToMiles,
  extractBbotAddressFields,
  getCoordsFromAddressObject,
  getWithinHaversineDistanceFromPatron,
} from 'utils/GooglePlaceUtils';

export default class GoogleMapsAPI {
  rootStore = null;

  AutocompleteService = null;
  PlacesService = null;
  DirectionsService = null;
  maps = null;

  mapElement = null;
  searchLocation = null;
  searchLocationMarker = null;
  markers = [];

  _googleApiTimeoutInterval = null;

  constructor(rootStore) {
    this.rootStore = rootStore;
    if (window?.google?.maps) {
      this.initGoogleMaps();
      this.initAutoCompleteService();
      this.initDirectionService();
    } else {
      this.loadGoogleScript();
    }
  }

  /**
   * Loads the google maps script
   */
  loadGoogleScript() {
    // TODO: grab the language and region the user is in so Google maps can be in the right language
    // const defaultLanguage = window.navigator.language.slice(0, 2);
    // const defaultRegion = window.navigator.language.slice(3, 5);

    loadScript(
      `https://maps.googleapis.com/maps/api/js?key=${process.env.REACT_APP_GOOGLE_API_KEY}&libraries=places`,
      () => {
        this.initGoogleMaps();
        this.initAutoCompleteService();
        this.initDirectionService();
        this.initPlacesService();
      }
    );
  }

  initGoogleMaps() {
    this.maps = window.google.maps;
  }

  initAutoCompleteService() {
    this.AutocompleteService = new this.maps.places.AutocompleteService();
  }

  initPlacesService() {
    this.mapElement = document.createElement('div');
    this.PlacesService = new this.maps.places.PlacesService(this.mapElement);
  }

  initDirectionService() {
    this.DirectionsService = new this.maps.DirectionsService();
  }

  /**
   * NOTE: This should be debounced by at least 1 second to prevent Google API rate limiting
   * @param text from an address search input
   * @returns {Promise<unknown>}
   */
  getAutoCompletePredictions = async (text: string) =>
    await new Promise((resolve, reject) => {
      if (!text) {
        return reject('Need valid text input');
      }

      try {
        this.AutocompleteService.getPlacePredictions({ input: text, types: ['geocode'] }, resolve);
      } catch (error) {
        reject(error);
      }
      return null;
    });

  /**
   * @param placeId is a place_id returned from the selected autocomplete service prediction
   * @returns {Promise<unknown>}
   */
  getPlaceDetails = async (placeId) =>
    await new Promise((resolve, reject) => {
      if (!placeId) {
        return reject('Need valid google place id');
      }

      try {
        this.PlacesService.getDetails(
          { placeId, fields: ['address_component', 'formatted_address', 'geometry.location', 'place_id'] },
          resolve
        );
      } catch (error) {
        reject(error);
      }
      return null;
    });

  /*
   * Initializes the interactive google maps api.
   * @return Object
   * */
  initInteractiveMap(htmlElement, centerCoords, zoomLevel) {
    try {
      if (!centerCoords) {
        throw new BbotLoggedError('Cannot load map without coordinates to center the map on.', {
          customer_id: this.rootStore?.hostStore?.host_customer?.customer_id,
          endpoint: 'initInteractiveMap',
        });
      }
      const map = new this.maps.Map(htmlElement, {
        center: centerCoords,
        zoom: zoomLevel,
        styles: BLACK_WHITE_MAP,
      });
      return map;
    } catch (error) {
      console.error(error);
      return null;
    }
  }

  placeMarkerAndPanTo = (latLng, map) => {
    const marker = new this.maps.Marker({
      label: 'C',
      position: latLng,
    });
    marker.setMap(map);
    map.panTo(latLng);
    map.setZoom(14);
    return marker;
  };

  drawMarkerOnMap = (coords, map, icon) =>
    new this.maps.Marker({
      position: coords,
      map,

      // if they pass an icon parameter then use their icon
      icon: icon && {
        url: icon,
        scaledSize: new this.maps.Size(40, 40),
      },
    });

  drawInfoWindow = () =>
    new this.maps.InfoWindow({
      content: ``,
    });

  /**
   * Set the address that the user searched for so it can be used by other helper functions.
   * @param addrObj
   */
  setSearchLocation = (addrObj) => {
    this.searchLocation = addrObj;
  };

  /**
   * Set the search location map marker and delete the existing one if there is one.
   * @param marker {Google Maps Marker}
   * @param map {Google Maps Map}
   */
  resetSearchLocationMarker = (marker, map) => {
    if (this.searchLocationMarker) {
      this.searchLocationMarker.setMap(null);
    }
    this.searchLocationMarker = marker;
  };

  getLatLngGivenPhysicalAddress = async (pysical_address_obj = {}) => {
    try {
      const { street, city, state, zip, country_code } = pysical_address_obj;
      const inferredAddress = [street, city, state, zip, country_code].join(' ');

      const predictions = await this.getAutoCompletePredictions(inferredAddress);
      const place = await this.getPlaceDetails(predictions[0]?.place_id);

      // Assume that we take the first match
      const googleAddress = { latitude: null, longitude: null, google_place: place };
      if (typeof place?.geometry?.location?.lat === 'function') {
        googleAddress.latitude = place.geometry.location.lat();
        googleAddress.longitude = place.geometry.location.lng();
      } else {
        googleAddress.latitude = place?.geometry?.location?.lat;
        googleAddress.longitude = place?.geometry?.location?.lng;
      }
      return googleAddress;
    } catch (err) {
      console.error(err);
      return { latitude: null, longitude: null, google_place: {} };
    }
  };

  getLocationsWithinRangeOfPatron = async (customersById, patronAddress, locations) => {
    const addressFields = extractBbotAddressFields(patronAddress);

    // Create an array of objects with associated haversine distance
    let customersWithinHaversineDistance = Object.values(
      await appendCustomerHaversineDistance(customersById, addressFields)
    );

    const locationsList =
      locations?.length &&
      locations
        .slice()
        .map((location) => {
          location = { ...location }; // Prevent mobx from throwing a warning about updating object outside runInAction
          const customer = customersWithinHaversineDistance.find((c) => location.customer === c.customer_id);

          if (!(customer?.haversineDistanceInMiles >= 0)) {
            return null;
          } // Filter out locations where the customer attached to the location does not have an address
          location.distanceInMiles = customer.haversineDistanceInMiles;
          location.address = customer?.physical_address;
          location.phone_number = customer?.phone_for_patrons;
          return location;
        })
        .filter((loc) => loc); // Filter out locations where the customer attached to the location does not have an address

    // representing customer addresses we should run an actual distance check for.
    // This should filter most of the results without hitting remote apis multiple times.
    customersWithinHaversineDistance = customersWithinHaversineDistance.filter(
      (customer) => customer.isWithinHaversine
    );

    // Because we check if any customer has a delivery integration, if nothing is in haversine range show an error.
    if (!customersWithinHaversineDistance.length) {
      return;
    }

    const finalListOfInRangeCustomersPromises = customersWithinHaversineDistance.map(
      async (customer) => await this.checkAddressIsWithinRangeOfCustomer(customer, patronAddress)
    );

    let finalListOfInRangeCustomers = [];
    try {
      finalListOfInRangeCustomers = await Promise.all(finalListOfInRangeCustomersPromises);
      finalListOfInRangeCustomers = finalListOfInRangeCustomers.filter((customer) => customer?.canDeliver);
    } catch (error) {
      if (!(error instanceof BbotLoggedError)) {
        console.error(error);
      }
    }

    // Use finalListOfInRangeCustomers to produce a final list of locationsToShow
    const customersByIdWithinRange = Object.assign(
      {},
      ...finalListOfInRangeCustomers.map((customer) => ({ [customer.customer_id]: customer }))
    );

    // eslint-disable-next-line consistent-return
    return locationsList
      .filter((location) => Object.keys(customersByIdWithinRange).includes(location.customer) && !location.external_url)
      .map((location) => {
        const customer = customersByIdWithinRange[location.customer];
        location.distanceInMiles = customer.distanceInMiles || customer.haversineDistanceInMiles;
        location.address = customer?.physical_address;
        location.phone_number = customer?.phone_for_patrons;
        return location;
      });
  };

  getInRangeCustomersFromDeliveryIntegration = async (customers, addressFields) => {
    // customers: array of customers that will get 'canDeliver' appended to them by end of function
    // addressFields: google place address fields that gets passed into an API call for a delivery integration
    try {
      const deliveryIntegrationPromises = customers.map((customer) =>
        this.rootStore.api.checkIfAddressIsDeliverableTo(customer, addressFields)
      );
      return await Promise.all(deliveryIntegrationPromises);
    } catch (error) {
      new BbotLoggedError(
        error.message || `Cannot retrieve deliverability for one or more customer(s) from delivery integration.`,
        {
          endpoint: `getInRangeCustomersFromDeliveryIntegration`,
          customer_id: this.rootStore?.hostStore?.host_customer?.customer_id,
          cause: error,
        }
      );
      return [];
    }
  };

  logGoogleApiFailure = (error) => {
    console.error('Google failed with error of ', error);
  };

  checkAddressIsWithinRangeOfCustomer = async (customer, addressObject) => {
    const addressFields = extractBbotAddressFields(addressObject);
    const listOfRequiredAddressFields = ['streetNumber', 'streetName', 'street', 'city', 'state', 'zip'];

    // verify that the address contains all the fields it needs to
    listOfRequiredAddressFields.forEach((key) => {
      if (!addressFields[key].trim()) {
        let label = null;
        switch (key) {
          case 'streetNumber':
            label = 'street number';
            break;
          case 'streetName':
            label = 'street name';
            break;
          default:
            label = addressFields[key].trim();
        }

        throw new BbotLoggedError(
          `The given address must include the ${label} but does not. Please select an address that includes a ${label}.`,
          {
            endpoint: 'GoogleMapsApi/checkAddressIsWithinRangeOfCustomer',
            customer_id: customer.customer_id,
            cause: customer?.physical_address,
          }
        );
      }
    });

    // Here is a fail safe
    if (!customer.physical_address?.latitude || !customer.physical_address?.longitude) {
      const neededData = await this.getLatLngGivenPhysicalAddress(customer?.physical_address);

      customer = { ...customer };
      customer.physical_address = { ...customer.physical_address };
      customer.physical_address.latitude = neededData.latitude;
      customer.physical_address.longitude = neededData.longitude;
      customer.physical_address.google_place = neededData.google_place;
    }

    // eslint-disable-next-line @typescript-eslint/return-await
    return await this.rootStore.api.checkIfAddressIsDeliverableTo(customer, addressFields);
  };

  getCustomerDistanceFromGivenAddress = async (customer, addressFields) => {
    const haversineUpdatedCustomer = await getWithinHaversineDistanceFromPatron(customer, addressFields);

    if (haversineUpdatedCustomer.isWithinHaversine) {
      const { distanceInMiles } = await this.getDrivingDistanceToPatron(haversineUpdatedCustomer, addressFields);
      return distanceInMiles;
    } else {
      return haversineUpdatedCustomer.haversineDistanceInMiles;
    }
  };

  getDrivingDistanceToPatron = async (customer, addressObj) =>
    // The google directionsService api call doesn't return a promise so we'll wrap it in one.
    // This allows us to await an array of driving distances for the array of customers determined to be in range.
    // We can then manually resolve and reject inside the callback functions
    await new Promise((resolve, reject) => {
      const customerCoordinates = {
        lat: customer.physical_address?.latitude,
        lng: customer.physical_address?.longitude,
      };
      if (!customerCoordinates.lat || !customerCoordinates.lng) {
        throw new BbotLoggedError(
          `Customer coordinates for ${
            customer?.customer_name
          } are null or invalid. Please go to account settings page and re-save the google address. \n ${JSON.stringify(
            customer?.physical_address,
            null,
            3
          )}`,
          {
            endpoint: 'GoogleMapsApi/getDrivingDistanceToPatron',
            customer_id: customer?.customer_id,
            cause: customer?.physical_address,
          }
        );
      }
      const patronCoordinates = getCoordsFromAddressObject(addressObj);

      if (!patronCoordinates.lat || !patronCoordinates.lng) {
        throw new BbotLoggedError(
          `Invalid patron coordinates latitude: ${patronCoordinates.lat}, longitude: ${
            patronCoordinates.lng
          }. \n ${JSON.stringify(patronCoordinates, null, 3)}`,
          {
            endpoint: 'GoogleMapsApi/getDrivingDistanceToPatron',
            customer_id: customer.customer_id,
            cause: addressObj,
          }
        );
      }

      if (this._googleApiTimeoutInterval) {
        // Set an interval to guard against google api failures.
        clearInterval(this._googleApiTimeoutInterval);
      }
      this._googleApiTimeoutInterval = setInterval(() => this.logGoogleApiFailure('API_TIMEOUT'), 8000, 1);

      this.DirectionsService.route(
        {
          origin: customerCoordinates,
          destination: patronCoordinates,
          travelMode: 'DRIVING',
        },
        (response, status) => {
          if (status === 'OK' && response.routes[0].legs[0].distance) {
            clearInterval(this._googleApiTimeoutInterval);

            const { distance } = response.routes[0].legs[0];

            // Distance is in meters, so convert it to miles
            resolve({ distance, distanceInMiles: convertDistanceToMiles(distance.value) });

            // If the user is somehow over their query limit, log it as a google API failure.
            // If we start seeing a lot of these we'll need to write more code to heed google's 1 request per second once over query limit is reached.
          } else if (status === 'OVER_QUERY_LIMIT') {
            clearInterval(this._googleApiTimeoutInterval);
            this.logGoogleApiFailure(status);

            // We want to fail open, so resolve with a customer that has distance set to 0.
            // This is okay because we know it's within haversine distance at the minimum.
            // We'll fall back on allowing the successful promises through, if any others succeeded
            resolve({ distance: 0, distanceInMiles: 0 });

            // Google maps request throws one of many errors including not found, no route between addresses,
            // or invalid request. We catch all of them and tell the user to enter a valid address.
          } else {
            clearInterval(this._googleApiTimeoutInterval);

            // Attach the google maps error response and return the customer as the promise rejection payload.
            reject({ distance: null, distanceInMiles: null });
          }
        }
      );
    });
}
