import { GetUpsellsForItemType } from './Types';
import { customCheckoutText } from './customCheckoutText';
import { onPremiseUpsellsDictionary } from './onPremiseUpsellsDictionary';

export const getCustomCheckoutTextFromCustomerId = (customerId: string) => {
  if (customerId === undefined) {
    return '';
  }
  return customCheckoutText[customerId] ?? '';
};

export const getUpsellsForCurrentMenu = (menuId: string) => {
  if (menuId === undefined) {
    return {};
  }
    const upsellsForCurrentMenu = onPremiseUpsellsDictionary.menus.find((dictionary: any) => dictionary.id === menuId) ?? {};
    return upsellsForCurrentMenu;
};

const getUpsellsForMenuHeading = (menuId: string, menuHeadingId: string) =>
  getUpsellsForCurrentMenu(menuId)?.menu_heading?.find((menuHeading: any) => menuHeading.id === menuHeadingId) ?? [];

const getUpsellsForMenuItem = (menuId: string, menuItemId: string) =>
  getUpsellsForCurrentMenu(menuId)?.menu_item?.find((menuItem: any) => menuItem.id === menuItemId) ?? [];

const getDVUpsellsForItem = (menuId: string, menuHeadingId: string, menuItemId: string) =>
  getUpsellsForMenuItem(menuId, menuItemId)?.recommended_items?.length
    ? getUpsellsForMenuItem(menuId, menuItemId)
    : getUpsellsForMenuHeading(menuId, menuHeadingId);

export const getUpsellsForItem = ({
  menuId,
  menuHeadingId,
  menuItemId,
  menuItem,
  menuHeading,
}: GetUpsellsForItemType) => {
  if (menuItem?.upsells.length) {
    return { id: menuItem.id, recommended_items: menuItem.upsells };
  }
  if (menuHeading?.upsells.length) {
    return { id: menuHeading.id, recommended_items: menuHeading.upsells };
  }
  return getDVUpsellsForItem(menuId, menuHeadingId, menuItemId);
};
