import { BBOT_ENVIRONMENTS, CurrentEnvironment } from 'constants/Environments';
import { removePrefix } from 'utils/Utility';
import { Capacitor } from '@capacitor/core';
import { getKioskHostname } from 'utils/KioskConfig';

// Get the top level domain root url
export const getRootUrl = () => {
  const port = window.location.port.length ? window.location.port : null;
  return `${window.location.protocol}//${window.location.hostname}${port ? `:${port}` : ''}`;
};

export const getEnvironment = () => {
  if (process.env.REACT_APP_BBOT_ENVIRONMENT) {
    return process.env.REACT_APP_BBOT_ENVIRONMENT;
  } else {
    return 'prod';
  }
};

export const getAPIUrl = () => {
  const url = new URL(window.location as any);
  const { hostname } = url;

  // Required for testing on BrowserStack. See: https://www.browserstack.com/question/759
  if (hostname === 'bs-local.com') {
    return `http://bs-local.com:${process.env.REACT_APP_DEV_API_PORT ?? '8000'}`;
  }

  if (
    process.env.NODE_ENV === 'development' &&
    (hostname.includes('localhost') || hostname.match(/^(?:[0-9]{1,3}\.){3}[0-9]{1,3}$/))
  ) {
    // handles localhost, and IP addresses
    let apiUrl = 'http://'; // Note: it is not possible to read the environment variable HTTPS, it always return undefined
    apiUrl += hostname.split(':')[0];
    apiUrl += `:${process.env.REACT_APP_DEV_API_PORT ?? '8000'}`;
    return apiUrl;
  } else {
    const parsedHostname = removePrefix(hostname, 'app.');

    if (parsedHostname.endsWith('.bbot.menu')) {
      // Is a bbot domain
      switch (process.env.REACT_APP_BBOT_ENVIRONMENT) {
        case BBOT_ENVIRONMENTS.DEV:
          return 'https://api.dev.bbot.menu';
        case BBOT_ENVIRONMENTS.STAGING:
          return 'https://api.staging.bbot.menu';
        case BBOT_ENVIRONMENTS.DEMO:
          return 'https://api.demo.bbot.menu';
        default:
          return 'https://api.bbot.menu';
      }
    } else {
      // Is a Whitelabel
      return `https://api.${parsedHostname}`;
    }
  }
};

export const getHostName = async () => {
  // Android Build
  if (Capacitor.getPlatform() === 'android') {
    // eslint-disable-next-line no-return-await
    return await getKioskHostname();
  }

  // Web Build
  const url = new URL(window.location as any);
  const hostname = removePrefix(window.location.hostname, 'app.');
  const isBbotHostname = Boolean(hostname === 'bbot.menu' || hostname.endsWith('.bbot.menu'));

  if (url.searchParams.has('hostname') && !url.searchParams.get('hostname')) {
    localStorage.removeItem('hostname');
  }
  const previewHostname = url.searchParams.get('hostname') ?? localStorage.getItem('hostname');

  if (url.searchParams.has('document_id')) {
    localStorage.setItem('document_id', url.searchParams.get('document_id') ?? '');
  } else {
    localStorage.removeItem('document_id');
  }

  if (CurrentEnvironment.isProd) {
    if (isBbotHostname && previewHostname) {
      localStorage.setItem('hostname', previewHostname);
      return previewHostname;
    }
    if (localStorage.getItem('hostname')) {
      localStorage.removeItem('hostname');
    }
    return hostname;
  } else {
    if (previewHostname) {
      localStorage.setItem('hostname', previewHostname);
      return previewHostname;
    } else if (process.env.NODE_ENV === 'development' && hostname.includes('bbot-consumer-localdev.ngrok.io')) {
      return hostname;
    } else if (
      process.env.NODE_ENV === 'development' &&
      (hostname.includes('localhost') || hostname.match(/^(?:[0-9]{1,3}\.){3}[0-9]{1,3}$/))
    ) {
      return process.env.REACT_APP_HOST_AS;
    }

    return hostname;
  }
};

export const getPreviewBannerParams = (): {
  redirectUrl?: string;
  message?: string;
  showPreviewBanner: string | null | boolean;
} => {
  // When previewing the consumer app, a preview banner will be shown
  // In some instances, a redirect url will be offered to get out of redirect mode.

  const hostnameLocalStorageValue = localStorage.getItem('hostname');
  const documentIdLocalStorageValue = localStorage.getItem('document_id');
  const showPreviewBanner = hostnameLocalStorageValue ?? documentIdLocalStorageValue;

  // Never show the preview banner for android
  if (Capacitor.getPlatform() === 'android') {
    return { showPreviewBanner: false };
  }

  // Never show a redirect url in the BOS Builder, users know it's a preview.
  if (window.location !== window.parent.location) {
    return { showPreviewBanner };
  }

  if (CurrentEnvironment.isProd) {
    // previewing a live site such as https://lpqusa.menu/?document_id=123456789
    if (!hostnameLocalStorageValue && documentIdLocalStorageValue) {
      return {
        redirectUrl: window.location.origin,
        message: 'If you want to view the published site:',
        showPreviewBanner,
      };
    }
    return { showPreviewBanner };
  } else {
    // all other environments
    // previewing a preview link from the BOS Builder (staging.bbot.menu/?hostname=junzi.menu&document_id=123456789)
    if (hostnameLocalStorageValue && documentIdLocalStorageValue) {
      return {
        redirectUrl: `${window.location.origin}/?hostname=${hostnameLocalStorageValue}`,
        message: 'If you want to view what the published site would look like:',
        showPreviewBanner,
      };
    } else if (hostnameLocalStorageValue) {
      // previewing a "live" or "published" version of the hostname on local, dev, staging (localhost:8000/?hostname=junzi.menu)
      return {
        redirectUrl: `${window.location.origin}/?hostname=`,
        message: 'If you want to return to Bbot home:',
        showPreviewBanner,
      };
    }
    return { showPreviewBanner };
  }
};

// Twilio replaces cbd in UUIDs with '****'
export const uncensorTwilio = (urlParam: string | null) => (urlParam ?? '').replace(/\*+/g, 'cbd');
