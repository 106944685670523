/* eslint-disable @typescript-eslint/naming-convention */
import { makeAutoObservable, runInAction } from 'mobx';

// Components
import { notification } from 'bbot-component-library';

// Constants
import { BbotError, BbotLoggedError } from 'constants/Errors';

// Models
import Tab from 'models/Tab';
import RootStore from 'stores/RootStore';
import TransportLayer from 'api/TransportLayer';
import { TODO } from 'utils/Types';
import { TipChoice, TipType } from 'models/Cart';

// Models
import Charge, { ChargeDistribution } from 'models/Charge';

// Tracking
import {
  trackManualCloseTabFailure,
  trackManualCloseTabSuccess,
} from 'integrations/segment/tracking-events/ManageTabTracking';

// Types
import { ConsumerTabSummaryData, TabData, TabStatus, POSInitiatedTab } from 'api/types';
import { Card, TipKey } from 'models/Types';

// Constants
import { CHARGE_TYPE } from 'constants/Checkout';

// Utility
import { getTipOptionKey } from 'components/checkout-modules/CheckoutTipSelect';
import { setCookie } from 'utils/Cookie';
import { OrderStatusContext } from 'stores/UIState';
import { retrieveFromLocalStorage, saveToLocalStorage } from 'utils/LocalStorage';
import { sleep } from 'utils/Utility';
import { TabErrorId } from '../constants/TabErrors';
import { TabClosePollingStatus, TabOpenPollingStatus } from '../constants/UIState';

export type ActiveConsumerTabSummary = Omit<ConsumerTabSummaryData, 'tab'> & { tab: Tab };

export default class TabStore {
  api: TransportLayer;
  rootStore: RootStore;

  partyTabs = [];

  // this only gets set after a party code has been validated/verified.
  activePartyTab?: Tab;

  activeConsumerTab?: Tab;

  // this represents changes being made to the tab as the user selects tip options
  activeConsumerTabTipSettings: {
    new_tip_cents?: number;
    new_tip_percentage?: number;
    tip_type: TipType;
    tip_choice: TipKey;
    user_has_chosen_tip: boolean;
  } = {
    new_tip_cents: undefined,
    new_tip_percentage: undefined,
    tip_type: TipType.Percentage,
    tip_choice: getTipOptionKey(TipChoice.PresetOption),
    user_has_chosen_tip: false,
  };

  addCheckoutToConsumerTab = false;

  // this represents the state of the tab from the backend after starting and adding orders to it
  activeConsumerTabSummary: ActiveConsumerTabSummary | null = null;

  unclaimedPosOnlyTabs: POSInitiatedTab[] = [];
  showUnclaimedPosOnlyTabs = true;

  claimedPosOnlyTabTip: {
    tip_cents?: number;
    tip_percentage?: number;
    tip_type: TipType;
    tip_choice: TipKey;
    user_has_chosen_tip: boolean;
  } = {
    tip_cents: undefined,
    tip_percentage: undefined,
    tip_type: TipType.Percentage,
    tip_choice: getTipOptionKey(TipChoice.PresetOption),
    user_has_chosen_tip: false,
  };

  claimingTab = false;
  closingTab = false;
  ignoreTabs: boolean = false;

  constructor(rootStore: RootStore) {
    this.rootStore = rootStore;
    this.api = rootStore.api;

    makeAutoObservable(this, {
      rootStore: false,
      api: false,
    });
  }

  get allowOrderAgain() {
    const { activeConsumerTab, activeConsumerTabSummary } = this;
    const tabToClose = activeConsumerTabSummary?.tab;

    return (tabToClose?.isOpenForOrders ?? false) || (activeConsumerTab?.isOpenForOrders ?? false);
  }

  allowTipAtEndOfTab(startingTab: boolean = false) {
    // When we are starting the tab, the tab summary will be set but not yet populated with server side values
    // so we need to keep using the location level config.
    if (startingTab) {
      return this.rootStore.locationStore.allow_tip_at_end_of_tab;
    }

    // For tabs that are not yet created, tipping enabled is based on location config.
    // Once tabs are created, we want to keep the experience consistent with the tab even if the location config
    // changes.
    return (
      this.activeConsumerTabSummary?.allow_tip_at_end_of_tab ?? this.rootStore.locationStore.allow_tip_at_end_of_tab
    );
  }

  // TODO: Logic for showing notification should be moved to a react component view and not be on a store.
  //  Stores only have logic for UI state and should not be used to render items
  getPartyCode = async () => {
    const url = new URL(window.location);
    const partyCode = url.searchParams.get('partyCode');

    // If party code is included in the url, validate and join the party tab
    if (partyCode) {
      try {
        await this.getTabAsConsumer();
        if (this.activeConsumerTab) {
          // Disallow joining party tabs when an open consumer tab exists.
          // Allowing it breaks assumptions around consumer tab behaviour.
          // TODO: remove this when party tabs is replaced by shareable consumer tabs.
          throw new Error(
            'We found an open tab linked to this session. Please close your existing tab before joining a party tab.'
          );
        }
        if (this.rootStore.userStore.isDDMerchant) {
          // Disallow joining party tabs when the merchant uses dovetail.
          // There should be no way to create a tab, but this blocks the rare case where somehow someone tries to use
          // one with a dovetail enabled merchant.
          throw new Error(
            'Party tabs can not be used when ordering with this merchant. Please use group ordering instead, which is available when viewing the menu.'
          );
        }
        // try to join the party using party code
        const partyTab = await this.api.checkPartyCode(partyCode);
        notification.success({
          message: 'Successfully joined the party tab. Keep the party going!',
        });

        this.setActivePartyTab(partyTab);
      } catch (error: any) {
        notification.error({ message: error.message });
      }
    }
  };

  getPartyTabs = async () => {
    try {
      const partyTabs = await this.api.getPartyTabs();
      runInAction(() => {
        this.partyTabs = partyTabs;
      });
    } catch (error) {
      console.error(error);
    }
  };

  setIgnoreTabs = (ignore: boolean) => {
    runInAction(() => {
      this.ignoreTabs = ignore;
    });
  };

  setActivePartyTab = (partyTab: Tab | null) => {
    if (!partyTab) {
      this.activePartyTab = undefined;
      return;
    }
    const tab = partyTab instanceof Tab ? partyTab : new Tab(partyTab);

    runInAction(() => {
      this.activePartyTab = tab;
    });
  };

  setActiveConsumerTab = async (consumerTab: Tab | TabData | null) => {
    if (!consumerTab) {
      return;
    }
    const tab = consumerTab instanceof Tab ? consumerTab : new Tab(consumerTab);

    runInAction(() => {
      this.activeConsumerTab = tab;
    });
  };

  setActiveConsumerDataWithTabId = async (tabId: string | null) => {
    try {
      if (tabId && !this.rootStore.tabStore.activeConsumerTab) {
        await this.api.setActiveConsumerSessionWithTabId(tabId);
        await this.rootStore.userStore.loadUserData();
        await this.rootStore.userStore.loadUserCards();
        saveToLocalStorage(`setTabId:${tabId}`, true);
      }
    } catch (error) {
      console.error(error);
    }
  };

  callCreateConsumerTabApi = async (defaultCardId: string, amount: number) => {
    // We need to create a tab, so hit api.createConsumerTab
    const nameField = this.rootStore.checkoutStore.extraCheckoutInfo.find((field) => field.key === 'name');
    const phoneNumber = this.rootStore.checkoutStore.extraCheckoutInfo.find((field) => field.key === 'phone_number');
    const data = await this.api.createConsumerTab({
      defaultCardId,
      amount,
      locationCustomerId: this.rootStore.locationStore.customer.customer_id,
      locationId: this.rootStore.locationStore.id,
      tabName: nameField ? nameField.value : '',
      defaultTip: this.getDefaultTipPercentage(),
      phoneNumber: phoneNumber ? phoneNumber.value : '',
    });
    const { tab } = data;
    return tab as TabData;
  };

  createConsumerTab = async (defaultCardId: string, amount: number) => {
    try {
      // Allow fetching the active consumer tab instead of creating from scratch if it's already set.
      // It's possible we already have an active tab, but we still need to poll for the tab status if this is
      // a DD merchant.
      const tab = this.activeConsumerTab ?? (await this.callCreateConsumerTabApi(defaultCardId, amount));

      // If this is a classic merchant, we can assume the tab is successfully created.
      // If not, we should poll if the tab status is pending checkout
      // TODO: this isDDMerchant check is redundant as this tab status is only returned if it's true.
      //  Leave it in for now just to be safe until dovetail is released.
      if (
        this.rootStore.userStore.isDDMerchant &&
        [TabStatus.Unauthorized, TabStatus.PendingCheckout].includes(tab?.status)
      ) {
        // This will either resolve or throw an error to stop execution.
        await this.openTabStatus(tab?.id);
      }

      this.setActiveConsumerTab(tab);
    } catch (error) {
      console.error(error);
      throw error;
    }
  };

  openTabStatus = async (tabId: string, delayUntilRetry = 1000) => {
    let tries = 0;
    let message: string = TabOpenPollingStatus.TabOpenInProgress;

    while (message === TabOpenPollingStatus.TabOpenInProgress && tries < 120) {
      /* eslint-disable no-await-in-loop */
      await sleep(delayUntilRetry);
      const responseData = await this.rootStore.api.openTabStatus(tabId);
      message = responseData.message;
      tries += 1;
    }

    if (message === TabOpenPollingStatus.TabOpenInProgress) {
      throw new Error(`Ran out of retries (tries: ${tries}) while polling for tab status. Cleanup may be required.`);
    }
  };

  closeTabStatus = async (delayUntilRetry = 1000) => {
    // 250 ms as a slight debounce
    const tabId = this.rootStore.tabStore.activeConsumerTab?.id ?? '';
    let tries = 0;
    let message: string = TabClosePollingStatus.TabCloseInProgress;

    while (message === TabClosePollingStatus.TabCloseInProgress && tries < 120) {
      /* eslint-disable no-await-in-loop */
      await sleep(delayUntilRetry);
      const responseData = await this.rootStore.api.closeTabStatus(tabId);
      message = responseData.message;
      tries += 1;
    }

    if (message === TabClosePollingStatus.TabCloseInProgress) {
      throw new Error(`Ran out of retries (tries: ${tries}) while polling for tab status. Cleanup may be required.`);
    }
  };

  claimPOSInitiatedTab = async (posTabId: string) => {
    const { selectedPaymentMethod = '' } = this.rootStore.checkoutStore;
    const { primaryCard, user_info: userInfo } = this.rootStore.userStore;

    // first try using the card the user selected in the UI
    const matchingCard = userInfo.cards?.find((card) => card.id === selectedPaymentMethod);
    const cardToUse = matchingCard ?? primaryCard;

    const cardIsSaved = Boolean(cardToUse);

    try {
      runInAction(() => {
        this.claimingTab = true;
      });

      // if there's no card selected, try to save whatever card data
      // might be in the stripe form. This will surface an error
      // to the user if it fails
      if (!cardIsSaved) {
        await this.rootStore.userStore.implicitlySaveCard();
      }

      // if there was already a matching card, use that
      // otherwise we'll use the result of implicitly saving the card info in the form.
      const card = cardToUse ?? this.rootStore.userStore.primaryCard;

      if (!card) {
        // We shouldn't ever hit this path since we require a card to open a tab
        // and don't let them remove all cards.
        // But who knows.
        // This will be shown to the user
        throw new BbotError(
          'In order to close your tab, we need a primary payment method. Please add one and try again.'
        );
      }

      const nameField = this.rootStore.checkoutStore.extraCheckoutInfo.find((field) => field.key === 'name');
      const phoneNumber = this.rootStore.checkoutStore.extraCheckoutInfo.find((field) => field.key === 'phone_number');

      await this.api.claimPOSInitiatedTab({
        posTabId,
        locationId: this.rootStore.locationStore.id,
        tabName: nameField ? nameField.value : '',
        defaultCardId: card.id,
        phoneNumber: phoneNumber ? phoneNumber.value : '',
      });
      // claimPOSInitiatedTab will attach an owned_tab to the user's session
      // which gets picked up when we load user data
      await this.rootStore.userStore.loadUserData();
    } catch (error) {
      console.error(error);
      throw error;
    } finally {
      // Always re-fetch unclaimed POS tabs in case the tab in question is no longer in the cache.
      await this.getUnclaimedPosOnlyTabsForLocation(this.rootStore.locationStore.id);
      runInAction(() => {
        this.claimingTab = false;
      });
    }
  };

  getDefaultTipPercentage = (): number => {
    if (this.allowTipAtEndOfTab(true)) {
      const tipping = this.rootStore.locationStore.customer?.app_properties?.tipping;
      const { default_tip: defaultTip = 0, has_default_tip: hasDefaultTip = true } = tipping ?? {};
      const defaultTipPercentage = hasDefaultTip ? defaultTip : 0;
      return defaultTipPercentage;
    }

    return this.rootStore.checkoutStore.selectedCart?.tip_percentage / 100;
  };

  getTabAsConsumer = async () => {
    try {
      const tab = (await this.api.getTabAsConsumer()) as TabData | null;
      this.setActiveConsumerTab(tab);
    } catch (error) {
      console.error(error);
      throw error;
    }
  };

  async getTabSummary(tabId: string): Promise<void> {
    try {
      const summary = (await this.api.getConsumerTabSummary(tabId)) as ConsumerTabSummaryData;

      runInAction(() => {
        this.activeConsumerTabSummary = {
          ...summary,
          tab: new Tab(summary.tab),
        };
      });

      if (summary.tab.status === TabStatus.Settled) {
        this.setTabClosed(tabId);
        return;
      }

      // Set the default tip if we are tipping at the end.
      if (this.allowTipAtEndOfTab() && !this.activeConsumerTabTipSettings.user_has_chosen_tip) {
        this.setTipAmount(
          (this.activeConsumerTabSummary?.tab?.default_tip ?? 0) *
            (this.activeConsumerTabSummary?.total_pretax_cents ?? 0)
        );
      }
    } catch (error) {
      console.error(error);
      throw error;
    }
  }

  updateTipForTab = async () => {
    try {
      const { new_tip_cents = 0 } = this.activeConsumerTabTipSettings;
      const { total_tip_cents = 0, tab, order_ids = 0 } = this.activeConsumerTabSummary ?? {};
      const tipDifferenceCents = new_tip_cents - total_tip_cents;

      if (tipDifferenceCents !== 0) {
        await this.api.updateConsumerTabTip({ tabId: tab?.id, newTipCents: new_tip_cents, orderIds: order_ids });
      }
    } catch (error) {
      if (!(error instanceof BbotLoggedError)) {
        console.error(error);
      }
      throw error;
    }
  };

  getUnclaimedPosOnlyTabsForLocation = async (locationId: string) => {
    // This will be called every time new data loads in the location store
    // POS tabs available to be claimed are location specific.
    try {
      const unclaimedPosTabs = (await this.rootStore.api.getUnclaimedPosOnlyTabsForLocation(
        locationId
      )) as POSInitiatedTab[];
      runInAction(() => {
        this.unclaimedPosOnlyTabs = unclaimedPosTabs;
      });

      this.setShowUnclaimedPosOnlyTabs(retrieveFromLocalStorage('showUnclaimedPosOnlyTabs', true));
    } catch (error) {
      console.error(error);
      throw error;
    }
  };

  setShowUnclaimedPosOnlyTabs = (isVisible: boolean) => {
    runInAction(() => {
      saveToLocalStorage('showUnclaimedPosOnlyTabs', isVisible);
      this.showUnclaimedPosOnlyTabs = isVisible;
    });
  };

  setUserHasChosenTip(val: boolean) {
    runInAction(() => {
      this.activeConsumerTabTipSettings.user_has_chosen_tip = val;
    });
  }

  setTipType(type: TipType) {
    runInAction(() => {
      this.activeConsumerTabTipSettings.tip_type = type;
    });
  }

  setTipChoice(choice: TipKey) {
    runInAction(() => {
      this.activeConsumerTabTipSettings.tip_choice = choice;
    });
  }

  setTipAmount(tipAmount: number) {
    // Round to the nearest cent (can sometime get decimals with weird tab totals)
    const rounded = Math.round(tipAmount);

    runInAction(() => {
      this.activeConsumerTabTipSettings.new_tip_cents = rounded;
      this.activeConsumerTabTipSettings.new_tip_percentage = this.calculateTipPercentage(rounded);
    });
  }

  setPOSTabTipSelection({
    type,
    choice,
    amount,
    percentage,
  }: {
    type: TipType;
    choice: TipKey;
    amount: number;
    percentage: number;
  }) {
    const rounded = Math.round(amount);
    runInAction(() => {
      this.claimedPosOnlyTabTip = {
        tip_cents: rounded,
        tip_percentage: percentage,
        tip_type: type,
        tip_choice: choice,
        user_has_chosen_tip: true,
      };
    });
  }

  calculateTipCents = (tipPercentageDecimal: number) => {
    const tipCents = Math.round(tipPercentageDecimal * (this.activeConsumerTabSummary?.total_pretax_cents ?? 0));

    // When we have a bunch of orders on a tab, it's possible that 20% tips applied to each order will total differently
    // than 20% applied to the total (or any percentage, 20% being an example).  If that is the case, use the original
    // amount so that we avoid unnecessary charge expansions for a few cents difference in tips.
    if (Math.abs(tipCents - (this.activeConsumerTabSummary?.total_tip_cents ?? 0)) < 10) {
      return this.activeConsumerTabSummary?.total_tip_cents ?? 0;
    }

    return tipCents;
  };

  /**
   * Returns a decimal representation of the tip percentage between 0 and 1.
   * @param tipAmount number
   * @returns decimal 0.0 to 1.0
   */
  calculateTipPercentage = (tipAmount: number): number =>
    Math.round((tipAmount / this.amountToApplyTipTo) * 1000) / 1000;

  get amountToApplyTipTo() {
    return this.activeConsumerTabSummary?.total_pretax_cents ?? 0;
  }

  get selectedTipPercentage() {
    const tipping = this.rootStore.locationStore.customer?.app_properties?.tipping;
    const { default_tip: defaultTip = 0, has_default_tip: hasDefaultTip = true } = tipping ?? {};
    const { user_has_chosen_tip: userHasChosenTip, new_tip_percentage: newTipPercentage } =
      this.activeConsumerTabTipSettings;

    const userHasSelectedTip = userHasChosenTip && newTipPercentage !== undefined;

    const defaultTipPercentage = hasDefaultTip ? defaultTip : 0;
    const selectedTipPercentage = userHasSelectedTip ? newTipPercentage : defaultTipPercentage;

    return selectedTipPercentage;
  }

  get selectedPOSTabTipPercentage() {
    const tipping = this.rootStore.locationStore.customer?.app_properties?.tipping;
    const { default_tip: defaultTip = 0, has_default_tip: hasDefaultTip = true } = tipping ?? {};
    const { user_has_chosen_tip: userHasChosenTip, tip_percentage: tipPercentage } = this.claimedPosOnlyTabTip;

    const userHasSelectedTip = userHasChosenTip && tipPercentage !== undefined;

    const defaultTipPercentage = hasDefaultTip ? defaultTip : 0;
    const selectedTipPercentage = userHasSelectedTip ? tipPercentage : defaultTipPercentage;

    return selectedTipPercentage;
  }

  getSelectedPOSTabTipAmount(amountToApplyTipTo: number) {
    const tipPercentage = this.selectedPOSTabTipPercentage;
    return Math.round(tipPercentage * amountToApplyTipTo * 1000) / 1000;
  }

  /**
   * this represents the actual radio selection the user clicked on
   * we basically just need a way to make them unique because they
   * can have identical values
   */
  get selectedTipOption() {
    const {
      user_has_chosen_tip: userHasChosenTip,
      new_tip_percentage: newTipPercentage,
      tip_choice: selectedTipChoice,
    } = this.activeConsumerTabTipSettings;

    const userHasSelectedTip = userHasChosenTip && newTipPercentage !== undefined;
    const selectedTipOption = userHasSelectedTip ? selectedTipChoice : this.defaultTipChoice;

    return selectedTipOption;
  }

  get selectedPOSTabTipOption() {
    const { user_has_chosen_tip: userHasChosenTip, tip_percentage: tipPercentage } = this.claimedPosOnlyTabTip;

    const userHasSelectedTip = userHasChosenTip && tipPercentage !== undefined;
    const selectedTipOption = userHasSelectedTip ? this.claimedPosOnlyTabTip.tip_choice : this.defaultTipChoice;

    return selectedTipOption;
  }

  get defaultTipChoice() {
    const tipping = this.rootStore.locationStore.customer?.app_properties?.tipping;
    const { choices, has_default_tip: hasDefaultTip = true, default_tip: defaultTip = 0 } = tipping ?? {};
    return hasDefaultTip
      ? getTipOptionKey(TipChoice.PresetOption, choices?.indexOf(defaultTip) ?? 0)
      : getTipOptionKey(TipChoice.None);
  }

  get subtotalBeforeFeesAndPromos(): number {
    return (this.activeConsumerTabSummary?.total_pretax_cents ?? 0) - this.totalTabFees - this.totalTabPromos;
  }

  get totalTabFees(): number {
    const tabFees: Array<number> = [];
    if (this.activeConsumerTabSummary?.order_fees_summary) {
      Object.values(this.activeConsumerTabSummary?.order_fees_summary).forEach((feeSummary: TODO) =>
        tabFees.push(feeSummary.pretax_cents)
      );
    }
    return tabFees.reduce((fees, fee) => fees + fee, 0);
  }

  get totalTabPromos(): number {
    const tabPromos: Array<number> = [];
    if (this.activeConsumerTabSummary?.promotions_summary) {
      Object.values(this.activeConsumerTabSummary?.promotions_summary).forEach((promoSummary: TODO) =>
        tabPromos.push(promoSummary.pretax_cents_added)
      );
    }
    return tabPromos.reduce((promos, promo) => promos + promo, 0);
  }

  activeTabSummaryTotal = () =>
    (this.activeConsumerTabTipSettings?.new_tip_cents ?? 0) +
    (this.activeConsumerTabSummary?.total_pretax_cents ?? 0) +
    (this.activeConsumerTabSummary?.total_tax_cents ?? 0);

  resetTipAdjustment = () => {
    this.activeConsumerTabTipSettings.new_tip_cents = this.activeConsumerTabSummary?.total_tip_cents;
    this.activeConsumerTabTipSettings.new_tip_percentage = this.calculateTipPercentage(
      this.activeConsumerTabSummary?.total_tip_cents ?? 0
    );
  };

  getShareURL = async (locationCode: string) => {
    const locationId = locationCode ? this.rootStore.locationStore.id : null;
    // eslint-disable-next-line no-return-await
    return await this.api.getTabShareURL(locationId);
  };

  setTabClosed = (closeTabId: string) => {
    setCookie(`closedTab:${closeTabId}`, closeTabId);
    this.rootStore.uiState.setOrderStatusContext(OrderStatusContext.TabClosed);

    // Clear data if successful
    runInAction(() => {
      this.activeConsumerTab = undefined;
      this.activeConsumerTabSummary = null;
      this.activeConsumerTabTipSettings = {
        new_tip_cents: 0,
        new_tip_percentage: 0,
        tip_type: TipType.Percentage,
        tip_choice: this.defaultTipChoice,
        user_has_chosen_tip: false,
      };
    });
  };

  closeTab = async (closeTabId: string) => {
    const { selectedPaymentMethod = '' } = this.rootStore.checkoutStore;
    const { primaryCard, user_info: userInfo } = this.rootStore.userStore;
    const { activeConsumerTabSummary, activeConsumerTabTipSettings } = this;
    const { new_tip_cents: newTipCents = 0 } = activeConsumerTabTipSettings;
    const { version_hash: versionHash = '' } = activeConsumerTabSummary?.tab ?? {};

    // first try using the card the user selected in the UI
    const matchingCard = userInfo.cards?.find((card) => card.id === selectedPaymentMethod);
    const cardToUse = matchingCard ?? primaryCard;

    const cardIsSaved = Boolean(cardToUse);

    try {
      runInAction(() => {
        this.closingTab = true;
      });

      // if there's no card selected, try to save whatever card data
      // might be in the stripe form. This will surface an error
      // to the user if it fails
      if (!cardIsSaved) {
        await this.rootStore.userStore.implicitlySaveCard();
      }

      // if there was already a matching card, use that
      // otherwise we'll use the result of implicitly saving the card info in the form.
      const card = cardToUse ?? this.rootStore.userStore.primaryCard;

      if (!card) {
        // We shouldn't ever hit this path since we require a card to open a tab
        // and don't let them remove all cards.
        // But who knows.
        // This will be shown to the user
        throw new BbotError(
          'In order to close your tab, we need a primary payment method. Please add one and try again.'
        );
      }

      const chargeDistributions = this.getActiveConsumerTabChargeDistributionsWithCard(card);

      await this.api.closeTabAsConsumer({
        versionHash,
        chargeDistributions,
        tabId: closeTabId,
        newTipCents: this.allowTipAtEndOfTab() ? newTipCents : null,
      });

      // If this is a classic merchant, we can assume the tab is successfully closed. If not, we should poll first.
      if (this.rootStore.userStore.isDDMerchant) {
        // This will either resolve or throw an error to stop execution.
        await this.closeTabStatus();
      }

      trackManualCloseTabSuccess({ closeTabId });

      this.setTabClosed(closeTabId);

      // Parse through orders again to update the UI on the order status page
      const { orders } = this.rootStore.ordersStore;
      const parsedOrders = await this.rootStore.ordersStore.parseOrders(orders);
      this.rootStore.ordersStore.setParsedOrders(parsedOrders);
    } catch (error: any) {
      const tabId = this.activeConsumerTab?.id;
      trackManualCloseTabFailure({ tabId });
      if ('error_id' in error && [TabErrorId.tab_closed, TabErrorId.tab_closed_in_pos].includes(error.error_id)) {
        const redirectLink = this.rootStore.locationStore.menuPageHref;
        // You have to reload the page because we cleared your tab from your session in the backend.
        notification.error({
          message: error.message,
          onClose: () => (window.location.href = redirectLink),
        });
      } else {
        console.error(error);
      }
      throw error;
    } finally {
      runInAction(() => {
        this.closingTab = false;
      });
    }
  };

  closePOSInitiatedTab = async (posInitiatedTab: POSInitiatedTab) => {
    const { addCardFormOpen, selectedPaymentMethod = '' } = this.rootStore.checkoutStore;
    const { primaryCard, user_info: userInfo } = this.rootStore.userStore;
    const {
      amount_cents: amountCents = 0,
      tax_amount_cents: taxAmountCents = 0,
      tax_inclusive_pricing: taxInclusivePricing = 0,
    } = posInitiatedTab;

    const tipAmountCents = this.getSelectedPOSTabTipAmount(posInitiatedTab.amount_cents);
    const totalCents = amountCents + tipAmountCents + taxAmountCents * (taxInclusivePricing ? 0 : 1);

    // first try using the card the user selected in the UI
    const matchingCard = userInfo.cards?.find((card) => card.id === selectedPaymentMethod);
    const cardToUse = matchingCard ?? primaryCard;

    const cardIsSaved = Boolean(cardToUse);

    try {
      runInAction(() => {
        this.closingTab = true;
      });

      // if there's no card selected, try to save whatever card data
      // might be in the stripe form. This will surface an error
      // to the user if it fails
      if (addCardFormOpen && !cardIsSaved) {
        await this.rootStore.userStore.implicitlySaveCard();
      }

      // if there was already a matching card, use that
      // otherwise we'll use the result of implicitly saving the card info in the form.
      const card = cardToUse ?? this.rootStore.userStore.primaryCard;

      let chargeDistributions: ChargeDistribution[] = [];

      if (!card) {
        // We normally require a card to open a tab and don't let them remove all cards.
        // But we now support closing a tab with only a gift card provided if it covers the total in full.
        chargeDistributions = this.getTabChargeDistributions(totalCents);

        const giftCardCoversTotalCents =
          chargeDistributions.length === 1 && chargeDistributions[0].chargeType === CHARGE_TYPE.GIFT_CARD;

        if (!giftCardCoversTotalCents) {
          // The gift card doesn't cover the charge in full, or neither a card nor a gift card has been entered.
          // Show an error to the user.
          throw new BbotError(
            'In order to close your tab in full, we need a primary payment method. Please add one and try again.'
          );
        }
      } else {
        chargeDistributions = this.getChargeDistributionsForCardAndAmount(card, totalCents);
      }

      await this.api.closePOSInitiatedTab({
        chargeDistributions,
        guid: posInitiatedTab.guid,
        locationId: this.rootStore.locationStore.id,
        defaultCardId: card ? card.id : '',
        tabName: posInitiatedTab.tab_name,
        newTipCents: tipAmountCents,
      });

      // TODO: change this to a unique event for pos initiated close tab success
      // trackManualCloseTabSuccess({ closeTabId: posInitiatedTab.guid });
    } catch (error) {
      // trackManualCloseTabFailure({ tabId });
      console.error(error);
      throw error;
    } finally {
      runInAction(() => {
        this.closingTab = false;
      });
    }
  };

  /**
   *
   * Split up the total on the active tab amongst any selected gift cards
   * and the user's primary card.
   *
   */
  get activeConsumerTabChargeDistributions() {
    const amount = this.activeConsumerTabAmountCents;

    return this.getTabChargeDistributions(amount);
  }

  get activeConsumerTabAmountCents() {
    const { activeConsumerTabSummary, activeConsumerTabTipSettings } = this;
    const {
      total_pretax_cents: totalPretaxCents = 0,
      total_tax_cents: totalTaxCents = 0,
      total_tip_cents: totalTipCents = 0,
      paid_amount_cents: paidAmountCents = 0,
    } = activeConsumerTabSummary ?? {};
    const { new_tip_cents: newTipCents = 0 } = activeConsumerTabTipSettings;

    const finalTipCents = this.allowTipAtEndOfTab() ? newTipCents : totalTipCents;

    const amount = totalPretaxCents + totalTaxCents + finalTipCents - paidAmountCents;

    return amount;
  }

  getTabChargeDistributions(amount: number) {
    // For some reason unpacking all these nested calls to store getters makes this work consistently.
    // Still unsure as to why this works this way.
    const { locationStore, userStore } = this.rootStore;

    const allGiftCards = userStore.user_info?.gift_cards ?? [];
    const selectedUserGiftCards = allGiftCards.filter((giftCard) => giftCard.selected);
    const selectedGiftCardsIfAllowed = locationStore?.customer?.has_gift_card_integration ? selectedUserGiftCards : [];

    const chargeDistributionArray = Charge.buildDistributionArray(
      amount,
      CHARGE_TYPE.SAVED_STRIPE,
      selectedGiftCardsIfAllowed,
      this.rootStore
    );

    return chargeDistributionArray;
  }

  getActiveConsumerTabChargeDistributionsWithCard = (card: Card) =>
    this.getChargeDistributionsForCardAndAmount(card, this.activeConsumerTabAmountCents);

  getChargeDistributionsForCardAndAmount = (card: Card, amount: number) => {
    const chargeDistributions = this.getTabChargeDistributions(amount);
    return chargeDistributions.map((distribution) => {
      switch (distribution.chargeType) {
        case CHARGE_TYPE.SAVED_STRIPE:
          return { ...distribution, cardId: card.id };
        case CHARGE_TYPE.TAB:
        case CHARGE_TYPE.FREE:
          return { ...distribution, chargeType: CHARGE_TYPE.SAVED_STRIPE, cardId: card.id };
        default:
          return distribution;
      }
    });
  };
}
