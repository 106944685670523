import styled from 'styled-components';

// Components
import { Typography } from 'bbot-component-library';
import CloseButton from 'components/buttons/CloseButton';
import BackButton from 'components/buttons/BackButton';

// Constants
import breakpoints from 'constants/breakpoints';
import { button } from 'constants/style';

interface visibleProps {
  visible: boolean;
}

export const ChildrenWrapper = styled.div`
  padding: 0 1.5rem;
  position: relative;

  @media (min-width: ${breakpoints.md}px) {
    max-height: 67vh;
  }
`;

export const StyledCloseButton = styled(CloseButton)`
  position: absolute;
  right: 1.25rem;
`;

export const StyledBackButton = styled(BackButton)`
  position: absolute;
  right: 1.25rem;
`;

export const CloseOnTouch = styled.div`
  background-color: transparent;
  cursor: pointer;
  flex-grow: 1;
  min-height: ${button.large}px;
`;

export const Container = styled.div<visibleProps>`
  flex: 1 0 auto;
  bottom: 0;
  display: flex;
  flex-direction: column;
  height: 100%;
  left: 0;
  overflow-x: hidden;
  overflow-y: auto;
  position: fixed;
  transform: translateY(${({ visible }) => (visible ? 0 : '100vh')});
  transition: transform 0.3s ease-in-out;
  width: 100%;
  z-index: 102;
`;

export const Header = styled.div`
  flex: 0 0 auto;
  border-top-left-radius: var(--modal__border-radius);
  border-top-right-radius: var(--modal__border-radius);
  padding: 1.5rem;
  position: relative;
  background-color: var(--color-neutral__card-background);
`;

export const Modal = styled.div`
  background-color: var(--color-neutral__card-background);
  border-top-left-radius: var(--modal__border-radius);
  border-top-right-radius: var(--modal__border-radius);
  box-shadow: 0px -5px 12px rgba(0, 0, 0, 0.25);
  display: flex;
  flex-direction: column;
  height: fit-content;
  min-height: fit-content; // Required for iPhone 7 (iOS 10) Safari.

  @media (min-width: ${breakpoints.md}px) {
    border-radius: var(--border-radius__small);
    display: block;
    left: 50%;
    overflow-y: auto;
    position: absolute;
    top: 50%;
    transform: translate(-50%, -50%);
    width: 500px;
  }
`;

export const Title = styled(Typography.Title)`
  && {
    font-size: 1.25rem;
    font-weight: bold;
    line-height: 1.2;
    margin-bottom: 0;
    padding: 0 3rem;
    text-align: center;
  }
`;

// Used to wrap modal titles that are using components that don't accept ref attrs
export const TitleWrapper = styled.h1`
  outline: none;
  margin: 0;
`;

export const Underlay = styled.div<visibleProps>`
  background: rgba(0, 0, 0, 0.5);
  bottom: 0;
  height: 100%;
  left: 0;
  opacity: ${({ visible }) => (visible ? 1 : 0)};
  pointer-events: none;
  position: fixed;
  transition: opacity 0.3s ease-in-out;
  width: 100%;
  z-index: 101;

  // Blurred backdrop for supported browsers.
  // See: https://blog.logrocket.com/new-in-chrome-76/
  @supports ((-webkit-backdrop-filter: none) or (backdrop-filter: none)) {
    -webkit-backdrop-filter: blur(0.125rem);
    backdrop-filter: blur(0.125rem);
  }
`;
