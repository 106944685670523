/* eslint-disable max-classes-per-file */
import { getHostName } from 'utils/Host';
import { captureErrorInSentry } from 'integrations/Sentry';
import { retrieveFromLocalStorage } from 'utils/LocalStorage';
import { DEVICE_ID } from 'constants/Environments';

import * as TabErrors from './TabErrors';
import * as SharedCartErrors from './SharedCartErrors';

export interface BbotErrorOptions {
  customer_id?: string;
  hostname?: string;
  endpoint?: string;
  cause?: Error;
  redirect?: string;
  originalError?: string;
  status?: number;
  error_id?: AllErrorId;
}

type AllErrorId = SharedCartErrors.SharedCartErrorId | TabErrors.TabErrorId;

// ----------------------------------------------------------------------
//
// Bbot Error - Grab useful information for an api error
//
// ----------------------------------------------------------------------
export class BbotError extends Error {
  protected customer_id?: string;
  protected hostname: Promise<string | undefined>;
  protected host?: string;
  protected endpoint?: string;
  protected redirect?: string;
  protected status?: number;
  protected originalError?: Error;
  protected error_id?: AllErrorId;
  protected device_id?: string;

  constructor(message: string, options?: BbotErrorOptions) {
    super(message, options);
    this.message = message;
    this.customer_id = options?.customer_id;
    this.hostname = getHostName();
    this.host = options?.hostname;
    this.endpoint = options?.endpoint;
    this.cause = options?.cause;
    this.redirect = options?.redirect;
    this.originalError = options?.cause;
    this.status = options?.status;
    this.error_id = options?.error_id;
    this.device_id = retrieveFromLocalStorage(DEVICE_ID.LABEL);
  }
}

// ----------------------------------------------------------------------
//
// Bbot Logged Error - Used for reporting to ChurnZero
//
// ----------------------------------------------------------------------
// Reference: https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Error/Error
export class BbotLoggedError extends BbotError {
  constructor(message: string, options?: BbotErrorOptions) {
    super(message, options);

    // Caught errors are not automatically logged in the console when thrown
    console.error(this);

    captureErrorInSentry(this);
  }
}

// ----------------------------------------------------------------------
//
// Endpoint Errors
//
// ----------------------------------------------------------------------

interface EndpointErrorOptions {
  hostname?: string;
  endpoint?: string;
  cause?: Error;
  status?: number;
}

export class EndpointError extends Error {
  hostname?: string;
  endpoint?: string;
  cause?: Error;
  status?: number;
  // types needs this because we do the dynamic key assignment in the constructor - we cant remove if we change to explicit property assignment
  [key: string]: any;

  constructor(message: string, options: EndpointErrorOptions) {
    super(message, options);
    Object.entries(options).forEach(([key, value]) => {
      this[key as keyof typeof this] = value as typeof this[keyof typeof this];
    });
  }
}

export class NetworkError extends BbotLoggedError {}

export class CSRFError extends BbotLoggedError {}

export class AbortedError extends Error {}

// ----------------------------------------------------------------------
//
// Checkout Errors
//
// ----------------------------------------------------------------------

export class CheckoutValidationError extends BbotLoggedError {}

export class CartItemChangedError extends BbotLoggedError {}

export class CheckoutError extends BbotLoggedError {}

export class ConsumerTabError extends BbotLoggedError {}

export class InventoryQuantityError extends BbotLoggedError {}

// ----------------------------------------------------------------------
//
// Loyalty Errors
//
// ----------------------------------------------------------------------

export class LoyaltyAccountCreationError extends BbotLoggedError {}

// ----------------------------------------------------------------------
//
// Stripe Errors
//
// ----------------------------------------------------------------------

export class StripeError extends BbotLoggedError {}

export class StripeCardValidationError extends BbotLoggedError {}

export class StripeCardSetupError extends BbotLoggedError {}

// ----------------------------------------------------------------------
//
// Kiosk Errors
//
// ----------------------------------------------------------------------

export class StripeReaderNotFound extends BbotLoggedError {}

export class StripeReaderNotPaired extends BbotLoggedError {}

export class StripeReaderNotConnected extends BbotLoggedError {}

export class ErrorCollectingPayment extends BbotLoggedError {}

// ----------------------------------------------------------------------
//
// Order Status Poll Errors
//
// ----------------------------------------------------------------------

export enum OrderStatusErrorType {
  None = 'none',
  SessionMissing = 'session-missing',
  Generic = 'generic',
}

export class OrderStatusPollFailure extends BbotError {
  errorType = OrderStatusErrorType.Generic;
}

export class OrderStatusPollFailureSessionMissing extends OrderStatusPollFailure {
  errorType = OrderStatusErrorType.SessionMissing;
}

export class OrderStatusPollFailureGeneric extends OrderStatusPollFailure {
  errorType = OrderStatusErrorType.Generic;
}

// ----------------------------------------------------------------------
//
// Server Errors
//
// ----------------------------------------------------------------------
export class PricecheckError extends BbotLoggedError {}

// ----------------------------------------------------------------------
//
// Shared Cart Errors
//
// ----------------------------------------------------------------------

// ----------------------------------------------------------------------
//
// Upsells Errors
//
// ----------------------------------------------------------------------
enum DrinkRefillErrorReason {
  Invalid = 'invalid',
  NotFound = 'not found',
  ServerError = 'server error',
  Unknown = 'unknown',
}

export class DrinkRefillError extends BbotError {
  static Reasons = DrinkRefillErrorReason;

  reason: DrinkRefillErrorReason = DrinkRefillErrorReason.Unknown;

  constructor(message: string, options?: BbotErrorOptions) {
    super(message, options);

    this.reason = (() => {
      switch (options?.status) {
        case 400:
          return DrinkRefillErrorReason.Invalid;
        case 404:
          return DrinkRefillErrorReason.NotFound;
        case 500:
          return DrinkRefillErrorReason.ServerError;
        default:
          return DrinkRefillErrorReason.Unknown;
      }
    })();

    if (this.reason !== DrinkRefillErrorReason.NotFound) {
      console.error(this);
      captureErrorInSentry(this);
    }
  }
}
