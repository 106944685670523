import MenuItem from 'models/MenuItem';
import { getUpsellsForItem, getUpsellsForCurrentMenu } from './DvUtils';
import { UpsellsFromMenuItemsType, GetConstsForRecommendationConditionType } from './Types';

const getUpsellsForCart = ({ menuId, cartLevelUpsells }: { menuId: string; cartLevelUpsells?: string[] }) => {
  if (cartLevelUpsells?.length) {
    return cartLevelUpsells;
  }
  return getUpsellsForCurrentMenu(menuId)?.cart_recommendations ?? [];
};

const getMenuId = (menuItems: MenuItem[], menu?: string | null) => menu ?? menuItems?.[0]?.menus?.[0]?.id;

const getConstsForRecommendationCondition = ({
  cartUpsells,
  fromCart,
  menuId,
  recommendedItems,
  showProgrammaticUpsells,
}: GetConstsForRecommendationConditionType) => ({
  showItemLevelRecommendations: !!recommendedItems?.length && !fromCart && !showProgrammaticUpsells,
  showCartLevelRecommendations: cartUpsells.length && fromCart && !showProgrammaticUpsells,
  showNoRecommendations:
    Object.keys(getUpsellsForCurrentMenu(menuId) ?? {})?.length === 4 &&
    !fromCart &&
    !recommendedItems?.length &&
    !showProgrammaticUpsells,
});

const getProgrammaticRecommendations = (
  menuItems: MenuItem[],
  fromCart: boolean,
  showProgrammaticUpsells?: boolean,
  menuItem?: MenuItem
): string[] => {
  if (!showProgrammaticUpsells) {
    return [];
  }
  let recommendations = menuItems.filter((item) => item.imageUrl !== null);
  if (fromCart) {
    recommendations = recommendations.filter((item) => item.menu_item_class === 'drink').slice(0, 4);
  } else if (menuItem) {
    recommendations = recommendations
      .filter((item) => item.name_for_customer !== menuItem.name_for_customer)
      .sort(() => Math.random() - 0.5)
      .slice(0, 4);
  }
  return recommendations.map((recommendation) => recommendation.id);
};

export const getUpsellsFromMenuItems = ({
  cartLevelUpsells,
  fulfillableItems,
  fromCart,
  showProgrammaticUpsells,
  menuItem,
  menuHeading,
  menu,
}: UpsellsFromMenuItemsType) => {
  try {
    if (!fulfillableItems?.length) {
      return [];
    }
    const menuId = getMenuId(fulfillableItems, menu);
    const { recommended_items: recommendedItems } = getUpsellsForItem({
      menuId,
      menuItem,
      menuHeading,
      menuHeadingId: menuItem?.menu_heading_id ?? '',
      menuItemId: menuItem?.id ?? '',
    });
    const cartUpsells = getUpsellsForCart({ menuId, cartLevelUpsells });
    const programaticRecommendations = getProgrammaticRecommendations(
      fulfillableItems,
      fromCart,
      showProgrammaticUpsells,
      menuItem
    );
    const { showItemLevelRecommendations, showCartLevelRecommendations, showNoRecommendations } =
      getConstsForRecommendationCondition({ cartUpsells, fromCart, menuId, recommendedItems });

    if (showItemLevelRecommendations) {
      return recommendedItems;
    } else if (showCartLevelRecommendations) {
      return cartUpsells;
    } else if (showProgrammaticUpsells) {
      return programaticRecommendations;
    } else if (showNoRecommendations) {
      return [];
    } else {
      return [];
    }
  } catch {
    return [];
  }
};

export const validateImageHosting = (url: string) => {
  const prodUrlPrefix = 'https://static.bbot.menu';
  const stagingUrlPrefix = 'https://static.staging.bbot.menu';
  const localUrlPrefix = 'https://static.dev.bbot.menu';
  return url.startsWith(prodUrlPrefix) || url.startsWith(stagingUrlPrefix) || url.startsWith(localUrlPrefix);
};

const convertBoseHeadingJsonToObject = (value: string) => {
  try {
    return Object.fromEntries(
      Object.entries(JSON.parse(value)).filter(([, headingValue]: any) => validateImageHosting(headingValue))
    );
  } catch (e) {
    return {}; // Could error out when converting value into json
  }
};

export const getBoseImagesFromCssVariables = (cssVariables: Object) =>
  Object.entries(cssVariables)
    .filter(([key]) => key.includes('--bose-image'))
    .reduce((boseImages: any, [key, value]) => {
      const formattedKey = key.replace('--', '').replace(/__|-/g, '_');
      let validatedValue = validateImageHosting(value) ? value : '';
      if (formattedKey === 'bose_image_menu_item_headings') {
        validatedValue = convertBoseHeadingJsonToObject(value);
      }
      boseImages[formattedKey] = validatedValue;
      return boseImages;
    }, {});

export const removeBoseImagesFromCssVariables = (cssVariables: Object) =>
  Object.fromEntries(Object.entries(cssVariables).filter(([key]) => !key.includes('--bose-image')));

// https://emailregex.com/ --> Javascript regex from this site
export const validateEmail = (email: string) => {
  const emailRegex =
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return emailRegex.test(email);
};

export const isPromoBOGO = (promoLabel: string) =>
  promoLabel.toLocaleUpperCase().includes('BUY ONE GET ONE FREE') || promoLabel.toLocaleUpperCase().includes('BOGO');
