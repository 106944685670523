/* eslint-disable no-param-reassign */
/* eslint-disable prefer-destructuring */
/* eslint-disable @typescript-eslint/no-shadow */
import _ from 'lodash';
import { makeAutoObservable, runInAction } from 'mobx';

// Models
import Customer from 'models/Customer';
import Menu from 'models/Menu';
import MenuItem from 'models/MenuItem';
import ModifierGroup from 'models/ModifierGroup';
import Modifier from 'models/Modifier';

import { retrieveFromLocalStorage } from 'utils/LocalStorage';

import { type MenuHeadingData, type CachedMenuData, type MenuData } from 'api/types';

import RootStore from './RootStore';
import TransportLayer from '../api/TransportLayer';
import MenuHeading from '../models/MenuHeading';
import { TODO } from '../utils/Types';
import { managedFeatures } from '../DynamicValues/DynamicValuesProvider';

export default class MenuDataStore {
  _loading: boolean = false;
  loaded: boolean = false;
  locationId: string | null = null; // Used to keep the location data and menu data in sync with each other

  activeHeading: MenuHeading | null = null;
  allow_order_ahead: boolean = false;
  customersById: Record<string, Customer> = {}; // Customers across all menus for this location
  errorPollingMenuItems: string | null = null;
  fulfillableMenuItemIds: Array<string> | null = null; // Fulfillable Menu Items across all menus for this location
  headingsById: Record<string, MenuHeadingData> = {}; // Menu Headings by Id (populated locally)
  orderSnoozedBannerMessages: Array<string> = [];
  lastCall: TODO = null;
  menuItemHash: string | null = null;
  menuItemPollId: NodeJS.Timeout | null = null; // If not null then you know the location is polling for fulfillable menu items
  isPollingMenuItems: boolean = false;
  menuItemsById: Record<string, MenuItem> = {}; // Menu Items across all menus for this location
  menuItemsByIdFiltered: Record<string, MenuItem> | null = null; // Filtered Menu Items across all menus for this location
  menus: Array<Menu> = [];
  modifierGroupsById: Record<string, ModifierGroup> = {}; // Modifier Groups across all menus for this location
  modifiersById: Record<string, Modifier> = {};
  searchText?: string;
  selectedMenu: Menu | null = null;
  showMobileSearchInput: boolean = false;
  some_order_ahead: boolean = false;
  upsells: string[] = [];

  // Private Variables to reduce duplicate calculations
  _mergedMenus?: Menu[];
  _atleatOneMenuAllowsFutureOrders?: boolean;

  // Polling Values
  visible_menus: Record<string, Menu> = {}; // Visible Menus by menuId

  rootStore: RootStore;
  api: TransportLayer;
  show_last_call?: boolean;

  constructor(rootStore: RootStore) {
    this.rootStore = rootStore;
    this.api = rootStore.api;

    makeAutoObservable(this, {
      rootStore: false,
      api: false,
      menuItemPollId: false,
    });
  }

  reset() {
    this.stopPollingFulfillableMenuItems();
    runInAction(() => {
      this._loading = false;
      this.loaded = false;
      this.locationId = null;
      this.headingsById = {};
      this.menuItemHash = null;
      this.menuItemsById = {};
      this.menuItemsByIdFiltered = null;
      this.menus = [];
      this.modifierGroupsById = {};
      this.modifiersById = {};
      this.selectedMenu = null;

      // Private variables
      this._mergedMenus = undefined;
      this._atleatOneMenuAllowsFutureOrders = undefined;
    });
  }

  get taxInclusivePricing() {
    return this.rootStore.locationStore.customer?.tax_inclusive_pricing;
  }

  get currencyCode() {
    return this.rootStore?.locationStore?.currencyCode;
  }

  // Gets the final list of menus once all menus have merged
  get mergedMenus() {
    if (this._mergedMenus) {
      return this._mergedMenus;
    }

    const finalMenus = this.menus
      .slice()
      .sort((menu1: Menu, menu2: Menu) => menu1.display_position - menu2.display_position);

    if (this.loaded) {
      runInAction(() => {
        this._mergedMenus = finalMenus;
      });
    }

    return finalMenus;
  }

  get fulfillableMenus() {
    return this.mergedMenus.filter((menu) => menu.hasFulfillableItems);
  }

  // Menus to be displayed after merging & checking fulfillable items
  get displayedMenus(): Array<Menu> {
    const locationMenus = this.rootStore.locationStore.getSortedMenus();

    // Filter out menus that combine into other menus
    return locationMenus
      .map((locationMenu) =>
        // Must be from the mergedMenus because later on we determine if
        this.mergedMenus.find((m) => m.id === locationMenu.menuId)
      )
      .filter(
        (menu: Menu | undefined): menu is Menu =>
          // Filter out menus that aren't merged menus
          !!menu
      )
      .filter((menu: Menu) => {
        // NOTE: visible_per_schedule is determined on menu-data load and visible_menus is polled every 30 seconds
        const isVisible = this.visible_menus[menu.menuId] || menu.showsUnfulfillableItems;
        const hasItems = menu.hasVisibleItems;
        return isVisible && hasItems;
      });
  }

  get isSearching(): boolean {
    return Boolean(this.searchText?.length);
  }

  setLocationId = (id: string) => {
    if (this.locationId !== id) {
      runInAction(() => {
        this.locationId = id;
        this.menus = [];
        this.loaded = false;
      });
    }
  };

  /**
   * Checks that the given menu item is visible given the list of menus it is attached to and cross referenced with the
   * list of child menus for the selected menus. The intersection of that list combined with the list of visible menus
   * tells us what menu is visible.
   */
  isOneItemMenuVisible = (menuItemId: string, selectedMenuId: string, menuItemMenus: Array<string>) => {
    const selectedMenuMergedMenuList = [selectedMenuId].concat(
      this.rootStore.locationStore.getChildMenuPks(selectedMenuId)
    );

    // Get the list of menus that intersect between the merged menus into the menu being viewed and the menus the menu
    // item belongs to
    const relevantMenuIds = selectedMenuMergedMenuList.filter((pk) => menuItemMenus.includes(pk));

    // Check if any of the menus of the intersection are visible
    const visibleMenus = relevantMenuIds.filter((id) => this.visible_menus[id]);

    return visibleMenus.length > 0;
  };

  /**
   * This function is run in parallel. We load all menus at the same time. Be advised that if you place console logs
   * in this function they may not appear to be called in sequential order.
   * @param menu
   * @param menuId
   * @param index
   * @returns {Promise<void>}
   */
  fetchMenu = async (menu: Menu, index: number, menuId: string) => {
    const pks = [menu.menuId].concat(menu.children);
    const data = (await this.api.getMenuData(pks)) as CachedMenuData;

    // Map customer objects to customer
    Object.values(data.customersById).forEach((customerObj) => {
      const newCustomer = this.customersById[customerObj.customer_id] || new Customer(customerObj);
      data.customersById[customerObj.customer_id] = newCustomer;
      if (data.customersById[customerObj.customer_id]?.upsells?.length) {
        this.upsells = data.customersById[customerObj.customer_id]?.upsells;
      }
      this.rootStore.locationStore.addCustomer(newCustomer);
    });

    runInAction(() => {
      Object.assign(this.customersById, data.customersById);
      Object.assign(
        this.menuItemsById,
        _.mapValues(
          data.menuItemsById,
          (menuItem, menuItemId) => new MenuItem(this, menuItemId, menuItem, this.taxInclusivePricing)
        )
      );
      _.mapValues(data.modifierGroupsById, (group, id) => {
        if (this.modifierGroupsById[id]) {
          this.modifierGroupsById[id].update(group);
        } else {
          this.modifierGroupsById[id] = new ModifierGroup(this, id, group);
        }
      });
    });

    const loadedMenu = this.updateMenuFromServer(data.menus[0]);

    // If there is already a selected menu then dont set a different active menu
    if (this.selectedMenu) {
      return null;
    }

    if (loadedMenu.menuId === menuId) {
      // Only set selected menu if the menu is not a menu that gets merged into other menus
      // Do not take into account the available items because we still want the ability to deep link
      // into a menu even if its unavailable.
      this.setActiveMenu(menuId);
    } else if (index === 0 && !menuId) {
      // If you are not deep linking to a menu and this menu is the fist to be loaded on the page the set this as
      // the selected menu. We want to respect the menu display order for all menus with available items.
      // NOTE: Available items means items that should be displayed
      if (loadedMenu.isVisible && loadedMenu.availableItems.length > 0) {
        this.setActiveMenu(loadedMenu.menuId);
      }
    }
    return null;
  };

  fetchMenuData = async (menuId: string) => {
    if (this.loaded || this._loading) {
      return;
    }

    runInAction(() => {
      this._loading = true;
    });

    const menuMetaData = this.rootStore.locationStore.getSortedMenus();

    // If the menuId is passed to this function ensure that the menu is available at this location
    const menuIdToFetch = menuId && !menuMetaData.find((menu) => menu.menuId === menuId) ? '' : menuId;

    const unloadedMenus = menuMetaData.filter((menu) => !this.isMenuLoaded(menu.menuId));

    try {
      await Promise.all(unloadedMenus.map(async (menu, index) => await this.fetchMenu(menu, index, menuIdToFetch)));

      if (!this.selectedMenu && this.displayedMenus?.[0]?.menuId) {
        // If all menus have loaded and there is no selected menu then default to first menu displayed
        // NOTE: this.displayedMenus is sorted by the menu display position so we are ok selecting the first element in the list
        this.setActiveMenu(this.displayedMenus?.[0]?.menuId);
      } else if (!this.selectedMenu) {
        // If all menus have loaded but no menus have items to display, default to the first menu in the merged list
        this.setActiveMenu(this.mergedMenus?.[0]?.menuId);
      }

      if (this.rootStore.checkoutStore.selectedCart?.isSharedCart) {
        await this.rootStore.checkoutStore.getCartPrice();
      }

      runInAction(() => {
        this.allow_order_ahead = _.every(this.menus, 'allow_order_ahead');
        this.some_order_ahead = _.some(this.menus, 'allow_order_ahead');
        this._loading = false;
        this.loaded = true;
      });
    } catch (err) {
      console.error(err);

      runInAction(() => {
        this._loading = false;
        this.loaded = true;
      });

      throw err;
    }
  };

  pollMenuItems = async (locationId: string) => {
    if (this.menuItemPollId) {
      clearTimeout(this.menuItemPollId);
    }

    try {
      const data = await this.api.pollMenuItems(locationId, this.menuItemHash, this.handlePollingNetworkError);

      runInAction(() => {
        this.isPollingMenuItems = true;
      });

      // Update Menu Item Availability
      if (this.menuItemHash !== data.hash) {
        runInAction(() => {
          this.menuItemHash = data.hash;
          this.fulfillableMenuItemIds = data.fulfillable_menuitem_ids || [];
        });
      }
      // Update snooze messages / last call / visible menus
      runInAction(() => {
        this.visible_menus = data.visible_menus || {};
        this.show_last_call = Boolean(data.show_last_call);
        this.orderSnoozedBannerMessages = data.order_snoozed_banner_messages || [];
      });

      // Update the fulfillable status of each cart items.
      this.rootStore.checkoutStore.selectedCart?.items.forEach((cartItem) => {
        if (this.fulfillableMenuItemIds?.length) {
          cartItem.setIsFulfillable(this.fulfillableMenuItemIds.includes(cartItem.menu_item_id));
        }
      });

      // Check the shared carts returned. If am already joined, we are good. If not, join it, start polling.
      // eslint-disable-next-line @typescript-eslint/naming-convention
      const { id, shared_carts_allowed } = this.rootStore.locationStore;
      const sharedCartFromLocalStorage = retrieveFromLocalStorage(`shared_cart:${id}`);
      const forceServerSideCarts = this.rootStore.locationStore.customer?.getEnabledFeatures(managedFeatures.serverSideCarts);
      if (
        !forceServerSideCarts &&
        shared_carts_allowed &&
        !sharedCartFromLocalStorage &&
        data.your_shared_cart_id &&
        data.your_shared_cart_id !== this.rootStore.checkoutStore.selectedCart.sharedCartReference?.id
      ) {
        await this.rootStore.checkoutStore.rejoinSharedCart(data.your_shared_cart_id);
      }

      if (data.unclaimed_pos_tabs_available) {
        // We support cloud tabs, so we need to fetch the list of POS only tabs available to claim for this location.
        await this.rootStore.tabStore.getUnclaimedPosOnlyTabsForLocation(id);
      }

      // Given the interval set by the server continue to poll
      const pollId = setTimeout(() => {
        runInAction(() => {
          this.menuItemPollId = null;
        });
        this.pollMenuItems(locationId);
      }, data.pollInterval);

      runInAction(() => {
        this.menuItemPollId = pollId; // Set the pollId in-case you need to cancel the timeout
        this.errorPollingMenuItems = null;
      });
    } catch (err: any) {
      runInAction(() => {
        this.menuItemPollId = null;
        if (err.message) {
          this.errorPollingMenuItems = err.message;
        }
      });
    }
  };

  handlePollingNetworkError = () => {
    runInAction(() => {
      this.errorPollingMenuItems =
        'Check your network connection. Please refresh the page and try again or connect to Wi-Fi if available.';
    });
  };

  get atLeastOneMenuAllowsFutureOrders() {
    if (this._atleatOneMenuAllowsFutureOrders) {
      return this._atleatOneMenuAllowsFutureOrders;
    }

    const orderAheadMenus = this.menus.filter((m) => m.allow_order_ahead);
    if (this.loaded) {
      runInAction(() => {
        this._atleatOneMenuAllowsFutureOrders = orderAheadMenus.length > 0;
      });
    }

    return orderAheadMenus.length > 0;
  }

  stopPollingFulfillableMenuItems = () => {
    if (this.menuItemPollId) {
      clearTimeout(this.menuItemPollId);
    }

    runInAction(() => {
      this.menuItemHash = null;
      this.isPollingMenuItems = false;
      this.menuItemPollId = null;
    });
  };

  get selectedMenuCustomer() {
    return this.selectedMenu?.customer;
  }

  isMenuLoaded = (menuId: string) => this.menus.some((m) => m.id === menuId);

  isValidMenuForLocation = (menuId: string) => this.menus.some((menu) => menu.id === menuId);

  updateMenuFromServer = (json: MenuData) => {
    const menu = this.menus.find((menu) => menu.menuId === json.menuId);

    if (menu) {
      menu.updateFromJson(json);
      return menu;
    }

    const newMenu = new Menu(this, json);

    runInAction(() => {
      this.menus = [...this.menus, newMenu];
    });

    return newMenu;
  };

  setActiveHeading = (heading: MenuHeading) => {
    this.activeHeading = heading;
  };

  setActiveMenu = (menu: string | Menu) => {
    const menuToSet = typeof menu === 'string' ? this.mergedMenus.find((m) => m.menuId === menu) : menu;

    // Do not set the selected menu if the menu is not a valid menu that is merged
    if (!menuToSet) {
      return;
    }

    runInAction(() => {
      this.selectedMenu = menuToSet;
      this.activeHeading = menuToSet.headings[0];
    });
  };

  getActiveMenu() {
    return this.selectedMenu;
  }

  setShowMobileSearchInput = (bool: boolean) => {
    runInAction(() => {
      this.showMobileSearchInput = bool;
    });
  };

  clearSearchQuery = () => {
    runInAction(() => {
      this.menuItemsByIdFiltered = null;
      this.searchText = undefined;
    });
  };

  searchMenuItems = (searchText: string) => {
    const normalizedSearchText = searchText.trim().toLowerCase();

    if (normalizedSearchText.length) {
      const menuItemsByIdFiltered: Record<string, MenuItem> = {};
      Object.values(this.menuItemsById).forEach((menuItem: MenuItem) => {
        const description = menuItem.description.toLowerCase();
        const heading = menuItem.heading.heading_name.toLowerCase();
        const name = menuItem.name_for_customer.toLowerCase();
        const price = menuItem.displayPrice;
        const tags = menuItem.tags.map(({ name }) => name?.toLowerCase());

        /**
         * TODO: Determine if it is a good UX to prevent the user from searching for unfufillable items. Having
         * the ability to search for any item would allow the user to discover that their favorite item is out of stock
         * and not give the impression its discontinued.
         */
        if (
          [description, heading, name, price, ...tags].some((text) => text.includes(normalizedSearchText)) &&
          menuItem.isFulfillable
        ) {
          menuItemsByIdFiltered[menuItem.id] = menuItem;
        }
      });

      runInAction(() => {
        this.menuItemsByIdFiltered = menuItemsByIdFiltered;
        this.searchText = searchText;
      });
    } else {
      this.clearSearchQuery();
    }
  };
}
