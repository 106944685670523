import styled from 'styled-components';

// Components
import { Button } from 'bbot-component-library';

export const BackButton = styled(Button)`
  border: 1px solid transparent;
  color: var(--color-neutral__title-active);
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1rem;
  height: 32px;
  padding: 0;
  right: unset;
  left: 1rem;
  box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.2);
  border-radius: 16px;
  width: 32px;

  & > span {
    color: var(--color-neutral__title-active);
    font-size: 1.25rem;
  }

  &&:focus {
    border: 1px solid var(--color-primary__regular);
    color: var(--color-neutral__title-active);
  }

  &&:hover {
    border-color: transparent;
    opacity: 0.6;
  }
`;
