import { observer } from 'mobx-react';
import React, { useEffect } from 'react';

// Components
import { CloseOnTouch, Container, Modal, Underlay } from 'bbot-component-library/HalfSheetModal/styles';

// Hooks
import { useStores } from 'hooks/use-stores';

function EmptyHalfSheetModal({
  children,
  className,
  onClose = () => {},
  visible,
  modalAriaLabel = '',
}: {
  children: React.ReactNode;
  className?: string;
  onClose?: () => void;
  visible: boolean;
  modalAriaLabel?: string;
}) {
  const {
    uiState: { lockBodyScroll, unlockBodyScroll },
  } = useStores();

  // Lock body scroll when the modal is visible.
  useEffect(() => {
    if (visible) {
      lockBodyScroll();
    }

    return () => {
      unlockBodyScroll();
    };
  }, [visible, lockBodyScroll, unlockBodyScroll]);

  return (
    <>
      <Underlay visible={visible} />
      <Container className={className} visible={visible} aria-hidden={!visible}>
        <CloseOnTouch onClick={onClose} />
        <Modal aria-label={modalAriaLabel} role="dialog" tabIndex={0}>
          {children}
        </Modal>
      </Container>
    </>
  );
}

export default observer(EmptyHalfSheetModal);
