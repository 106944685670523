/* eslint-disable max-classes-per-file */
import { BbotErrorOptions, BbotLoggedError } from './Errors';

export enum TabErrorId {
  tab_missing_field = 'tab_missing_field',
  tab_unsupported_currency = 'tab_unsupported_currency',
  tab_closed = 'tab_closed',
  tab_unsupported_card = 'tab_unsupported_card',
  tab_invalid_prepaid_card = 'tab_invalid_prepaid_card',
  tab_general = 'tab_general',
  tab_max_expansion_reached_for_tip = 'tab_max_expansion_reached_for_tip',
  tab_bug_in_code = 'tab_bug_in_code',
  tab_card_declined = 'tab_card_declined',
  tab_max_expansion_reached = 'tab_max_expansion_reached',
  tab_insufficient_funds = 'tab_insufficient_funds',
  tab_closed_in_pos = 'tab_closed_in_pos',
  tab_does_not_exist = 'tab_does_not_exist',
  incorrect_card_details = 'incorrect_card_details',
  payment_fraud = 'payment_fraud',
  unknown_error = 'unknown_error',
  payment_method_missing = 'payment_method_missing',
  payment_expired = 'payment_expired',
  tab_not_authorized = 'tab_not_authorized',
  tab_not_started = 'tab_not_started',
  tabs_out_of_sync = 'tabs_out_of_sync',
  owned_tab_mismatch = 'owned_tab_mismatch',
  tab_version_hash_mismatch = 'tab_version_hash_mismatch',
  tab_invalid_argument = 'tab_invalid_argument',
}

class TabMissingFieldError extends BbotLoggedError {
  constructor(_: string, options: BbotErrorOptions) {
    const message = 'Sorry. There is a missing field. Please contact support if this error persist.';
    super(message, options);
    this.message = message;
    this.error_id = TabErrorId.tab_missing_field;
  }
}

class TabUnsupportedCurrecnyError extends BbotLoggedError {
  constructor(_: string, options: BbotErrorOptions) {
    const message = 'Tabs are not supported outside the U.S. yet.';
    super(message, options);
    this.message = message;
    this.error_id = TabErrorId.tab_unsupported_currency;
  }
}

class TabClosedError extends BbotLoggedError {
  constructor(_: string, options: BbotErrorOptions) {
    const message = 'Your tab has already been closed. Please refresh to see the latest tab update.';
    super(message, options);
    this.message = message;
    this.error_id = TabErrorId.tab_closed;
  }
}
class TabPOSTabDoesNotExistError extends BbotLoggedError {
  constructor(_: string, options: BbotErrorOptions) {
    const message = 'The tab you have provided does not exist. Please try a different one';
    super(message, options);
    this.message = message;
    this.error_id = TabErrorId.tab_does_not_exist;
  }
}

class TabUnsupportedCardError extends BbotLoggedError {
  constructor(_: string, options: BbotErrorOptions) {
    const message = 'The card you attempted is not compatible with the system. Please use another card.';
    super(message, options);
    this.message = message;
    this.error_id = TabErrorId.tab_unsupported_card;
  }
}

class TabInvalidPrepaidCardError extends BbotLoggedError {
    constructor(_: string, options: BbotErrorOptions) {
        const message = 'You cannot start a tab with a prepaid card.  You can use your card to close the tab when you are ready if it has sufficient funds.';
        super(message, options);
        this.message = message;
        this.error_id = TabErrorId.tab_invalid_prepaid_card;
    }
}

class TabMaxExpansionReachForTipError extends BbotLoggedError {
  constructor(_: string, options: BbotErrorOptions) {
    const message =
      'This tab cannot be increased any further.  Please close the tab without increasing your original tip amount.';
    super(message, options);
    this.message = message;
    this.error_id = TabErrorId.tab_max_expansion_reached_for_tip;
  }
}

class TabGeneralError extends BbotLoggedError {
  constructor(_: string, options: BbotErrorOptions) {
    const message = 'Tab error.  Please contact support';
    super(message, options);
    this.message = message;
    this.error_id = TabErrorId.tab_general;
  }
}

class TabMaxExpansionReachedError extends BbotLoggedError {
  constructor(_: string, options: BbotErrorOptions) {
    const message = 'This tab cannot be increased any further.';
    super(message, options);
    this.message = message;
    this.error_id = TabErrorId.tab_max_expansion_reached;
  }
}

class TabBugInCodeError extends BbotLoggedError {
  constructor(_: string, options: BbotErrorOptions) {
    const message = 'We encountered an error expanding your tab. Please try a non-tab checkout.';
    super(message, options);
    this.message = message;
    this.error_id = TabErrorId.tab_bug_in_code;
  }
}

class TabCardDeclinedError extends BbotLoggedError {
  constructor(_: string, options: BbotErrorOptions) {
    const message = 'Your card was declined.  Please try another card';
    super(message, options);
    this.message = message;
    this.error_id = TabErrorId.tab_card_declined;
  }
}

class TabInsufficientFundsError extends BbotLoggedError {
  constructor(_: string, options: BbotErrorOptions) {
    const message = 'Insufficient funds on tab';
    super(message, options);
    this.message = message;
    this.error_id = TabErrorId.tab_insufficient_funds;
  }
}

class TabClosedInPOSError extends BbotLoggedError {
  constructor(_: string, options: BbotErrorOptions) {
    const message =
      'Your server has combined your tab with another active tab. Your tab is now closed ' +
      'and your card will not be charged. Please refresh the page.';
    super(message, options);
    this.message = message;
    this.error_id = TabErrorId.tab_closed_in_pos;
  }
}

class IncorrectCardDetailsError extends BbotLoggedError {
  constructor(_: string, options: BbotErrorOptions) {
    const message = 'The card details you entered are incorrect. Please update it or use another payment method.';
    super(message, options);
    this.message = message;
    this.error_id = TabErrorId.incorrect_card_details;
  }
}

class PaymentFraudError extends BbotLoggedError {
  constructor(_: string, options: BbotErrorOptions) {
    const message = 'We are unable to process your payment. Please contact support.';
    super(message, options);
    this.message = message;
    this.error_id = TabErrorId.payment_fraud;
  }
}

class UnknownError extends BbotLoggedError {
  constructor(_: string, options: BbotErrorOptions) {
    const message = 'Unknown error. Please contact support.';
    super(message, options);
    this.message = message;
    this.error_id = TabErrorId.unknown_error;
  }
}

class PaymentMethodMissingError extends BbotLoggedError {
  constructor(_: string, options: BbotErrorOptions) {
    const message = 'Payment method missing. Please contact support.';
    super(message, options);
    this.message = message;
    this.error_id = TabErrorId.payment_method_missing;
  }
}

class PaymentExpiredError extends BbotLoggedError {
  constructor(_: string, options: BbotErrorOptions) {
    const message = 'Your card has expired. Please use another payment method.';
    super(message, options);
    this.message = message;
    this.error_id = TabErrorId.payment_expired;
  }
}

class TabNotAuthorizedError extends BbotLoggedError {
    constructor(_: string, options: BbotErrorOptions) {
        const message = 'Tab is not authorized. Please contact support.';
        super(message, options);
        this.message = message;
        this.error_id = TabErrorId.tab_not_authorized;
    }
}

class TabNotStartedError extends BbotLoggedError {
    constructor(_: string, options: BbotErrorOptions) {
        const message = 'Tab is inactive. It is before the start time. Please wait until the start time.';
        super(message, options);
        this.message = message;
        this.error_id = TabErrorId.tab_not_started;
    }
}

class TabOutOfSyncError extends BbotLoggedError {
    constructor(_: string, options: BbotErrorOptions) {
        const message = 'Tab is out of sync. Please refresh.';
        super(message, options);
        this.message = message;
        this.error_id = TabErrorId.tabs_out_of_sync;
    }
}

class OwnedTabMismatchError extends BbotLoggedError {
    constructor(_: string, options: BbotErrorOptions) {
        const message = 'This tab is not associated with the current session; perhaps cookies were cleared. (It\'s ok; any open tabs will automatically close after a few hours.)';
        super(message, options);
        this.message = message;
        this.error_id = TabErrorId.owned_tab_mismatch;
    }
}

class TabVersionHashMismatchError extends BbotLoggedError {
    constructor(_: string, options: BbotErrorOptions) {
        const message = 'Tab is out of date. Please refresh and try again.';
        super(message, options);
        this.message = message;
        this.error_id = TabErrorId.tab_version_hash_mismatch;
    }
}

class TabInvalidArgumentError extends BbotLoggedError {
    constructor(_: string, options: BbotErrorOptions) {
        const message = 'Invalid argument is supplied in one of the fields. Please contact support to file bug.';
        super(message, options);
        this.message = message;
        this.error_id = TabErrorId.tab_invalid_argument;
    }
}

const ERROR_ID_TO_TYPE: Record<TabErrorId, typeof BbotLoggedError> = {
  [TabErrorId.tab_missing_field]: TabMissingFieldError,
  [TabErrorId.tab_unsupported_currency]: TabUnsupportedCurrecnyError,
  [TabErrorId.tab_closed]: TabClosedError,
  [TabErrorId.tab_unsupported_card]: TabUnsupportedCardError,
  [TabErrorId.tab_invalid_prepaid_card]: TabInvalidPrepaidCardError,
  [TabErrorId.tab_max_expansion_reached_for_tip]: TabMaxExpansionReachForTipError,
  [TabErrorId.tab_general]: TabGeneralError,
  [TabErrorId.tab_max_expansion_reached]: TabMaxExpansionReachedError,
  [TabErrorId.tab_bug_in_code]: TabBugInCodeError,
  [TabErrorId.tab_card_declined]: TabCardDeclinedError,
  [TabErrorId.tab_insufficient_funds]: TabInsufficientFundsError,
  [TabErrorId.tab_closed_in_pos]: TabClosedInPOSError,
  [TabErrorId.tab_does_not_exist]: TabPOSTabDoesNotExistError,
  [TabErrorId.incorrect_card_details]: IncorrectCardDetailsError,
  [TabErrorId.payment_fraud]: PaymentFraudError,
  [TabErrorId.unknown_error]: UnknownError,
  [TabErrorId.payment_method_missing]: PaymentMethodMissingError,
  [TabErrorId.payment_expired]: PaymentExpiredError,
  [TabErrorId.tab_not_authorized]: TabNotAuthorizedError,
  [TabErrorId.tab_not_started]: TabNotStartedError,
  [TabErrorId.tabs_out_of_sync]: TabOutOfSyncError,
  [TabErrorId.owned_tab_mismatch]: OwnedTabMismatchError,
  [TabErrorId.tab_version_hash_mismatch]: TabVersionHashMismatchError,
  [TabErrorId.tab_invalid_argument]: TabInvalidArgumentError,
};

export const throwTabError = (
  serverError: Error & { error_id: TabErrorId },
  options: BbotErrorOptions = {}
): TabErrorType => {
  if (ERROR_ID_TO_TYPE[serverError.error_id]) {
    throw new ERROR_ID_TO_TYPE[serverError.error_id](serverError.message, options);
  }
  throw new TabGeneralError(serverError.message, options);
};

export type TabErrorType =
  | TabMissingFieldError
  | TabUnsupportedCurrecnyError
  | TabClosedError
  | TabUnsupportedCardError
  | TabInvalidPrepaidCardError
  | TabMaxExpansionReachForTipError
  | TabGeneralError
  | TabMaxExpansionReachedError
  | TabBugInCodeError
  | TabCardDeclinedError
  | TabInsufficientFundsError
  | TabClosedInPOSError
  | TabPOSTabDoesNotExistError
  | IncorrectCardDetailsError
  | PaymentFraudError
  | UnknownError
  | PaymentMethodMissingError
  | PaymentExpiredError
  | TabNotAuthorizedError
  | TabNotStartedError
  | TabOutOfSyncError
  | OwnedTabMismatchError
  | TabVersionHashMismatchError
  | TabInvalidArgumentError;