/* eslint-disable no-restricted-syntax,
no-prototype-builtins,
no-param-reassign,
no-shadow,
@typescript-eslint/naming-convention,
@typescript-eslint/no-misused-promises,
consistent-return,
guard-for-in,
@typescript-eslint/no-dynamic-delete,
@typescript-eslint/no-implied-eval */
import { makeObservable, runInAction, observable, computed } from 'mobx';
import { v4 as uuidv4 } from 'uuid';

// Components
import { notification } from 'bbot-component-library';

// Constants
import { BbotLoggedError } from 'constants/Errors';
import { SHARED_CART_ITEM_PLACEHOLDER_ID } from 'constants/SharedCart';

// Models
import SharedCartItem from 'models/SharedCartItem';
import CartItem from 'models/CartItem';
import User from 'models/User';

// Utils
import { saveToLocalStorage, removeFromLocalStorage } from 'utils/LocalStorage';
import { setCookie } from 'utils/Cookie';

// Types
import { Discount, SharedCartData } from 'api/types';

// Tracking
import { sharedCartFormatting } from 'integrations/segment/util/formatting';
import { sharedCartTrackingEvents } from 'integrations/segment/tracking-events';
import { DateTime } from 'luxon';
import { CannotEditAbandonedCartError, CannotEditClosedCartError } from 'constants/SharedCartErrors';
import { TODO } from '../utils/Types';
import RootStore from '../stores/RootStore';
import MenuDataStore from '../stores/MenuDataStore';
import Cart from './Cart';
import { SHARED_CART_STATE } from '../constants/Checkout';

export default class SharedCart {
  cart_master_id: string = '';
  id: string = '';
  name: string = '';
  location_id: string = '';
  shared_cart_items: Array<SharedCartItem> = [];
  status: SHARED_CART_STATE = SHARED_CART_STATE.OPEN;

  members: Array<TODO> = [];
  secret_for_link: string = '';
  cart?: Cart; // Reference to parent single user cart (cart we call getCartPrice)
  secret_password: string = '';
  hash: string = '';
  time_modified?: DateTime;

  loaded: boolean = false;
  membersById: Record<string, User> = {};

  isPolling: boolean = false;
  sharedCartPollId?: NodeJS.Timeout;
  errorPollingGroupOrder: string = '';

  rootStore: RootStore;
  private _pollRequestId: TODO;

  constructor(rootStore: RootStore, sharedCart: SharedCart, cart: TODO) {
    this.rootStore = rootStore;

    runInAction(() => {
      this.id = sharedCart?.id;
      // TODO(types) another typing error??
      this.time_created = sharedCart?.time_created;
      this.cart = cart;
      this.secret_for_link = sharedCart?.secret_for_link;
      this.secret_password = sharedCart?.secret_password;
    });

    makeObservable(this, {
      rootStore: false,
      cart_master_id: observable,
      id: observable,
      location_id: observable,
      shared_cart_items: observable.deep,
      status: observable,
      members: observable.deep,
      secret_for_link: observable,
      cart: false,
      secret_password: observable,
      hash: observable,
      loaded: observable,
      membersById: observable.deep,
      isPolling: observable,
      sharedCartPollId: observable,
      errorPollingGroupOrder: observable,
      itemsCount: computed,
      currentUserIsReadyToCheckout: computed,
    });
  }

  async updateFromGetCartPrice() {
    const { pricechecks } = this.rootStore.checkoutStore.selectedCart ?? {};
    await SharedCart.distributePriceCheckAcrossSharedCartItems(this.cart.items, this.shared_cart_items, pricechecks);
  }

  /**
   * Updates this Shared Cart object with new cart objects. Called from the poller to update client side Shared Cart object.
   * @param cart Updated cart. If cart is null, return early, no need to update.
   * @returns {Promise<void>}
   */
  async update(cart: SharedCart) {
    // If cart is null, return early. This means that our cart is up to date, no need to update.
    if (!cart) {
      return;
    }

    for (const key in cart) {
      const ignoreKeys = ['members', 'shared_cart_items']; // NOTE: hash is not a property on the shared_cart
      if (this.hasOwnProperty(key) && !ignoreKeys.includes(key)) {
        runInAction(() => {
          // @ts-expect-error
          this[key] = cart[key];
        });
      }

      // Backend sends cart_status, frontend model stores just 'status'.
      if (key === 'cart_status') {
        runInAction(() => {
          this.status = cart.cart_status;
        });
      }
    }

    // Update the list of shared_cart_items
    cart.shared_cart_items?.forEach((item: SharedCartItem) => {
      const existingItem = this.shared_cart_items.find((i) => i.id === item.id);

      // update item
      if (existingItem) {
        existingItem.update(item, true);
      }
      // add item
      else {
        const sharedCartItem = new SharedCartItem(this.rootStore, this, item);
        runInAction(() => {
          this.shared_cart_items.push(sharedCartItem);
        });
      }
    });

    // remove any items that are no longer in the cart
    const serverItemIds = cart.shared_cart_items.map((i) => i.id);
    if (cart.shared_cart_items.length !== this.shared_cart_items.length) {
      runInAction(() => {
        this.shared_cart_items = this.shared_cart_items.filter(
          (clientSideItem) =>
            clientSideItem.id === SHARED_CART_ITEM_PLACEHOLDER_ID || serverItemIds.includes(clientSideItem.id)
        );
      });
    }

    // Update the members dict
    cart.members?.forEach((m: TODO) => {
      const member = this.membersById[m.id];
      if (member) {
        const readyForCheckout =
          this.shared_cart_items.filter((item) => item.owner_id === member.id && !item.ready_for_checkout).length === 0;
        member.update({ ...m, ready_for_checkout: readyForCheckout });
      } else {
        const newUser = new User(m);
        runInAction(() => {
          this.membersById[m.id] = newUser;
          this.members.push(newUser);
        });
      }
    });
    // Remove members no longer in the cart
    const currentCartMembers = cart.members.map((m) => m.id);
    const membersThatLeftCart = this.members.filter((m) => !currentCartMembers.includes(m.id));
    membersThatLeftCart.forEach((member) => {
      runInAction(() => {
        const memberIndex = this.members.findIndex((m) => m.id === member.id);
        this.members.splice(memberIndex, 1);
        delete this.membersById[member.id];
      });
    });

    await this.rootStore.checkoutStore.updateLocalCart();
    runInAction(() => {
      this.time_modified = cart.time_modified;
    });

    runInAction(() => {
      this.loaded = true;
    });
  }

  get itemsCount() {
    return this.shared_cart_items.reduce((count, item) => count + item.qty, 0);
  }

  get hasItems() {
    return this.shared_cart_items.length > 0;
  }

  getSharedCartItemById(cartItemId: string) {
    return this.shared_cart_items.find((item) => item.id === cartItemId);
  }

  getExistingItemIfItExists(hash: number) {
    return this.shared_cart_items.find((item) => item.hash() === hash);
  }

  getOtherMatchingItems(sharedCartItem: SharedCartItem) {
    return this.shared_cart_items.filter((item) =>
      Boolean(
        CartItem.calculateItemHash(item) === CartItem.calculateItemHash(sharedCartItem) &&
          item.owner_id === sharedCartItem.owner_id &&
          item.id !== sharedCartItem.id
      )
    );
  }

  getCartMemberByUserId(userId: string | undefined) {
    return this.membersById[userId];
  }

  get currentUserIsReadyToCheckout() {
    const { anonymousUserId } = this.rootStore.userStore;
    const currentUserItems = this.getItemsForGivenUser(anonymousUserId);
    return currentUserItems.length > 0 && currentUserItems.filter((item) => !item.ready_for_checkout)?.length === 0;
  }

  get sharedCartItems() {
    return this.shared_cart_items;
  }

  get cartMembersStillOrdering() {
    return this.members.filter((member) => member.id !== this.cartOwner.id && !member.ready_for_checkout);
  }

  getItemsForGivenUser(userId?: string) {
    return this.shared_cart_items.filter((item) => item.owner_id === userId) || [];
  }

  async updateReadyForCheckout(val: boolean) {
    await this.rootStore.api.updateReadyForCheckout(this.id, val);
  }

  async leaveSharedCart(shouldAbandonItems: boolean) {
    const { anonymousUserId } = this.rootStore.userStore;
    await this.rootStore.api.leaveSharedCart(this.id);
    await SharedCart.convertFromSharedToSingleUserCart(
      this.rootStore.menuDataStore,
      this,
      anonymousUserId,
      shouldAbandonItems
    );

    await this.rootStore.checkoutStore.getCartPrice();
  }

  get secretPassword() {
    return this.secret_password;
  }

  get secretForLink() {
    return this.secret_for_link;
  }

  get cartOwner() {
    return this.membersById[this.cart_master_id];
  }

  /**
   * Returns User object associated with the current user session
   * @returns {User}
   */
  get currentCartMember(): User {
    return this.membersById[this.rootStore.userStore.anonymousUserId];
  }

  getGuestFriendlyErrorHelper() {
    return { cartOwner: this.cartOwner, currentMember: this.currentCartMember };
  }

  async pollForUpdates(tries = 0, delayUntilRetry = 0) {
    clearTimeout(this.sharedCartPollId as unknown as NodeJS.Timeout);

    try {
      const currentRequestId = uuidv4();
      runInAction(() => {
        this._pollRequestId = currentRequestId;
        this.isPolling = true;
      });

      const { updated_shared_cart, pollInterval } = (await this.rootStore.api.pollSharedCartUpdates(
        this.id,
        this.location_id,
        this.time_modified
      )) as SharedCartData;
      if (this._pollRequestId !== currentRequestId) {
        return null;
      }
      await this.update(updated_shared_cart);

      // Given the interval set by the server continue to poll
      const pollId = setTimeout(async () => {
        await this.pollForUpdates();
      }, pollInterval);

      runInAction(() => {
        this.sharedCartPollId = pollId; // Set the pollId in-case you need to cancel the timeout
        this.errorPollingGroupOrder = '';
      });

      return null;
    } catch (error: TODO) {
      if (error instanceof CannotEditClosedCartError) {
        setCookie(`checkedOutSharedCart:${this.id}`, this.id, 1);
        setCookie('mostRecentSharedCartId', this.id, 1);
        removeFromLocalStorage(`shared_cart:${this.location_id}`);
        this.rootStore?.uiState?.history?.push('/order-status');

        runInAction(() => {
          this.errorPollingGroupOrder = error.message;
          this.isPolling = false;
        });
      } else if (error instanceof CannotEditAbandonedCartError) {
        // NOTE: At this point you cannot convert the user's items to normal cart items because the server isn't responding with a list of shared cart items
        this.rootStore.checkoutStore.removeClientConnectionToSharedCart();
        notification.error({ message: error.message });

        runInAction(() => {
          this.errorPollingGroupOrder = error.message;
          this.isPolling = false;
        });
      } else if (tries < 2) {
        tries += 1;
        setTimeout(await this.pollForUpdates(tries), delayUntilRetry);
      } else {
        runInAction(() => {
          this.errorPollingGroupOrder = error.message;
          this.isPolling = false;
        });
      }
    }
  }

  stopPolling() {
    // @ts-expect-error
    clearTimeout(this.sharedCartPollId);

    runInAction(() => {
      this.isPolling = false;
      this.sharedCartPollId = undefined;
    });
  }

  /**
   * @param newStatus
   * @returns {Promise<void>}
   */
  async setSharedCartStatus(newStatus: TODO) {
    const { locked_shared_cart } = await this.rootStore.api.updateSharedCartStatus(this.id, newStatus);
    if (locked_shared_cart) {
      await this.update(locked_shared_cart);
    } else {
      runInAction(() => {
        this.status = newStatus;
      });
    }
  }

  /**
   * This assumes that the GIVEN ITEMS ARE NOT DUPLICATES
   * @param items
   * @returns {Promise<void>}
   */
  async addSeveralItemsToSharedCart(items: Array<CartItem> = []) {
    await this.rootStore.api.addCartItemToSharedCart(this.id, items);
    const { updated_shared_cart } = (await this.rootStore.api.pollSharedCartUpdates(
      this.id,
      this.location_id,
      this.time_modified
    )) as SharedCartData;
    await this.update(updated_shared_cart);
  }

  /**
   * Takes a cartItem object and adds it to the shared cart.
   * It also formats the cartItem through a static function that appends the user data to the cartItem
   * so that when the payload is sent to the server it has the necessary user data.
   * @param item The CartItem to add to this shared cart.
   */
  async addCartItemToSharedCart(item: CartItem) {
    // api request to add item to shared cart
    // get response from request
    // Update user on clientside to not be ready for checkout
    this.currentCartMember?.markReadyForCheckout(false);

    const newItem = new SharedCartItem(this.rootStore, this, {
      ...item,
      owner_id: this.rootStore.userStore?.anonymousUserId,
      id: SHARED_CART_ITEM_PLACEHOLDER_ID,
    });

    // Optimistically updating local clientside state
    runInAction(() => {
      this.shared_cart_items.push(newItem);
    });

    setTimeout(() => {
      sharedCartTrackingEvents.trackAddSharedCartItem({
        ...sharedCartFormatting.formatSharedCartItemForTracking(newItem),
      });
    });
    setTimeout(() => {
      this.rootStore.checkoutStore.updateLocalCart();
    });
    setTimeout(async () => {
      try {
        const { new_shared_cart_item_ids } = await this.rootStore.api.addCartItemToSharedCart(this.id, [item]);
        if (new_shared_cart_item_ids?.length) {
          newItem.syncIdFromServer(new_shared_cart_item_ids[0]);
        }
        sharedCartTrackingEvents.trackAddSharedCartItemSuccess({
          ...sharedCartFormatting.formatSharedCartItemForTracking(newItem),
        });
      } catch (error: any) {
        // remove all SHARED_CART_ITEM_PLACEHOLDER_ID items so they arent stuck in limbo
        const sharedCartIndexOfNewItem = this.shared_cart_items.indexOf(newItem);
        const cartIndexOfNewItem = this.cart?.items.findIndex(
          (cartItem) => cartItem.id === SHARED_CART_ITEM_PLACEHOLDER_ID
        );
        runInAction(() => {
          if (sharedCartIndexOfNewItem >= 0) {
            this.shared_cart_items.splice(sharedCartIndexOfNewItem, 1);
          }
          if (cartIndexOfNewItem != null && cartIndexOfNewItem >= 0) {
            this.cart?.items.splice(cartIndexOfNewItem, 1);
          }
        });

        sharedCartTrackingEvents.trackAddSharedCartItemFailure({
          message: error.message,
        });

        notification.error({ message: error.message });
        return null;
      }
    });
  }

  async handleUpdateCartItemInSharedCart(newItemCopy: SharedCartItem) {
    try {
      // Update user on clientside to not be ready for checkout
      this.currentCartMember?.markReadyForCheckout(false);

      // Any item that already exists in the cart that matches the same selections as the item being edited
      const matchingSharedCartItems = this.getOtherMatchingItems(newItemCopy);

      if (matchingSharedCartItems.length > 0) {
        const totalQty = matchingSharedCartItems.reduce((total, current) => total + current.qty, 0);
        newItemCopy.setQty(totalQty + newItemCopy.qty);
        matchingSharedCartItems.forEach((item) => item.setQty(0));
        await this.updateCartItemsInSharedCart([newItemCopy, ...matchingSharedCartItems]);
        // Wait for the update cart item endpoint to resolve before optimistically updating the item client side
        this.replaceExistingItem(newItemCopy);
      } else {
        // Wait for the update cart item endpoint to resolve before optimistically updating the item client side
        await this.updateCartItemsInSharedCart([newItemCopy]); // sends to server
        this.replaceExistingItem(newItemCopy); // locally updating the existing shared cart item
      }

      setTimeout(() => {
        sharedCartTrackingEvents.trackEditSharedCartItem({
          ...sharedCartFormatting.formatSharedCartItemForTracking(newItemCopy),
        });
      });
    } catch (error: TODO) {
      notification.error({ message: error.message });
    }
  }

  /**
   * Request - Takes an existing sharedCartItem and formats it through a static function
   * that appends the user data to the cartItem so that when the payload is sent to the server
   * it has the necessary user data. NOTE: The hash for the cartItem and the user_id/session_id is used to
   * determine if the item is a new item or should be updated.
   * @returns {Promise<void>}
   */
  async updateCartItemsInSharedCart(sharedCartItems: Array<SharedCartItem>): Promise<TODO> {
    try {
      await this.rootStore.api.editItemInSharedCart(sharedCartItems);
    } catch (error) {
      console.error(error);
      throw error;
    }
  }

  /**
   * Takes a cartItem object hash and removes it from the shared cart
   * Request - Takes an existing sharedCartItem hash and user_id/session_id.
   * @param item
   * @returns {Promise<void>}
   */
  async removeCartItemFromSharedCart(item: SharedCartItem) {
    // api request to remove item from shared cart
    try {
      const sharedCartItem = this.getSharedCartItemById(item.id);
      const itemJson = sharedCartItem.toJSON();
      const itemJsonWithZeroQuantity = { ...itemJson, qty: 0 };
      await this.rootStore.api.editItemInSharedCart([itemJsonWithZeroQuantity]); // Success: item successfully removed from shared cart

      sharedCartTrackingEvents.trackRemoveSharedCartItem({
        ...sharedCartFormatting.formatSharedCartItemForTracking(item),
      });

      // Remove item from this.shared_cart_items
      this.localRemoveSharedCartItemById(sharedCartItem);
    } catch (error) {
      console.error(error);
      throw error;
    }
  }

  localRemoveSharedCartItemById(sharedCartItem: SharedCartItem) {
    runInAction(() => {
      this.shared_cart_items = this.shared_cart_items.filter((item) => item.id !== sharedCartItem.id);
    });
  }

  markItemsReadyForUser(userId: string, isReady: boolean) {
    const items = this.getItemsForGivenUser(userId);
    items.forEach((item) => {
      item.update({ ready_for_checkout: isReady }, true);
    });
    if (this.currentCartMember) {
      this.currentCartMember?.markReadyForCheckout(isReady);
    }
  }

  createSharedCartItem(menu_id: string, menu_item_id: string, owner_id: string) {
    return new SharedCartItem(this.rootStore, this, { menu_id, menu_item_id, owner_id });
  }

  replaceExistingItem(itemCopy: SharedCartItem) {
    const newList = this.shared_cart_items.filter((i) => i.id !== itemCopy.id);
    itemCopy.updateFromPriceCheck({});
    newList.push(itemCopy);
    runInAction(() => {
      this.shared_cart_items = newList;
    });
  }

  static convertSharedCartItemsToCartItems(menuDataStore: MenuDataStore, sharedCartItems: Array<SharedCartItem> = []) {
    const groupedItemsByHash: Record<string, Array<SharedCartItem>> = sharedCartItems.reduce(
      (groupByValue: Record<number, Array<SharedCartItem>>, item: SharedCartItem) => {
        (groupByValue[CartItem.calculateItemHash(item)] = groupByValue[CartItem.calculateItemHash(item)] ?? []).push(
          item
        );
        return groupByValue;
      },
      {}
    );
    const cartItems = Object.values(groupedItemsByHash).map(
      (itemList: Array<SharedCartItem>) =>
        new CartItem(menuDataStore, {
          ...itemList[0],
          qty: itemList?.reduce((totalQty, current) => totalQty + current.qty, 0) ?? 0,
          sharedCartItemIds: itemList?.map((item) => item.id),
        })
    );

    return cartItems;
  }

  static async distributePriceCheckAcrossSharedCartItems(
    cartItems: Array<CartItem> = [],
    sharedCartItems: Array<SharedCartItem> = [],
    priceChecks: Record<string, TODO> = {}
  ) {
    cartItems.forEach((cartItem: CartItem) => {
      const matchingSharedCartItems = sharedCartItems.filter(
        (sharedCartItem) => CartItem.calculateItemHash(sharedCartItem) === CartItem.calculateItemHash(cartItem)
      );

      const priceCheck = priceChecks[cartItem.hash()];
      if (!priceCheck) {
        return;
      }

      const weights = matchingSharedCartItems.map((item: SharedCartItem) => item.qty);
      const BOGODiscount = priceCheck.discounts.find((discount: Discount) => discount?.is_item_level_discount);

      const pretaxDistributions = CartItem.distributeByWeights(priceCheck.lineitem_pretax_cents, weights);
      const preDiscountPretaxCentsDistr = CartItem.distributeByWeights(
        priceCheck.lineitem_pretax_cents - (BOGODiscount?.cents_added ?? 0),
        weights
      );
      const taxDistributions = CartItem.distributeByWeights(priceCheck.lineitem_tax_cents, weights);
      const discountDistributions = CartItem.distributeByWeights(priceCheck.discounts, weights);
      const bogoDistributions = CartItem.distributeBOGODiscount(BOGODiscount, weights, preDiscountPretaxCentsDistr);
      matchingSharedCartItems.forEach((sharedCartItem: SharedCartItem, index) => {
        sharedCartItem.updateFromPriceCheck({
          lineitem_pretax_cents: BOGODiscount ? preDiscountPretaxCentsDistr[index] : pretaxDistributions[index],
          lineitem_tax_cents: taxDistributions[index],
          discounts: BOGODiscount ? bogoDistributions[index] : discountDistributions[index],
        });
      });
    });
  }

  static async convertFromSharedToSingleUserCart(
    menuDataStore: MenuDataStore,
    sharedCart: SharedCart,
    userId: string,
    shouldAbandonItems: boolean
  ) {
    runInAction(() => {
      const itemsToSave = shouldAbandonItems
        ? []
        : sharedCart.shared_cart_items
            .filter((item) => item.owner_id === userId)
            .map((item) => new CartItem(menuDataStore, item));

      if (sharedCart?.cart?.sharedCartReference) {
        sharedCart.cart.setItemsFromSharedCart(itemsToSave);
        sharedCart.cart.sharedCartReference = undefined;
      }
    });

    sharedCart.stopPolling();
  }

  static toJSON(cart: SharedCart) {
    if (!cart) {
      return null;
    }
    return {
      id: cart?.id,
      location_id: cart?.location_id,
    };
  }

  static validateGuestSharedCart(sharedCart: SharedCart, userId: string) {
    const items = sharedCart.getItemsForGivenUser(userId);

    if (items.length === 0) {
      throw new BbotLoggedError(
        "We weren't able to mark your cart as ready just yet, it's still empty. Please add some items first."
      );
    }
  }

  static saveToLocalStorage(sharedCart: SharedCart) {
    if (!sharedCart) {
      return;
    }

    saveToLocalStorage(`shared_cart:${sharedCart.location_id}`, SharedCart.toJSON(sharedCart));
  }

  static async create(rootStore: RootStore, sharedCartData: TODO, cart: TODO) {
    const sharedCart = new SharedCart(rootStore, sharedCartData, cart);
    await sharedCart.update(sharedCartData);
    const { shared_cart_items } = sharedCart;

    const cartItems = SharedCart.convertSharedCartItemsToCartItems(rootStore.menuDataStore, shared_cart_items);
    sharedCart.cart?.setItemsFromSharedCart(cartItems);

    if (
      rootStore?.locationStore?.loaded &&
      rootStore?.menuDataStore?.loaded &&
      sharedCart.cart?.loaded &&
      sharedCart?.loaded
    ) {
      await rootStore.checkoutStore.getCartPrice();
      await sharedCart.updateFromGetCartPrice();
    }

    return sharedCart;
  }
}
