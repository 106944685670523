import { useEffect, useState } from 'react';
import { observer } from 'mobx-react';

// Components
import { Typography } from 'bbot-component-library';
import CustomTipField from 'components/tipping/CustomTipField';

// Hooks
import { useStores } from 'hooks/use-stores';

// Styles
import { Choice, ChoiceAmount, ChoicePercentage, Choices } from 'components/tipping/TipOptions/styles';

// Types
import { TipType, TipKey } from 'models/Types';

// Utils
import { formatCentsToPrice } from 'utils/Currency';
import { TipChoice } from 'models/Cart';
import { getTipOptionKey } from 'components/checkout-modules/CheckoutTipSelect';
import { managedFeatures } from 'DynamicValues/DynamicValuesProvider';

const { Text } = Typography;

export type PossibleTipSelections =
  | {
      type: TipType.Percentage; // percentage must be defined
      choice: TipKey;
      amount?: number;
      percentage: number;
    }
  | {
      type: TipType.Amount; // amount must be defined
      choice: TipKey;
      amount: number;
      percentage?: number;
    };

function TipOptions({
  className,
  choices,
  currencySymbol,
  currencyCode,
  selectedTipPercentage,
  selectedTipOption,
  amountToApplyTipTo,
  onSelectTip,
}: {
  className?: string;
  choices: Array<number>;
  currencySymbol: string;
  currencyCode: string;
  selectedTipPercentage: number;
  selectedTipOption: string;
  amountToApplyTipTo: number;
  onSelectTip: (selection: PossibleTipSelections) => void;
}) {
  const {
    checkoutStore,
    locationStore,
    locationStore: { customer },
  } = useStores();
  const {
    selectedCart: { tip_amount },
  } = checkoutStore;

  const { show_no_tip: showNoTip, has_default_tip: hasDefaultTip = true } = customer?.app_properties?.tipping ?? {};

  const [showTipField, setShowTipField] = useState(selectedTipOption === getTipOptionKey(TipChoice.Other));
  const [customTipAmount, setCustomTipAmount] = useState(tip_amount ? String((tip_amount / 100).toFixed(2)) : '0'); // Keep customTip state here so that the field preserves its state when user switches between tip "options"

  const showDualCheckoutOptions =
    locationStore.customer.enabled_features?.includes(managedFeatures.dualCheckoutOptions) ?? false;

  const handleSetTipSelection = (choice: TipKey, percentage: number) => {
    onSelectTip({ type: TipType.Percentage, choice, percentage });
  };

  const handleUnsetTipSelection = () => {
    onSelectTip({ type: TipType.Percentage, choice: getTipOptionKey(TipChoice.None), percentage: 0 });
  };

  // only call if a customer does not have a default tip selection
  const handleToggleTipSelection = (choice: TipKey, percentage: number) =>
    choice === selectedTipOption ? handleUnsetTipSelection() : handleSetTipSelection(choice, percentage);

  const handleSelectTip = (choice: TipKey, percentage: number) => {
    setShowTipField(choice === getTipOptionKey(TipChoice.Other));
    hasDefaultTip ? handleSetTipSelection(choice, percentage) : handleToggleTipSelection(choice, percentage);
  };

  const handleSelectCustomTip = () => {
    if (showTipField && !hasDefaultTip) {
      // Only call if Cx does not have default tip selection
      onSelectTip({ type: TipType.Percentage, choice: getTipOptionKey(TipChoice.None), percentage: 0 });
      setShowTipField(false);
    } else {
      // When custom tip option is slected, update selectedTip so that the "option" is selected in UI
      onSelectTip({
        type: TipType.Amount,
        choice: getTipOptionKey(TipChoice.Other),
        amount: customTipAmount === '' ? 0 : parseFloat(customTipAmount) * 100, // If customTipAmount is empty, force amount to be 0
      });
      setShowTipField(true);
    }
  };

  useEffect(() => {
    // Hotfix: On first render, force re-select of default tip option so that default get set properly in the checkoutStore and tabStores
    if (showDualCheckoutOptions) {
      // On first render, use the default selectedTipOption and re-select the option, only do this if one of the "choices" are selected, because that is the case breaking
      try {
        if (
          selectedTipOption.length > 0 &&
          selectedTipOption !== getTipOptionKey(TipChoice.Other) &&
          selectedTipOption
        ) {
          const splitDefaultTopOption = selectedTipOption.split('-');
          const defaultOptionIndex = Number(splitDefaultTopOption.length > 1 ? splitDefaultTopOption[1] : undefined);

          // Only do this if we have a valid index
          if (defaultOptionIndex && defaultOptionIndex < choices.length) {
            onSelectTip({
              type: TipType.Percentage,
              choice: selectedTipOption as TipKey,
              percentage: choices[defaultOptionIndex],
            });
          }
        }
      } catch (error) {}
    }
  }, []);

  return (
    <>
      <Choices
        className={className}
        buttonStyle="solid"
        defaultValue={selectedTipOption}
        value={selectedTipOption} // Used to determine what tip is selected, will match corresponding choice value
        id="tip-options"
      >
        {showNoTip && (
          <Choice
            value={getTipOptionKey(TipChoice.NoTip)}
            onClick={() => handleSelectTip(getTipOptionKey(TipChoice.NoTip), 0)}
          >
            <Text style={{ textAlign: 'center', letterSpacing: '0.8px' }} data-testid="no-tip_choice">
              No Tip
            </Text>
          </Choice>
        )}
        {choices.map((tipPercentage: number, index: number) => (
          <Choice
            key={`tip-choice-${tipPercentage}`}
            value={getTipOptionKey(TipChoice.PresetOption, index)}
            className={`tip-choice-${index}`}
            onClick={() => handleSelectTip(getTipOptionKey(TipChoice.PresetOption, index), tipPercentage)}
          >
            <ChoicePercentage data-testid={`tips-${index}`}>{Math.round(tipPercentage * 100)}%</ChoicePercentage>
            <ChoiceAmount data-testid={`${index}-tip-button${checkoutStore.isUpdating ? '-loading' : ''}`}>
              {formatCentsToPrice(amountToApplyTipTo * tipPercentage, currencyCode)}
            </ChoiceAmount>
          </Choice>
        ))}
        <Choice onClick={handleSelectCustomTip} value={getTipOptionKey(TipChoice.Other)} className="tip-choice-other">
          <Text style={{ letterSpacing: '0.8px' }} data-testid="tips-choice">
            Other
          </Text>
        </Choice>
      </Choices>

      {showTipField && (
        <CustomTipField
          onSelectTip={onSelectTip}
          customTipAmount={customTipAmount}
          setCustomTipAmount={setCustomTipAmount}
        />
      )}
    </>
  );
}

export default observer(TipOptions);
